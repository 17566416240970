/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { useRef } from "react";

const useRenderCount = (componentName: string) => {
  const renders = useRef(0);
  // console.log(`renders : ${componentName} : `, renders.current++);
};

export default useRenderCount;
