import { useQuery } from "react-query";
import axiosConfig from "../config/axiosConfig";

const getMemberData = async () => {
  const res = await axiosConfig.get("user/get");
  return res;
};

const useGetUser = () => {
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getUser"],
    getMemberData,
    {
      enabled: false,
      cacheTime: 1,
    },
  );
  return {
    isLoading,
    refetch,
    data,
    error,
    isFetching,
    isSuccess,
  };
};

export default useGetUser;
