import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import axiosConfig from "../../config/axiosConfig";
import { PharmacyInfoDto } from "../../services/api";

const getPharmacy = async () => {
  const response = await axiosConfig.get("pharmacies/get");
  return response;
};

export default function useGetPharmacy() {
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getPharmacy"],
    getPharmacy,
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<PharmacyInfoDto[]>) => {
        return response.data;
      },
      onError: (err: AxiosError) => {
        return err;
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
}
