import { useState } from "react";

export default function useExpandibleContent() {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleChange = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return { handleChange, activeIndex };
}
