import React from "react";

export interface SpinnerIconProps {
  fill: string;
  className: string;
}

export default function SpinnerIcon({ fill, className }: SpinnerIconProps) {
  return (
    <svg
      width="56.1837662px"
      height="56.1837662px"
      viewBox="0 0 56.1837662 56.1837662"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>loading-sprite-pill</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="loading-sprite-pill">
          <rect fill="" x="0" y="0" width="56.1837662" height="56.1837662" />
          <path
            d="M42.5918831,30.8198052 L42.5918831,36.375874 C42.5918831,40.3694063 41.0213218,43.986658 38.4765106,46.6043545 C35.9468138,49.2065036 32.4531424,50.8198052 28.5918831,50.8198052 C24.7306237,50.8198052 21.2369524,49.2065036 18.7072556,46.6043545 C16.1624444,43.986658 14.5918831,40.3694063 14.5918831,36.375874 L14.5918831,36.375874 L14.5918831,30.8198052 L42.5918831,30.8198052 Z M28.5918831,5.36396103 C32.4531424,5.36396103 35.9468138,6.9772626 38.4765106,9.57941172 C41.0213218,12.1971081 42.5918831,15.8143599 42.5918831,19.8078922 L42.5918831,19.8078922 L42.5918831,25.363961 L14.5918831,25.363961 L14.5918831,19.8078922 C14.5918831,15.8143599 16.1624444,12.1971081 18.7072556,9.57941172 C21.2369524,6.9772626 24.7306237,5.36396103 28.5918831,5.36396103 Z"
            stroke={fill}
            strokeWidth="3"
            transform="translate(28.591883, 28.091883) rotate(45.000000) translate(-28.591883, -28.091883) "
          />
        </g>
      </g>
    </svg>
  );
}
