import { TranslateFunction } from "../hooks/useTranslationByClient";

export interface MenuItem {
  text: string;
  link: string;
  authority: string;
  children?: MenuItem[];
}

export type MenuListItem =
  | { children: MenuListItem[]; authority?: never; link?: never; text: string }
  | { children?: null; authority: string; link: string; text: string };

export type RouteProps = MenuItem & MenuListItem;

export const getMenuItems = (translate: TranslateFunction): MenuListItem[] => {
  return [
    {
      text: translate("MENU_ITEMS.PRESCRIBERS"),
      link: "/my-prescribers",
      authority: "PRESCRIBER_PAGE",
    },
    {
      text: translate("MENU_ITEMS.PHARMACIES"),
      link: "/my-pharmacies",
      authority: "PHARMACY_PAGE",
    },
    {
      text: translate("MENU_ITEMS.RECENT_REFILLS"),
      link: "/recent-refills",
      authority: "RECENT_REFILLS_PAGE",
    },
    {
      text: translate("MENU_ITEMS.PROFILE"),
      link: "/my-profile",
      authority: "PROFILE_PAGE",
    },
    {
      text: translate("MENU_ITEMS.FEEDBACK"),
      link: "/feedback",
      authority: "FEEDBACK_PAGE",
    },
    {
      text: translate("MENU_ITEMS.FAQS"),
      link: "/faq",
      authority: "FAQ_PAGE",
    },
    {
      text: translate("MENU_ITEMS.AGREEMENTS"),
      children: [
        {
          text: translate("MENU_ITEMS.PRIVACY_POLICY"),
          link: "/privacy-policy",
          authority: "PRIVACY_PAGE",
        },
        {
          text: translate("MENU_ITEMS.TERMS_AND_CONDITIONS"),
          link: "/terms-and-conditions",
          authority: "TC_PAGE",
        },
      ],
    },
  ];
};
