import InfoIcon from "@mui/icons-material/Info";
import { List, ListItem, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { SwitchListItemDto } from "../../services/api/models/SwitchListItemDto";
import { SwitchNotificationDto } from "../../services/api/models/SwitchNotificationDto";
import SwitchItemContainer from "./SwitchItemContainer";

export interface SwitchItem extends SwitchNotificationDto {
  id: number;
}

interface SwitchListComponentProps {
  switchesList: SwitchListItemDto[];
}

function SwitchListComponent({ switchesList }: SwitchListComponentProps) {
  const navigate = useNavigate();
  const translate = useTranslationByClient();

  const handleChange = (id: string) => {
    navigate(`/switch?id=${id}&redirect=true`);
  };

  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h2"
        fontSize="2rem"
        lineHeight={0.97}
        letterSpacing={-1.86}
        mb={2}
        mt={2}
        textAlign="center"
        sx={{ wordSpacing: "3px" }}
      >
        {translate("SWITCHES.SWITCH_LIST_TITLE")}
      </Typography>

      {switchesList && switchesList.length > 0 ? (
        <List className="list-style">
          {switchesList?.map((switchItem: SwitchListItemDto) => {
            return (
              <ListItem className="list-item" key={switchItem.switchInfoUuid}>
                <SwitchItemContainer
                  handleChange={handleChange}
                  provider={switchItem}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          flexDirection="column"
        >
          <InfoIcon
            className="errorIcon"
            sx={{ color: theme.palette.info.darker }}
            aria-label="info icon"
          />
          <br />
          <Typography
            variant="body3"
            color="info.darker"
            textTransform="uppercase"
          >
            {translate("SWITCHES.NO_SWITCHES_YET_TEXT")}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default SwitchListComponent;
