import Cookies from "universal-cookie";
import history from "../config/history";
import i18n, { getTranslation } from "../config/i18n";
import { GrantedAuthority } from "../services/api/models/GrantedAuthority";
import { decodeToken } from "./decodeToken";
import createToast from "./globalUtils";

// get server base url
export const getBaseUrl = () => {
  return process.env.REACT_APP_ENV
    ? `https://${process.env.REACT_APP_ENV}-api.members.${process.env.REACT_APP_HOSTED_ZONE_NAME}.com`
    : "http://localhost:58080";
};

export type TokenData = {
  accessToken: string;
  refreshToken: string;
  expirationTime: string;
};

export const cookiesObject = new Cookies();

const datePlusDays = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

// set cookie data
export const setCookies = (
  accessToken: string,
  refreshToken: string,
  expirationTime: string,
) => {
  const FifteenDaysfromNow = datePlusDays(15);
  cookiesObject.set("accessToken", accessToken, {
    secure: true,
    expires: FifteenDaysfromNow,
    sameSite: "none",
    path: "/",
  });
  cookiesObject.set("refreshToken", refreshToken, {
    secure: true,
    expires: FifteenDaysfromNow,
    sameSite: "none",
    path: "/",
  });
  cookiesObject.set("expirationTime", expirationTime, {
    secure: true,
    expires: FifteenDaysfromNow,
    sameSite: "none",
    path: "/",
  });
};

// get specific cookie
export const getCookie = (cookieName: string) => {
  return cookiesObject.get(cookieName);
};

// check if user is logged in
export const isLoggedIn = () => {
  const accessToken = getCookie("accessToken");
  if (accessToken) {
    return true;
  }
  return false;
};

// check if member id is present
export const memberIdExists = () => {
  const memberId = getMemberId();
  if (memberId) {
    return true;
  }
  return false;
};

// check if token is or about to be expired
export const isTokenExpired = () => {
  const cookieExpirationTime = Number(getCookie("expirationTime"));
  if (cookieExpirationTime) {
    const now = new Date();
    if (cookieExpirationTime - now.getTime() <= 1000 * 60 * 5) {
      console.log("expired");
      return true;
    }
  }
  return false;
};

// clear cookies storage
export const clearCookies = () => {
  cookiesObject.remove("accessToken", {
    secure: true,
    sameSite: "none",
    path: "/",
  });
  cookiesObject.remove("refreshToken", {
    secure: true,
    sameSite: "none",
    path: "/",
  });
  cookiesObject.remove("expirationTime", {
    secure: true,
    sameSite: "none",
    path: "/",
  });
};

// clear local storage
export const clearLocalStorage = () => {
  localStorage.clear();
  localStorage.removeItem("memberId");
  localStorage.removeItem("phone");
  localStorage.removeItem("toggleDrawer");
};

// logout user
export const clearSession = () => {
  clearCookies();
  clearLocalStorage();
};

// invalidate current session
export const invalidateSession = () => {
  createToast(
    getTranslation("GENERAL.ERROR.SESSION_EXPIRED", i18n.t, "default"),
    "error",
    5000,
  );
  clearSession();
  history.push("/logout");
};

// detects if user is trying to access app using another member id
// than one logged in
export const isDifferentUserAccess = (
  translate: any,
  newFirebaseId: string,
) => {
  try {
    if (isLoggedIn()) {
      const decoded = decodeToken();
      if (decoded.user_id !== newFirebaseId) {
        createToast("Different user access. Please login!", "success", 4000);
        return true;
      }
    }
    return false;
  } catch (err) {
    createToast(translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"), "error");
    return true;
  }
};

// check if user has authority
export const hasAuthority = (
  authorities: GrantedAuthority[],
  authority: string,
) => {
  for (let i = 0; i < authorities.length; i += 1) {
    if (authorities[i].authority?.toUpperCase() === authority) {
      return true;
    }
  }
  return false;
};

// get member id
export const getMemberId = () => {
  if (
    localStorage.getItem("memberId") !== null &&
    isEncryptedValueSafe(localStorage.getItem("memberId")!)
  ) {
    return decodeURIComponent(localStorage.getItem("memberId")!);
  } else {
    return localStorage.getItem("memberId");
  }
};

// set member id
export const setMemberId = (memberId: string) => {
  return localStorage.setItem("memberId", encodeURIComponent(memberId));
};

export const isEncryptedValueSafe = (encryptedValue: string) => {
  // contains only ascii characters
  for (let i = 0; i < encryptedValue.length; i++) {
    if (encryptedValue.charCodeAt(i) > 127) {
      return false;
    }
  }

  // Verify URL-safe characters
  const urlSafeChars = /^[a-zA-Z0-9\-_]+$/;
  if (!urlSafeChars.test(encryptedValue)) {
    console.error("Encrypted value contains invalid characters.");
    return false;
  }

  // All checks passed
  return true;
};
