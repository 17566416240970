import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import PharmaciesComponent from "../components/pharmaciesPage/PharmaciesComponent";
import useGetPharmacy from "../hooks/module/useGetPharmacy";
import { PharmacyInfoDto } from "../services/api";

function PharmaciesPage() {
  const { data, refetch, isLoading, isFetching, error } = useGetPharmacy();
  useEffect(() => {
    refetch();
  }, []);

  return (
    <Page
      isLoading={isLoading || isFetching}
      error={error}
      isSecondary={false}
      header={<Header />}
      displayExtMemIdAtBottom
    >
      <PharmaciesComponent
        pharmacyList={data?.data.map((pharm: PharmacyInfoDto) => ({
          ...pharm,
          discriminator: "pharmacy",
        }))}
      />
    </Page>
  );
}

export default PharmaciesPage;
