import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/common/PrivateRoute";
import PublicRoute from "../components/common/PublicRoute";
import useRenderCount from "../hooks/custom/useRenderCount";
import DashboardPage from "../pages/DashboardPage";
import DiscountDetailPage from "../pages/DiscountDetailPage";
import FAQPage from "../pages/FAQPage";
import FeedbackPage from "../pages/FeedbackPage";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import NotFoundPage from "../pages/NotFoundPage";
import OtpPage from "../pages/OtpPage";
import PharmaciesPage from "../pages/PharmaciesPage";
import PrescriberPage from "../pages/PrescriberPage";
import PrivacyPage from "../pages/PrivacyPage";
import ProfilePage from "../pages/ProfilePage";
import RecentRefillsPage from "../pages/RecentRefillsPage";
import SwitchNotificationPage from "../pages/SwitchNotificationPage";
import TermsAndConditionPage from "../pages/TermsAndConditionsPage";

interface IRoute {
  path: string;
  isPrivate?: boolean;
  component: ReactElement;
  title: string;
}

type AuthorityProps =
  | { isPrivate?: false; authority?: never }
  | { isPrivate: true; authority: string };

export type RouteProps = IRoute & AuthorityProps;

export const routesList: RouteProps[] = [
  {
    path: "/member/:memberId",
    isPrivate: false,
    component: <LandingPage />,
    title: "Welcome",
  },
  {
    path: "/",
    isPrivate: false,
    component: <LoginPage />,
    title: "Login",
  },
  {
    path: "/otp",
    isPrivate: false,
    component: <OtpPage />,
    title: "Login",
  },
  {
    path: "/privacy-policy",
    isPrivate: true,
    component: <PrivacyPage />,
    authority: "PRIVACY_PAGE",
    title: "Privacy Policy",
  },
  {
    path: "/terms-and-conditions",
    isPrivate: true,
    component: <TermsAndConditionPage />,
    authority: "TC_PAGE",
    title: "Terms & Condiitons",
  },
  {
    path: "/dashboard",
    isPrivate: true,
    component: <DashboardPage />,
    authority: "DASHBOARD_PAGE",
    title: "Dashboard",
  },
  {
    path: "/my-pharmacies",
    isPrivate: true,
    component: <PharmaciesPage />,
    authority: "PHARMACY_PAGE",
    title: "Pharmacies",
  },
  {
    path: "/my-prescribers",
    isPrivate: true,
    component: <PrescriberPage />,
    authority: "PRESCRIBER_PAGE",
    title: "Prescribers",
  },
  {
    path: "/discount-opportunities",
    isPrivate: true,
    component: <DiscountDetailPage />,
    authority: "DISCOUNT_PAGE",
    title: "Discount Opportunities",
  },
  {
    path: "/recent-refills",
    isPrivate: true,
    component: <RecentRefillsPage />,
    authority: "RECENT_REFILLS_PAGE",
    title: "Recent Refills",
  },
  {
    path: "/feedback",
    isPrivate: true,
    component: <FeedbackPage />,
    authority: "FEEDBACK_PAGE",
    title: "Feedback",
  },
  {
    path: "/switch",
    isPrivate: true,
    component: <SwitchNotificationPage />,
    authority: "SWITCH_NOTIFICATION",
    title: "Switch",
  },
  {
    path: "/logout",
    component: <LogoutPage />,
    title: "Logout",
  },
  // {
  //   path: "/",
  //   isPrivate: true,
  //   component: <Navigate to="/dashboard" replace />,
  //   authority: "DASHBOARD_PAGE",
  //   title: "Dashboard",
  // },
  {
    path: "/faq",
    isPrivate: true,
    component: <FAQPage />,
    authority: "FAQ_PAGE",
    title: "FAQ",
  },
  {
    path: "/my-profile",
    isPrivate: true,
    component: <ProfilePage />,
    authority: "PROFILE_PAGE",
    title: "My Profile",
  },
  {
    path: "*",
    component: <NotFoundPage />,
    title: "Error",
  },
];

export default function RoutesList() {
  useRenderCount("Route");
  return (
    <Routes>
      {routesList.map((route) => {
        return route.isPrivate === true ? (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute route={route} path={route.path}>
                {route.component}
              </PrivateRoute>
            }
          />
        ) : route.isPrivate === false ? (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute path={route.path}>{route.component}</PublicRoute>
            }
          />
        ) : (
          <Route key={route.path} path={route.path} element={route.component} />
        );
      })}
    </Routes>
  );
}
