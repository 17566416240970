// set background color on html/root element for each page
export default function setBackgroundColor(
  isSecondary: boolean,
  primaryColor: string,
  secondaryColor: string,
) {
  const htmlElement = document?.querySelector("html") as HTMLElement;
  const rootElement = document?.querySelector('div[id="root"]') as HTMLElement;

  if (isSecondary) {
    document
      ?.querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", secondaryColor);

    if (rootElement) {
      rootElement.style.background = secondaryColor;
      rootElement.style.backgroundColor = secondaryColor;
    }

    if (htmlElement) {
      htmlElement.style.backgroundColor = secondaryColor;
      htmlElement.style.background = secondaryColor;
    }
  } else {
    document
      ?.querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", primaryColor);
    if (rootElement) {
      rootElement.style.backgroundColor = primaryColor;
      rootElement.style.background = primaryColor;
    }
    if (htmlElement) {
      htmlElement.style.backgroundColor = primaryColor;
      htmlElement.style.background = primaryColor;
    }
  }
}
