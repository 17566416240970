import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetErrorMessage from "../../hooks/useGetErrorMessage";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/components/errorBox.scss";
import { clearSession, getMemberId, isLoggedIn } from "../../utils/authUtils";
import getMessageFromCode from "../../utils/errorLookup";

export interface ErrorBoxProps {
  error?: Error | AxiosError | string | null | unknown;
}

function ErrorBox({ error }: ErrorBoxProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const translate = useTranslationByClient();
  const getErrorMessage = useGetErrorMessage();
  useEffect(() => {
    if (error) {
      setErrorMessage(getErrorMessage(error));
    } else if (!getMemberId() || !isLoggedIn()) {
      setErrorMessage(getMessageFromCode("LOGGED_OUT", translate));
    }
  }, [error]);

  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      width="100%"
      height="80%"
      minHeight="80vh"
      className="col-center"
      textAlign="center"
      pr={2}
      pl={2}
    >
      <ErrorOutlineIcon
        className="errorIcon"
        sx={{ color: theme.palette.info.darker }}
        aria-label="error"
      />
      <br />
      <Typography
        mt={1}
        variant="h6"
        color="info.darker"
        textTransform="uppercase"
      >
        {errorMessage?.replaceAll("_", " ") ??
          translate("GENERAL.ERROR.SOMETHING_WENT_WRONG")}
        {(errorMessage.toLowerCase().includes("login again") ||
          errorMessage.toLowerCase().includes("Ingresar de nuevo")) && (
          <Box mt={5}>
            <Button
              onClick={() => {
                clearSession();
                navigate("/");
              }}
            >
              <Typography
                variant="h6"
                color="info.darker"
                lineHeight={1}
                fontSize="1.2rem"
                textTransform="uppercase"
                style={{
                  textDecoration: "underline",
                }}
              >
                {translate("GENERAL.GO_TO_LOGIN_PAGE")}
              </Typography>
            </Button>
          </Box>
        )}
      </Typography>
    </Grid>
  );
}

export default ErrorBox;
