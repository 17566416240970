import InfoIcon from "@mui/icons-material/Info";
import { List, ListItem, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { DrugDto } from "../../services/api/models/DrugDto";
import "../../styles/components/drugs.scss";

function DrugComponent({ drugDtoList }: any) {
  const theme = useTheme();
  const translate = useTranslationByClient();

  return (
    <>
      <Typography
        variant="h2"
        fontSize="2rem"
        lineHeight={0.97}
        letterSpacing={-1.86}
        mb={2}
        mt={2}
        textAlign="center"
        sx={{ wordSpacing: "3px" }}
      >
        {translate("DRUGS.DRUG_LIST_TITLE")}
      </Typography>

      {drugDtoList && drugDtoList.length > 0 ? (
        <List className="drug-list list-style">
          {drugDtoList?.map((drug: DrugDto) => (
            <ListItem className="list-item" key={drug.drugName}>
              <Grid
                mt={1}
                width="100%"
                gap={1}
                className="drug-pill"
                sx={{ backgroundColor: theme.palette.info.main }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={drug.drugName}
              >
                <Grid xs={7}>
                  <Typography
                    variant="body3"
                    fontWeight="600"
                    fontSize="1rem"
                    color="info.darker"
                    aria-label="drug name"
                    sx={{ wordWrap: "break-word" }}
                  >
                    {drug.drugName && drug.drugName !== ""
                      ? drug.drugName
                      : translate("DRUGS.UNSPECIFIED_DRUG_NAME_TEXT")}
                  </Typography>
                </Grid>
                <Grid xs={5} display="flex" justifyContent="flex-end">
                  <Box className="drug-dosage" textAlign="right">
                    <Typography
                      variant="body3"
                      fontWeight="500"
                      fontSize="1rem"
                      color="info.darker"
                      aria-label="drug strength and dosage"
                      sx={{
                        backgroundColor: theme.palette.info.dark,
                        wordWrap: "break-word",
                      }}
                    >
                      {`${drug.drugStrength ?? ""} - ${
                        drug.daysSupply
                          ? `/${drug.daysSupply} ${translate("GENERAL.DAY")}`
                          : translate("DRUGS.INVALID_DAYS_SUPPLY_TEXT")
                      }`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          flexDirection="column"
        >
          <InfoIcon
            className="errorIcon"
            sx={{ color: theme.palette.info.darker }}
            aria-label="info icon"
          />{" "}
          <br />
          <Typography
            variant="body3"
            color="info.darker"
            textTransform="uppercase"
          >
            {translate("DRUGS.NO_DRUGS_YET_TEXT")}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default DrugComponent;
