import axios from "axios";
import { useQuery } from "react-query";
import { getBaseUrl } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import useGetErrorMessage from "../useGetErrorMessage";
import { addHeadersToPublicRequest } from "../../config/axiosConfig";

const getMemberfromPhone = async (phoneNumber: string, dateOfBirth: string) => {
  const baseUrl = getBaseUrl();
  const res = await axios.post(
    `${baseUrl}/user/get-member-by-phone`,
    { phoneNumber, dateOfBirth },
    {
      headers: addHeadersToPublicRequest(),
    },
  );
  return res;
};

const useGetMemberfromPhone = (phoneNumber: string, dateOfBirth: string) => {
  const getErrorMessage = useGetErrorMessage();
  const { isLoading, refetch, data, error, isFetching, status } = useQuery(
    ["getMemberfromPhone"],
    () => getMemberfromPhone(phoneNumber, dateOfBirth),
    {
      cacheTime: 10,
      retry: 0,
      enabled: false,
      onError: (err) => {
        createToast(getErrorMessage(err), "error", 3000);
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, status };
};

export default useGetMemberfromPhone;
