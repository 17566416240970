if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/sw.js").then(
      function (registration) {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
      },
      function (err) {
        console.log("ServiceWorker registration failed: ", err);
      },
    );
  });
}

let deferredPrompt = null;

window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

export default () => {
  if (deferredPrompt != null) {
    const btnParent = document.querySelector(".install-btn-parent");
    if (btnParent) btnParent.style.display = "block";
    document.querySelector(".install-btn").addEventListener("click", () => {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          if (btnParent) btnParent.style.display = "none";
        }
        deferredPrompt = null;
      });
    });
  }
};
