import { Box, Button, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../config/store";
import useOTP, { UserAction } from "../../hooks/custom/useOTP";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/components/otp.scss";
import { clearSession } from "../../utils/authUtils";
import useStyles from "../styles";
import history from "./../../config/history";
import SpinnerOverlay from "./SpinnerOverlay";

export default function OtpComponent() {
  const { preferredPhone, setPreferredPhone } = useContext(AppContext);
  const translate = useTranslationByClient();
  const { otp, setOtp, onSubmitOtp, spinner, spinnerText, sendOtp } = useOTP(
    preferredPhone ?? localStorage.getItem("phone")!,
    UserAction.AUTHENTICATE,
  );
  const { preAuthUser } = useContext(AppContext);
  // const [checked, setChecked] = useState(false);
  const theme = useTheme();
  const classes = useStyles(
    theme.palette.info.light,
    theme.palette.info.darker!,
  )();

  const navigate = useNavigate();

  const onCancel = () => {
    setPreferredPhone(null);
    clearSession();
    navigate("/");
  };

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        onCancel();
      }
    };
  }, [history]);

  return (
    <>
      <Grid
        xs={12}
        mt={1}
        minHeight="70vh"
        height="100%"
        display="flex"
        flexDirection="column"
        flexGrow={preAuthUser.firebaseUserId ? 0 : 0.5}
        justifyContent="space-between"
        width="100%"
      >
        <Grid
          container
          xs={12}
          direction="row"
          justifyContent="center"
          textAlign="center"
        >
          <Grid xs={10} mb={6} mt={2}>
            <Typography variant="body1" lineHeight={1.267}>
              {preAuthUser.firebaseUserId
                ? translate("OTP_PAGE.IDENTITY_VERIFICATION_TEXT")
                : translate("OTP_PAGE.OTP_CODE_PROMPT_TEXT")}
            </Typography>
          </Grid>
          <Grid
            mb={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
            aria-label="otp code"
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span />}
              containerStyle="otp-input otpContainer"
              inputStyle={classes.toDetailPage}
              renderInput={(props) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input {...props} />{" "}
                </div>
              )}
              inputType="tel"
            />
          </Grid>
          <Grid xs={12} aria-label="otp resend link">
            <Button onClick={sendOtp}>
              <Typography
                variant="body1"
                lineHeight={1.267}
                style={{
                  textDecoration: "underline",
                }}
              >
                {translate("OTP_PAGE.RESEND_OTP_BUTTON")}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12}>
          {!preAuthUser.firebaseUserId && (
            <Grid
              container
              xs={12}
              mb={3}
              direction="row"
              justifyContent="center"
              textAlign="center"
            >
              <Grid xs={10}>
                <Typography variant="body1" textAlign="justify" fontSize="15px">
                  {translate("OTP_PAGE.PRIVACY_POLICY_ACK_TEXT")}{" "}
                  <a
                    href="https://razormetrics.com/legal/privacy/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "underline",
                      color: `${theme.palette.info.darker}`,
                      cursor: "pointer",
                    }}
                    aria-label="link.text"
                  >
                    {translate("OTP_PAGE.PRIVACY_POLICY_LINK_TEXT")}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box
            className="public-page-back-button"
            sx={{ flexDirection: "column", alignItems: "center" }}
          >
            <Button
              variant="primary"
              disabled={otp.length !== 6}
              onClick={onSubmitOtp}
              aria-label="submit"
              sx={{ margin: "0px" }}
            >
              {translate("GENERAL.BUTTON.CONFIRM")}
            </Button>
            {!preAuthUser.firebaseUserId && (
              <Button onClick={onCancel}>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline", margin: "0px" }}
                >
                  {translate("GENERAL.BUTTON.CANCEL")}
                </Typography>
              </Button>
            )}
          </Box>
          <Typography ml={1} className="page-member-id-text">
            {translate("GENERAL.MEMBER_ID")}: {preAuthUser.externalMemberId}
          </Typography>
        </Grid>
      </Grid>
      {spinner ? <SpinnerOverlay text={spinnerText} /> : null}
    </>
  );
}
