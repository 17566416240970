import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { DrugDto } from "../../services/api/models/DrugDto";
import { formatPhoneNumber } from "../../utils/globalUtils";
import {
  PharmacyInfoDiscriminatorDto,
  PrescriberInfoDiscriminatorDto,
} from "../types";

interface ProviderCardContainerProps {
  index: number;
  handleChange: any;
  expanded: number | boolean;
  provider: PharmacyInfoDiscriminatorDto | PrescriberInfoDiscriminatorDto;
}
export default function ProviderCardContainer({
  handleChange,
  expanded,
  provider,
  index,
}: ProviderCardContainerProps) {
  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <Accordion
      style={{
        borderRadius: "8px",
        marginTop: "8px",
        width: "100%",
        backgroundColor: `${theme.palette.info.main}`,
      }}
      expanded={expanded === index}
      onChange={handleChange(index)}
    >
      <AccordionSummary
        sx={{ minWidth: "fit-content" }}
        expandIcon={
          <Box
            bgcolor={expanded === index ? "info.darker" : "info.light"}
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="fit-content"
          >
            <ExpandMoreIcon
              sx={{ color: expanded === index ? "info.light" : "info.darker" }}
            />
          </Box>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid xs={10} maxWidth="90%">
          <Typography
            width="100%"
            variant="body3"
            fontWeight="700"
            fontSize="1.25rem"
            color="info.darker"
            aria-label={
              provider.discriminator === "pharmacy"
                ? "pharmacy name"
                : "prescriber name"
            }
          >
            {provider.discriminator === "pharmacy"
              ? provider.pharmacyName && provider.pharmacyName !== ""
                ? provider.pharmacyName
                : translate("PHARMACIES_PAGE.UNKNOWN_PHARMACY_TEXT")
              : provider.prescriberName && provider.prescriberName !== ""
              ? provider.prescriberName
              : translate("PRESCRIBER_PAGE.UNKNOWN_PRESCRIBER_TEXT")}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{ padding: "0px", margin: "0px", overflow: "visible" }}
      >
        <Box
          mx="0.55rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
          overflow="visible"
          sx={{ gap: "1rem" }}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            fontSize="1.1rem"
            color="info.darker"
            maxWidth="50%"
            sx={{ wordBreak: "break-word" }}
            aria-label={
              provider.discriminator === "pharmacy"
                ? "pharmacy address"
                : "prescriber address"
            }
          >
            {provider.discriminator === "pharmacy"
              ? provider.pharmacyAddress
              : `${provider.practiceName} ${provider.prescriberAddress}`}
          </Typography>

          {((provider.discriminator === "pharmacy" && provider.pharmacyPhone) ||
            (provider.discriminator === "prescriber" &&
              provider.prescriberPhone)) && (
            <Box
              display="flex"
              color="info.main"
              bgcolor="info.darker"
              padding="4px"
              borderRadius="6px"
              maxWidth="50%"
            >
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize="1.1rem"
                color="info.light"
                overflow="visible"
                aria-label={
                  provider.discriminator === "pharmacy"
                    ? "pharmacy phone no"
                    : "prescriber phone no"
                }
              >
                {provider.discriminator === "pharmacy" && provider.pharmacyPhone
                  ? formatPhoneNumber(provider.pharmacyPhone)
                  : provider.discriminator === "prescriber" &&
                    provider.prescriberPhone
                  ? formatPhoneNumber(provider.prescriberPhone)
                  : ""}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider style={{ width: "100%" }} />
        {provider?.drugListDto?.map(
          // eslint-disable-next-line array-callback-return
          (prescription: DrugDto) => (
            <>
              <Box
                pt={1.5}
                pb={1.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx="0.5rem"
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  fontSize="1.1rem"
                  color="info.darker"
                  aria-label="prescription drug name"
                >
                  {prescription.drugName && prescription.drugName !== ""
                    ? prescription.drugName
                    : translate("DRUGS.UNSPECIFIED_DRUG_NAME_TEXT")}
                </Typography>
                <Box
                  display="flex"
                  color="info.main"
                  bgcolor="info.dark"
                  padding="4px"
                  borderRadius="6px"
                >
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    fontSize="1.1rem"
                    color="info"
                    aria-label="prescription drug strength"
                  >
                    {prescription.drugStrength} -
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontWeight={500}
                    fontSize="1.1rem"
                    color="info"
                    aria-label="prescription drug dosage"
                  >
                    {prescription.daysSupply
                      ? `/${prescription.daysSupply} ${translate(
                          "GENERAL.DAY",
                        )}`
                      : translate("DRUGS.INVALID_DAYS_SUPPLY_TEXT")}
                  </Typography>
                </Box>
              </Box>

              <Divider style={{ width: "100%" }} />
            </>
          ),
        )}
      </AccordionDetails>
    </Accordion>
  );
}
