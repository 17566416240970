import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import SpinnerIcon from "../../assets/SpninnerIcon";
import "../../styles/components/spinner.scss";

export interface SpinnerProps {
  text?: string;
}

function Spinner({ text }: SpinnerProps) {
  const themeConfig = useTheme();
  return (
    <Box m="auto" textAlign="center">
      <SpinnerIcon
        className="spinner"
        aria-label={`Loading ${text}`}
        fill={themeConfig.palette.info.darker!}
      />
      <Typography
        mt={1}
        variant="body3"
        fontWeight={500}
        textTransform="uppercase"
        color="info.darker"
      >
        {text ?? "Loading"}
      </Typography>
    </Box>
  );
}

export default Spinner;
