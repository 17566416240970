import { Box, Divider, Typography } from "@mui/material";
import React from "react";

interface SideBarButtonProps {
  handleClick: () => void;
  text: string;
}

function SideBarButton({ handleClick, text }: SideBarButtonProps) {
  return (
    <div>
      <Box
        onClick={handleClick}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        py={1.6}
        pr={2}
        aria-labelledby="link-text"
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography variant="body2" id="link-text" overflow="visible">
          {text}
        </Typography>
      </Box>
      <Divider style={{ width: "100%" }} />
    </div>
  );
}

export default SideBarButton;
