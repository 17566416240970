import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import RecentRefillsComponent from "../components/recentRefillsPage/RecentRefillsComponent";
import useGetDrug from "../hooks/module/useGetDrug";
import useGetErrorMessage from "../hooks/useGetErrorMessage";
import useGetUserAllSwitches from "../hooks/useGetUserAllSwitches";

function RecentRefillsPage() {
  const {
    data: drugsData,
    refetch: refetchDrugs,
    isLoading: isLoadingDrugs,
    error: drugsError,
  } = useGetDrug();
  const getErrorMessage = useGetErrorMessage();

  const {
    refetch: refetchSwitches,
    isLoading: isLoadingSwitches,
    error: switchesError,
    data: switchesData,
  } = useGetUserAllSwitches();

  useEffect(() => {
    refetchSwitches();
    refetchDrugs();
  }, []);

  let error = null;

  if (drugsError) {
    error = getErrorMessage(drugsError);
  }
  if (switchesError) {
    error =
      error === null
        ? getErrorMessage(switchesError)
        : `${error} ${getErrorMessage(switchesError)}`;
  }

  return (
    <Page
      isLoading={isLoadingDrugs || isLoadingSwitches}
      error={error}
      isSecondary={false}
      header={<Header />}
      displayExtMemIdAtBottom
    >
      <RecentRefillsComponent
        drugsData={drugsData?.data ?? []}
        switchesData={switchesData?.data ?? []}
      />
    </Page>
  );
}

export default RecentRefillsPage;
