import React from "react";

export interface CreditCardIconProps {
  fill: string;
  className: string;
}

export default function CreditCardIcon({
  fill,
  className,
}: CreditCardIconProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 198.193 198.193"
      className={className}
      style={{ marginInline: "0.4em" }}
    >
      <g>
        <path
          d="M171.205,33.97H26.988C12.108,33.97,0,44.982,0,58.517v81.16c0,13.533,12.105,24.545,26.988,24.545h144.217
				c14.88,0,26.988-11.012,26.988-24.545v-81.16C198.193,44.982,186.085,33.97,171.205,33.97z M182.974,139.678
				c0.001,5.141-5.279,9.328-11.769,9.328H26.988c-6.49,0-11.77-4.185-11.77-9.328V86.36h167.756V139.678z M182.975,71.144H15.22
				V58.517c0-5.143,5.277-9.33,11.768-9.33h144.217c6.49,0,11.77,4.185,11.77,9.331V71.144z"
          fill={fill}
        />
        <rect
          x="29.034"
          y="114.313"
          width="27.898"
          height="15.217"
          fill={fill}
        />
        <rect
          x="61.447"
          y="114.313"
          width="27.898"
          height="15.217"
          fill={fill}
        />
      </g>
    </svg>
  );
}
