import { LoggedInUserDto, MemberInfoDto } from "../../services/api";

const useGetClientNameAbbreviation = (
  preAuthUser: MemberInfoDto,
  postAuthUser: LoggedInUserDto,
) => {
  let value = "";
  if (postAuthUser?.clientNameAbbreviation) {
    value = postAuthUser.clientNameAbbreviation;
  } else if (preAuthUser?.clientNameAbbreviation) {
    value = preAuthUser.clientNameAbbreviation;
  }

  return value.toLowerCase();
};

export default useGetClientNameAbbreviation;
