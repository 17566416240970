import { Box, Button, Grid, Link, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useExpandibleContent from "../../hooks/custom/useExpandibleContent";
import useTranslationByClient, {
  TranslateFunction,
} from "../../hooks/useTranslationByClient";
import "../../styles/components/faq.scss";
import ScrollableList from "../common/ScrollableList";
import QuestionAnswer from "../landingPage/QuestionAnswer";

export interface QuestionAnswerType {
  question: string;
  answer: string;
}

const getQuestionAnswerList = (translate: TranslateFunction) => {
  const qaList: any = translate("FAQ_PAGE.QUESTIONS", {
    returnObjects: true,
  });
  return typeof qaList !== "string"
    ? (Object.values(qaList) as QuestionAnswerType[])
    : [];
};

function FAQComponent() {
  const navigate = useNavigate();
  const { handleChange, activeIndex } = useExpandibleContent();
  const handleClick = () => {
    navigate("/dashboard");
  };

  const theme = useTheme();
  const translate = useTranslationByClient();
  const questionAnswerList = getQuestionAnswerList(translate);
  return (
    <Grid
      container
      xs={12}
      width="100%"
      height="auto"
      mx={3.75}
      justifyContent="center"
      alignContent="space-around"
    >
      <Grid item xs={12} className="col-center">
        <Typography
          variant="h2"
          fontSize="2rem"
          lineHeight={0.97}
          letterSpacing={-1.86}
          mb={4}
          textAlign="center"
          sx={{ wordSpacing: "3px" }}
        >
          {translate("FAQ_PAGE.TITLE")}
        </Typography>
        <ScrollableList>
          {questionAnswerList.map(
            (questionAnswer: QuestionAnswerType, index: number) => (
              <QuestionAnswer
                key={questionAnswer.question}
                question={questionAnswer.question}
                answer={questionAnswer.answer}
                handleExpansionChange={handleChange}
                index={index}
                activeIndex={activeIndex}
              />
            ),
          )}
          <Typography variant="body1" mb={4} mt={4} textAlign="center">
            {translate("FAQ_PAGE.VIEW_COMPLETE_LIST_CALL_TO_ACTION_TEXT")}
            {": "}
            <Link
              style={{
                color: `${theme.palette.info.darker}`,
                textDecoration: "underline",
              }}
              href="https://razormetrics.com/razorrx-faq/"
              target="_blank"
            >
              razormetrics.com/faqs
            </Link>
          </Typography>
        </ScrollableList>
      </Grid>
      <Box className="page-back-button">
        <Button
          variant="primary"
          aria-label="back"
          onClick={handleClick}
          className="disabled-hover-effects"
        >
          {translate("GENERAL.BUTTON.BACK")}
        </Button>
      </Box>
    </Grid>
  );
}

export default FAQComponent;
