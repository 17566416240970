import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import InputField from "./InputField";

interface ProfileEmailSectionProps {
  isEditingPreferredEmail: boolean;
  defaultEmail: string;
  editablePreferredEmail: string;
  setEditablePreferredEmail: React.Dispatch<React.SetStateAction<string>>;
  currentPreferredEmail: string | undefined | null;
}

function ProfileEmailSection({
  isEditingPreferredEmail,
  defaultEmail,
  editablePreferredEmail,
  setEditablePreferredEmail,
  currentPreferredEmail,
}: ProfileEmailSectionProps) {
  const theme = useTheme();
  const translate = useTranslationByClient();

  const preferredEmailJSX = isEditingPreferredEmail ? (
    <>
      <Grid item xs={12} my={1} className="col-center">
        <Typography variant="body1" textAlign="center">
          {translate("PROFILE_PAGE.EMAIL_SECTION.HEADING")}.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        my={1}
        p={2}
        className="rounded-container"
        sx={{
          border: "0.5px solid",
          borderColor: `${theme.palette.info.dark}`,
          backgroundColor: `${theme.palette.info.main}`,
        }}
      >
        <InputField
          type="email"
          label={translate("PROFILE_PAGE.EMAIL_SECTION.EMAIL_INPUT_LABEL")}
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          errorMessage={translate("GENERAL.ERROR.INVALID_EMAIL")}
          aria-label="street field"
          placeholder={translate(
            "PROFILE_PAGE.EMAIL_SECTION.EMAIL_INPUT_PLACEHOLDER",
          )}
          ariaLabel="email field"
          value={editablePreferredEmail}
          onChange={(event: any) => {
            setEditablePreferredEmail(event.target.value);
          }}
        />
      </Grid>
    </>
  ) : null;

  return (
    <div>
      <Grid item xs={12} className="col-center">
        <Typography variant="body1" mb={1} textAlign="center">
          {translate("PROFILE_PAGE.EMAIL_SECTION.PROVIDED_EMAIL_TEXT")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="col-center">
        <Typography
          variant="h2"
          mb={1}
          textAlign="center"
          fontWeight={600}
          sx={{
            textDecoration:
              isEditingPreferredEmail ||
              (currentPreferredEmail && currentPreferredEmail !== "")
                ? "line-through"
                : "",
          }}
        >
          {defaultEmail}
        </Typography>
      </Grid>
      {(isEditingPreferredEmail ||
        (currentPreferredEmail && currentPreferredEmail !== "")) && (
        <Grid item xs={12} className="col-center">
          <Typography variant="body1" mb={1} textAlign="center">
            {translate("PROFILE_PAGE.EMAIL_SECTION.PREFERRED_EMAIL_TEXT")}
          </Typography>
        </Grid>
      )}

      {!isEditingPreferredEmail &&
        currentPreferredEmail &&
        currentPreferredEmail !== "" && (
          <Typography variant="h2" mb={1} textAlign="center" fontWeight={600}>
            {currentPreferredEmail}
          </Typography>
        )}
      {preferredEmailJSX}
    </div>
  );
}

export default ProfileEmailSection;
