import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import axiosConfig from "../config/axiosConfig";
import { SwitchListItemDto } from "../services/api/models/SwitchListItemDto";

const getSwitches = async () => {
  const res = await axiosConfig.get(`/switch/get-all-switches`);
  return res;
};

const useGetUserAllSwitches = () => {
  const [gotSwitches, setGotSwitches] = useState(true);
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getUserAllSwitches"],
    () => getSwitches(),
    {
      enabled: false,
      onSuccess: async (response: AxiosResponse<SwitchListItemDto[]>) => {
        setGotSwitches(true);
        return response;
      },
      onError: async (err: AxiosError) => {
        setGotSwitches(true);
        return err;
      },
    },
  );
  return {
    isLoading,
    refetch,
    data,
    error,
    isFetching,
    isSuccess,
    gotSwitches,
  };
};

export default useGetUserAllSwitches;
