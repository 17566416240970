import { Typography, useMediaQuery, useTheme } from "@mui/material";
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AxiosError } from "axios";
import React, { ReactNode, useContext, useEffect } from "react";
import { AppContext } from "../../config/store";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import setBackgroundColor from "../../utils/setBackgroundColor";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";
import ErrorBox from "./ErrorBox";

export interface PageProps {
  isSecondary: boolean;
  header: ReactNode;
  isLoading?: boolean;
  spinnerText?: string;
  error?: AxiosError | Error | null | string;
  displayExtMemIdAtBottom?: boolean;
}

function Page({
  isSecondary,
  header,
  isLoading,
  error,
  spinnerText,
  children,
  displayExtMemIdAtBottom,
}: React.PropsWithChildren<PageProps>) {
  const theme = useTheme();

  useEffect(() => {
    setBackgroundColor(
      isSecondary,
      theme.palette.primary.main,
      theme.palette.secondary.main,
    );
  }, []);
  const translate = useTranslationByClient();
  const { postAuthUser } = useContext(AppContext);

  return (
    <Grid
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      {header}
      <div
        style={
          useMediaQuery(`(min-width:${theme.constants.pageContentMaxWidth})`)
            ? {
                margin: "0 auto",
                maxWidth: theme.constants.pageContentMaxWidth,
              }
            : {}
        }
      >
        {isLoading ? (
          <SpinnerOverlay
            text={spinnerText ?? translate("GENERAL.SPINNER.LOADING_TEXT")}
            isSecondary={isSecondary}
          />
        ) : error ? (
          <ErrorBox error={error} />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            px="1rem"
            alignItems="center"
          >
            {children}
          </Box>
        )}
        {displayExtMemIdAtBottom && (
          <Typography ml={1} className="page-member-id-text">
            {translate("GENERAL.MEMBER_ID")}: {postAuthUser.externalMemberId}
          </Typography>
        )}
      </div>
    </Grid>
  );
}

export default Page;
