import { useMutation } from "react-query";
import axiosConfig from "../config/axiosConfig";

const sendNotification = async () => {
  const res = await axiosConfig.get(`/discount/send-notification`);
  return res;
};

const useSendNotificationForDiscountCards = () => {
  return useMutation(sendNotification);
};

export default useSendNotificationForDiscountCards;
