import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import React, { ReactNode, useContext } from "react";
import { AppContext } from "../../config/store";
import Header from "./Header";

export interface ScreenOverlayProps {
  centerContent?: boolean;
  isSecondary: boolean;
  isClosable?: boolean;
  topSection?: ReactNode;
  mainSection?: ReactNode;
  bottomSection?: ReactNode;
}
export function ScreenOverlay({
  centerContent,
  isSecondary,
  isClosable,
  topSection,
  bottomSection,
  mainSection,
}: ScreenOverlayProps) {
  const { setOverlayName } = useContext(AppContext);

  const paddingTop = 5;
  const paddingBottom = 12;

  return (
    <Box
      bgcolor={isSecondary ? "secondary.main" : "primary.main"}
      position="fixed"
      zIndex={100}
      top={0}
      width="100%"
      height={`${100 - paddingTop - paddingBottom}vh`}
      gridTemplateRows="auto auto 1fr auto"
      display="grid"
      right={0}
      bottom={0}
      paddingTop={`${paddingTop}vh`}
      paddingBottom={`${paddingBottom}vh`}
      left={0}
      sx={{
        opacity: 0.96,
        overflow: "hidden",
        overflowY: "scroll",
      }}
      textAlign={centerContent ? "center" : "left"}
    >
      <Box>
        {isClosable && isClosable === true && (
          <CloseIcon
            style={{
              position: "fixed",
              left: 5,
              fontSize: "1.8rem",
              color: "white",
            }}
            onClick={() => {
              setOverlayName("NONE");
            }}
          />
        )}

        <Header hideLanguageDropdown />
      </Box>
      {topSection || <div></div>}
      {mainSection || <div></div>}
      {bottomSection || <div></div>}
    </Box>
  );
}
