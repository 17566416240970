import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import LoginComponent from "../components/loginPage/LoginComponent";
import useRenderRecaptcha from "../hooks/custom/useRenderRecaptcha";
import "../styles/components/login.scss";

function LoginPage({ setMemberId, setMemberPhone }: any) {
  const [isCaptchaSuccessful, setIsCaptchaSuccessful] = useState(false);
  const { renderCaptcha } = useRenderRecaptcha(setIsCaptchaSuccessful);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    renderCaptcha(setIsLoading);
  }, []);

  return (
    <Page isSecondary={false} header={<Header />} isLoading={isLoading}>
      <LoginComponent
        setGetMember={setMemberId}
        setMemberPhone={setMemberPhone}
        isCaptchaSuccessful={isCaptchaSuccessful}
      />
    </Page>
  );
}

export default LoginPage;
