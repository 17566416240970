import IosShareIcon from "@mui/icons-material/IosShare";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import checkSaveToDevice from "../../saveToDevice";
import { isIOSDevice, isInStandaloneMode } from "../../utils/globalUtils";

function SaveToDevice() {
  const [displayIOSSaveToDeviceGuide, setDisplayIOSSaveToDeviceGuide] =
    useState(false);

  const translate = useTranslationByClient();
  useEffect(() => {
    if (!isIOSDevice()) {
      checkSaveToDevice();
    } else if (!isInStandaloneMode()) {
      setDisplayIOSSaveToDeviceGuide(true);
    }
  }, []);
  return (
    <>
      {displayIOSSaveToDeviceGuide && (
        <Box mt={5} textAlign="center">
          <Typography
            mt={1}
            variant="h6"
            color="info.darker"
            textTransform="uppercase"
          >
            {translate("LOGOUT_PAGE.FOLLOW_STEPS_TEXT")}:
          </Typography>
          <Typography mt={1} variant="body1" color="info.darker">
            {translate("LOGOUT_PAGE.TAP_TEXT")} <IosShareIcon />{" "}
            {translate("LOGOUT_PAGE.AND_SELECT_TEXT")}{" "}
            <strong>{translate("LOGOUT_PAGE.ADD_TO_HOME_SCREEN_TEXT")}</strong>
          </Typography>
        </Box>
      )}
      <Box
        mt={5}
        className="install-btn-parent"
        textAlign="center"
        style={{ display: "none" }}
      >
        <Typography
          mt={1}
          variant="h6"
          color="info.darker"
          textTransform="uppercase"
        >
          {translate("LOGOUT_PAGE.SAVE_TO_DEVICE_CALL_TO_ACTION_TEXT")}:
        </Typography>
        <Button className="install-btn">
          <Typography
            variant="h6"
            lineHeight={1}
            fontSize="1.2rem"
            textAlign="center"
            textTransform="uppercase"
            color="info.darker"
            style={{
              textDecoration: "underline",
            }}
          >
            {translate("LOGOUT_PAGE.SAVE_TO_DEVICE_BUTTON")}
          </Typography>
        </Button>
      </Box>
    </>
  );
}

export default SaveToDevice;
