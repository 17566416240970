import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import useSavingSummaryModule from "../../hooks/module/useSavingSummaryModule";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import formatCurrency from "../../utils/currencyFormatter";
import DashedContainer from "../common/DashedContainer";
import Spinner from "../common/Spinner";

export default function SavingTotalWidget() {
  const translate = useTranslationByClient();
  const { data, refetch, isLoading, isFetching } = useSavingSummaryModule();

  useEffect(() => {
    refetch();
  }, []);

  const containerRef = useRef(null);
  const calculateFontSize = (container: HTMLElement, number: any) => {
    const { width, height } = container.getBoundingClientRect();
    const area = width * height;
    const fontSize = Math.sqrt(area / (number.toString().length * 2)); // Adjust the formula based on your preference
    return fontSize;
  };

  useEffect(() => {
    if (data?.data && containerRef.current) {
      const container = containerRef.current as HTMLElement;
      const fontSize = calculateFontSize(container, data.data.savings!);
      container.style.fontSize = `${fontSize}px`;
    }
  }, [data]);

  return (
    <Box>
      <DashedContainer>
        {isLoading || isFetching ? (
          <Spinner text="" />
        ) : (
          <Box className="col-center" pt={1} pb={1} color="info.darker">
            <Typography
              variant="figure"
              color="info.darker"
              aria-label="savings till date"
              ref={containerRef}
            >
              {formatCurrency(data?.data.savings)}
            </Typography>
            <Typography
              aria-label="savings till date"
              color="info.darker"
              textAlign="center"
              px={1}
            >
              {translate(
                "DASHBOARD_PAGE.WIDGET.SAVINGS_TOTAL.SAVINGS_SINCE_TEXT",
                {
                  savingsSinceDate: new Date(
                    data?.data.userCreationDate,
                  ).toLocaleDateString(),
                },
              )}
            </Typography>
          </Box>
        )}
      </DashedContainer>
    </Box>
  );
}
