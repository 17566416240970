import { UIEvent, useEffect, useState } from "react";

interface useScrollabelListReturnType {
  bottomScrollOverlay: boolean;
  topScrollOverlay: boolean;
  handleScroll: (event: UIEvent<HTMLDivElement>) => void;
}
export default function useScrollableList(
  className: string,
): useScrollabelListReturnType {
  const [bottomScrollOverlay, setBottomScrollOverlay] =
    useState<boolean>(false);
  const [topScrollOverlay, setTopScrollOverlay] = useState<boolean>(false);

  const [scrollEnd, toggleScrollEnd] = useState<boolean>(false);

  useEffect(() => {
    const moduleListElement: Element =
      document.getElementsByClassName(className)[0];
    const isScrollable =
      moduleListElement &&
      moduleListElement.scrollHeight > moduleListElement.clientHeight;
    if (isScrollable && !scrollEnd) setBottomScrollOverlay(true);
  });

  useEffect(() => {
    if (scrollEnd) setBottomScrollOverlay(false);
  }, [scrollEnd]);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    event.preventDefault();

    const scrollPosition =
      event.currentTarget.scrollHeight -
      event.currentTarget.scrollTop -
      event.currentTarget.clientHeight;
    const bottom = scrollPosition >= -50 && scrollPosition <= +50;
    const top = event.currentTarget.scrollTop > 50;
    if (bottom) {
      toggleScrollEnd(true);
    } else toggleScrollEnd(false);
    if (top) {
      setTopScrollOverlay(true);
    } else setTopScrollOverlay(false);
  };
  return { bottomScrollOverlay, topScrollOverlay, handleScroll };
}
