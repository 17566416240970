import { useTheme } from "@mui/material";
import React from "react";
import ExclaimationMarkIcon from "../../assets/ExclaimationMarkIcon";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/components/widgets.scss";
import WidgetBanner from "../common/WidgetBanner";

function SavingReadyWidget() {
  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <WidgetBanner
      img={
        <ExclaimationMarkIcon
          fill={theme.palette.info.darker!}
          className="exclamation-mark"
        />
      }
      text={translate("DASHBOARD_PAGE.WIDGET.SAVINGS_READY.BANNER_MAIN_TEXT")}
      subText={translate("DASHBOARD_PAGE.WIDGET.SAVINGS_READY.BANNER_SUB_TEXT")}
      handleClick={() => console.log("ggg")}
    />
  );
}
export default SavingReadyWidget;
