import { AxiosError } from "axios";
import { useQuery } from "react-query";
import axiosConfig from "../config/axiosConfig";
import { getBaseUrl } from "../utils/authUtils";

const getMemberContactInfo = async (
  encryptedMemberUuid: string,
  infoType: "preferred" | "default",
) => {
  const baseUrl = getBaseUrl();
  const res = await axiosConfig.get(
    `${baseUrl}/member-contact-info/${encryptedMemberUuid}/${infoType}-contact-info`,
  );
  return res;
};

const useGetContactInfo = (encryptedMemberUuid: string, isDefault: boolean) => {
  const { isLoading, error, data, isSuccess, refetch, isFetching, isError } =
    useQuery(
      [
        `getMemberContactInfo-${isDefault ? "default" : "preferred"}`,
        encryptedMemberUuid,
        isDefault,
      ],
      () =>
        getMemberContactInfo(
          encryptedMemberUuid,
          isDefault ? "default" : "preferred",
        ),
      {
        onError: (err: AxiosError) => {
          return err;
        },
      },
    );
  return { isLoading, error, data, isSuccess, refetch, isFetching, isError };
};

export default useGetContactInfo;
