import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { MenuListItem } from "../../config/menuItems";

interface SideBarCollapsibleButtonProps {
  handleClick: (index: number) => void;
  link: MenuListItem;
  expanded: number;
  index: number;
  handleChildClick: (link: string) => void;
}

function SideBarCollapsibleButton({
  handleClick,
  link,
  expanded,
  index,
  handleChildClick,
}: SideBarCollapsibleButtonProps) {
  const theme = useTheme();
  return (
    <div>
      <Box
        onClick={() => handleClick(index)}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        py={1.6}
        gap={1}
        width="100%"
        flexDirection="row"
        aria-labelledby="link-text"
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography variant="body2" id="link-text" overflow="visible">
          {link.text}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="fit-content"
          bgcolor="info.light"
          borderRadius="50%"
          mr={2}
        >
          <ExpandMoreIcon
            style={{
              color: "info.darker",
              alignSelf: "center",
            }}
          />
        </Box>
      </Box>
      <Divider style={{ width: "100%" }} />
      {expanded === index && (
        <>
          <Box
            display="flex"
            width="100%"
            bgcolor="info.light"
            flexDirection="column"
          >
            {link.children?.map((item) => {
              return (
                <div key={item.text}>
                  <Box
                    onClick={() => handleChildClick(item.link!)}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    py={1.6}
                    pr={2}
                    aria-labelledby="link-text"
                    sx={{
                      color: `info.darker`,
                      cursor: "pointer",
                      "&:hover": {
                        filter: "brightness(85%)",
                        backgroundColor: `${theme.palette.info.dark}`,
                        color: `${theme.palette.info.darker}`,
                      },
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontSize="1.3rem"
                      id="link-text"
                      overflow="visible"
                      color="inherit"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </div>
              );
            })}
          </Box>
          <Divider style={{ width: "100%" }} />
        </>
      )}
    </div>
  );
}

export default SideBarCollapsibleButton;
