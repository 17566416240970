import { Drawer as MUIDrawer, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { MenuListItem, getMenuItems } from "../../config/menuItems";
import { AppContext } from "../../config/store";
import useLogoutUser from "../../hooks/auth/useLogoutUser";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { GrantedAuthority } from "../../services/api";
import {
  disableScreenScroll,
  enableScreenScroll,
} from "../../utils/globalUtils";
import NavBar from "./NavBar";
import SideBarContent from "./SideBarContent";

interface DrawerProps {
  drawer: boolean;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

function Drawer({ drawer, toggleDrawer }: DrawerProps) {
  const { postAuthUser } = useContext(AppContext);
  const { refetch: logout } = useLogoutUser();
  const handleLogout = () => {
    enableScreenScroll();
    logout();
  };

  const hasAuthority = (authorities: GrantedAuthority[], authority: string) => {
    return authorities.find((e) => e.authority === authority);
  };

  const translate = useTranslationByClient();
  const menuItems = getMenuItems(translate);

  const menuLinks: MenuListItem[] = [];
  if (postAuthUser.authorities) {
    // eslint-disable-next-line consistent-return
    menuItems.forEach((item) => {
      if (
        !item.children &&
        hasAuthority(postAuthUser.authorities!, item.authority)
      ) {
        menuLinks.push(item);
      }
      if (item.children) {
        const submenus = item.children.filter((subItem: MenuListItem) => {
          return hasAuthority(postAuthUser.authorities!, subItem.authority!);
        });
        if (submenus.length > 0) {
          menuLinks.push({ ...item, children: submenus });
        }
      }
    });
  }

  useEffect(() => {
    if (drawer === true) {
      disableScreenScroll();
    } else {
      enableScreenScroll();
    }
  }, [drawer]);

  const theme = useTheme();
  return (
    <>
      {drawer ? (
        <div
          style={{
            transition: "1s ease-in-out background-color 2s",
            position: "fixed",
            zIndex: 99,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: `${theme.palette.secondary.main}`,
            opacity: 0.95,
          }}
        />
      ) : null}
      <NavBar drawer={drawer!} toggleDrawer={toggleDrawer} />
      <MUIDrawer
        PaperProps={{
          style: {
            height: "75%",
            top: 60,
            bottom: 20,
            borderRadius: "8px",
            boxShadow: "none",
            background: `${theme.palette.info.main}`,
          },
        }}
        anchor="right"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        BackdropProps={{
          invisible: true,
        }}
        aria-label="drawer"
      >
        <SideBarContent
          toggleDrawer={toggleDrawer}
          links={menuLinks}
          handleLogout={handleLogout}
        />
      </MUIDrawer>
    </>
  );
}

export default Drawer;
