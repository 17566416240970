import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import React from "react";
import ErrorBox from "../components/common/ErrorBox";
import Header from "../components/common/Header";
import Page from "../components/common/Page";

export interface ErrorPageProps {
  error?: Error | AxiosError | string | null | unknown;
}

function ErrorPage({ error }: ErrorPageProps) {
  return (
    <Page isSecondary={false} header={<Header />}>
      <Grid container xs={12}>
        <ErrorBox error={error} />
      </Grid>
    </Page>
  );
}

export default ErrorPage;
