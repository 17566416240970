import { TranslationResource } from "../TranslationResource";

const translations: TranslationResource = {
  MENU_ITEMS: {
    PHARMACIES: "My Pharmacies",
    PRESCRIBERS: "My Prescribers",
    PROFILE: "My Profile",
    RECENT_REFILLS: "Recent Refills",
    FEEDBACK: "Feedback",
    FAQS: "FAQs",
    AGREEMENTS: "Agreements",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS_AND_CONDITIONS: "Terms and Conditions",
    LOGOUT: "Logout",
  },
  OVERLAY: {
    PRESCRIPTION: {
      TITLE: "You have prescriptions to refill!",
      APPROVE_BUTTON: "Approve Refills",
    },
    GIFT_CARD: {
      TITLE: "You have gift cards to use",
      APPROVE_BUTTON: "Approve",
    },
    DRUG_SAVING: {
      TITLE: "You have a new drug saving to approve!",
      APPROVE_BUTTON: "Approve & Save",
      SWITCH_TO_MAIL_ORDER_TEXT: "SWITCH TO MAIL ORDER",
      SWITCH_TO_GENERIC_TEXT: "SWITCH TO GENERIC",
      AVAILABLE_SAVINGS_TEXT: "AVAILABLE SAVINGS",
      SELECTED_SAVINGS_TEXT: "SELECTED SAVINGS",
    },
    WIDGET_HIDE: {
      HIDDEN_TEXT:
        "We have hidden this feature, and it may be added back by selecting “+ Add New Feature” below.",
    },
  },
  PROGRAM_DETAIL_PAGE: {
    TITLE: "Program Details",
    INTRODUCTORY_TEXT:
      "{{clientName}} and RazorMetrics invite you to RxAdvocate, offering lower-cost, equivalent prescriptions approved by your healthcare provider.",
    NOTIFICATION_TEXT:
      "Once you complete registration, you’ll receive an SMS notification ONLY when your prescribing physician has changed your medication to a lower cost equivalent.",
    CALL_TO_REG_ACTION_TEXT: "Click below to complete your registration",
    PROCEED_REGISTRATION_BUTTON: "Let's Go",
  },
  OTP_PAGE: {
    IDENTITY_VERIFICATION_TEXT:
      "First let's verify your identity. A code was just delivered to your phone. Please verify it below",
    OTP_CODE_PROMPT_TEXT:
      "Enter the six-digit verification code to complete your RxAdvocate registration.",
    RESEND_OTP_BUTTON: "Resend the code",
    PRIVACY_POLICY_ACK_TEXT:
      "By clicking submit, you acknowledge and agree to the RazorMetrics'",
    PRIVACY_POLICY_LINK_TEXT: "Privacy Policy and Terms of Use",
    OTP_PROMPT_TEXT:
      "Enter the six-digit verification code to complete authentication",
  },
  PRIVACY_POLICY_PAGE: {
    TITLE: "Privacy Policy and Terms of Use",
    POLICIES: [
      'RAZORMETRICS INC, a Delaware Corporation ("RazorMetrics" or "We"), respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes:',
      '• The types of information we may collect or that you may provide when you download, install, register with, access, or use RazorMetrics’ RxAdvocate application (the "App").',
      "• Our practices for collecting, using, maintaining, protecting, and disclosing that information.",
      "This policy applies only to information we collect in this App and in email, text, and other electronic communications sent through or in connection with this App.",
      "This policy DOES NOT apply to information that:",
      "• We collect offline or on any other RazorMetrics apps or websites, including websites you may access or be redirected through this App.",
      '• You provide to or is collected by any third party (see LINK TO "THIRD-PARTY INFORMATION COLLECTION").',
      "Third party websites and applications may have their own privacy policies which we encourage you to read before providing information on or through them.",
      'Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see LINK TO "CHANGES TO OUR PRIVACY POLICY"). Your continued use of this App after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.',
      "Persons Under the Age of 18",
      "The App is not intended for persons under 18 years of age, and we do not knowingly collect information from persons under 18 without verification of parental consent. If we learn we have collected or received information from a person under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a person under 18, please contact us at support@razormetrics.com.",
      "Information We Collect and How We Collect It",
      "We collect information from and about users of our App:",
      "• Directly from you when you provide it to us.",
      "• Automatically when you use the App.",
      "Information You Provide to Us",
      "• When you download, register with, or use this App, we may ask you provide information:",
      '• By which you may be personally identified, such as name, postal address, email address(es), telephone number(s), social security number, as well as information related to your medical history, including information that is considered “protected health information” under certain state laws and federal laws such as HIPAA and HITECH (collectively, "PHI").',
      "• That is about you but individually does not identify you such as certain demographic information.",
      "This information includes:",
      "• Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App, and requesting further services. We may also ask you for information when you report a problem with the App.",
      "• Information collected from you during your use of the App.",
      "• Information submitted by your approved health care providers.",
      "• Information you provide related to persons you consent to share your PHI with, such as family members, care-takers, or medical professionals, as well as any communications between you and such persons.",
      "• Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.",
      "• Your responses to surveys that we might ask you to complete for research purposes.",
      "• Details of transactions you carry out through the App and of the fulfillment of your requests.",
      "• Your search queries on the App.",
      "• Your messages and interactions in the interactive chat session of the App.",
      "Automatic Information Collection and Tracking",
      "When you download, access, and use the App, it may use technology to automatically collect:",
      "• Usage Details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, logs, and other communication data and the resources that you access and use on or through the App.",
      "• Device Information. We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.",
      "• Stored Information and Files. The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.",
      "• Location Information. This App may also collect real-time information about the location of your device.",
      "If you do not want us to collect this information, do not download the App or delete it from your device.",
      "We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking).",
      "Information Collection and Tracking Technologies",
      "The technologies we use for automatic information collection may include:",
      "• Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.",
      "• Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit RazorMetrics, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).",
      "Third-Party Information Collection",
      "When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:",
      "• Analytics companies.",
      "• Your mobile device manufacturer",
      "• Your mobile service provider.",
      "These third parties may use tracking technologies to collect information about you when you use this App. The information they collect may be associated with your PHI or they may collect information, including PHI, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.",
      "We do not control these third parties' tracking technologies or how they may be used and we do not monitor your interactions with third parties. Any interactions you have with third parties are solely your responsibility. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.",
      "How We Use Your Information",
      "We use information that we collect about you or that you provide to us, including any PHI, to:",
      "• Provide you with the App and its contents, and any other information, products or services that you request from us.",
      "• Fulfill any other purpose for which you provide it.",
      "• Give you notices about your account, including expiration and renewal notices.",
      "• Carry out our obligations and enforce our rights arising from any contracts or agreements entered into between you and us.",
      "• Notify you when App updates are available, and of changes to any products or services we offer or provide though it.",
      "The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:",
      "• Estimate our audience size and usage patterns.",
      "• Store information about your preferences, allowing us to customize our App according to your individual preferences.",
      "• Speed up your searches.",
      "• Recognize you when you use the App.",
      "We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data.",
      "Aggregated and De-identified Data",
      "Subject to applicable state and federal law, including but not limited to our obligations under HIPAA and HITECH, we may license, sell, or otherwise share aggregated, de-identified versions of your PHI and other data (“De-identified Information”) with our subsidiaries, affiliates, partners, customers, investors, and contractors for any purpose. You agree and acknowledge that RazorMetrics is the sole and exclusive owner of any De-identified Information created by RazorMetrics and that you have no ownership or other intellectual property rights in or to such De-identified Information.",
      "Disclosure of Your Information",
      "We may disclose PHI or other personal information that we collect or you provide:",
      "• To our subsidiaries and affiliates who are bound by contractual obligations to keep PHI and other personal information confidential and use it only for the purposes for which we disclose it to them.",
      "• To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep PHI and other personal information confidential and use it only for the purposes for which we disclose it to them.",
      "• To fulfill the purpose for which you provide it.",
      "• For any other purpose disclosed by us when you provide the information.",
      "• With your consent",
      "• To comply with any court order, law, or legal process, including to respond to any government or regulatory request.",
      "• To enforce our rights arising from any contracts entered into between you and us, including the End User License Agreement, and for billing and collection.",
      "• Subject to applicable laws, if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of RazorMetrics, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.",
      "Your Choices About Our Collection, Use, and Disclosure of Your Information",
      "We strive to provide you with choices regarding the PHI and other personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.",
      "• Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.",
      "• Promotion by RazorMetrics. If you do not want us to use your email address to promote our own or third parties' products or services, you can opt-out by emailing us at support@razormetrics.com. You can also always opt-out by logging into the App and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to support@razormetrics.com.",
      "We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (\"NAI\") on the NAI's website.",
      "Accessing and Correcting Your PHI and Other Personal Information",
      "You can review and change your PHI or other information by logging into the App and visiting your account profile page. We cannot change your PHI or other information nor can we delete your PHI or other information except by also deleting your user account. We may deny access to your PHI or personal information when required by law or if we believe such access would cause the PHI or other information of a third party to be revealed.",
      "Your California Privacy Rights",
      "California Civil Code Section 1798.83 permits users of our App that are California residents to request certain information regarding our disclosure of information to third parties for their direct marketing purposes. To make such a request, please send an email to support@razormetrics.com.",
      "Data Security",
      "We have implemented technical, physical, administrative and organizational measures designed to secure your PHI and other personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls which conform to applicable state and federal regulations.",
      "The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password or security pin number for access to certain parts of our App, you are responsible for keeping this password confidential. Do not to share your password with anyone.",
      "Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your PHI and other personal information, we cannot guarantee the security of your PHI and other personal information transmitted through our App. Any transmission of PHI and other personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.",
      "Changes to Our Privacy Policy",
      "We may update our privacy policy from time to time. If we make material changes to how we treat our users' PHI, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify you by an in-App alert the first time you use the App after we make the change.",
      "Contact Information",
      "To ask questions or comment about this privacy policy and our privacy practices, contact us at: support@razormetrics.com",
    ],
    EXERCISE_PRIVACY_RIGHTS:
      "You can exercise your privacy rights or opt-out at any time by using the following opt out link",
    OR_BY_CALLING: "or by calling",
  },
  TERMS_AND_CONDITIONS_PAGE: {
    TITLE: "Terms & Conditions",
    TERMS_AND_CONDITIONS: [
      "This END USER LICENSE AGREEMENT (“Agreement”) is a legal agreement between RazorMetrics, LLC (“RazorMetrics,” “we,” “us” or “our”) and you (“you,” “your” or “User”), and governs your access to and use of: (i) the products or services, including without limitation, any product or service features (collectively the “Service”), for which you and/or your entity or organization have purchased Usage Rights from RazorMetrics (including products or services for which RazorMetrics acts as a reseller); and (ii) any documentation, content or materials accessible via the Service (collectively, the “Content”).",
      "Notwithstanding the preceding paragraph, additional terms may accompany a Service or certain Content, in which case, such additional terms shall supplement and/or substitute the terms and conditions of this Agreement, to the extent set forth in such additional terms.",
      "This is a legal document between you and RazorMetrics. PLEASE READ IT. By accessing AND/or using the SERVICE and any content in the SERVICE, you: (i)ACKNOWLEDGE that you have read and understand all of the terms in this Agreement; and (ii)expressly agree to the terms set forth HEREIN. If you do not agree to the terms and conditions of this Agreement, do not access AND/or use the SERVICE or any content.",
      "If you agree to the terms of this Agreement, you have the following rights, obligations and responsibilities:",
      "1. Definitions.",
      "“Usage Rights” means the usage rights purchased by you or your entity or organization, as applicable, with respect to the access to and/or use of the Service and/or any Content, pursuant to the contractual agreement between you and/or your entity or organization and RazorMetrics, as applicable. “Your Content” means all content, materials and other information that you upload, transmit or distribute to end users in and/or through the Service, as further defined in Section 7.1 below. Your Content does not include User Videos. “Service Data” means all data, content and information regarding your use of the Service and/or the Content in the nature of de-identified or aggregate system administrative data, statistical, demographical data, operational information, and data generated by or characterizing the use of the Service and/or the Content. Other capitalized terms used in this Agreement shall have the definitions given to those terms herein.",
      "2. Grant of License.",
      "2.1 During the Term and subject to the terms and conditions of this Agreement, including without limitation, your Usage Rights, RazorMetrics hereby grants to you a limited, personal, revocable, nonexclusive, non-transferable, non-sublicensable right and license to: (i) access and use the Service solely for your internal, non-commercial, informational and educational use only; and (ii) use, reproduce and copy (but only to the extent set forth herein), without modification, the Content accessed through the Service, solely for your internal, noncommercial, informational and educational use only, in each case, subject to the following terms, conditions and restrictions:",
      "2.1.1 The Service may permit users to download and/or print certain Content. To the extent the Service for which you have purchased Usage Rights permits the downloading and/or printing of Content and/or User Documentation, you have the following rights:",
      "(a) The license rights granted in this Section do not include the right to copy, print or download the Content apart from the Service, and you agree not to copy, print and/or download such Content apart from the Service except as otherwise expressly permitted herein",
      "(b) To the extent the Service permits you to download and/or print Content and/or User Documentation, then the license rights granted in this Section include the right to make printouts and/or copies of the Content, and distribute such printouts and/or copies, but only as follows: (i) for patient handouts included as part of the Content, you may print and/or copy such patient handouts without modifications, and distribute such unmodified patient handouts, but only to your patients; and (ii) for all other Content, you may make a reasonable number of printouts and/or copies of the Content, without modification, solely for your internal, non-commercial, informational and educational use only. Any printouts or copies of the Content shall retain RazorMetrics’ and/or its licensors’ copyright and/or other proprietary rights notices, in addition to any and all disclaimers and/or limitations included in the Content.",
      "(c) Except as otherwise expressly set forth in this Section, downloading, printing, copying, modifying, distributing or otherwise using the Service and/or the Content for external commercial purposes, including commercial publication, sale or personal gain, is expressly prohibited",
      "2.1.2 You may only access and/or use the Service and the Content within the scope of your Usage Rights. For example, certain Usage Rights may be limited to the use of the Service and/or the Content by a single user or multiple single users, or impose other prohibitions or restrictions on your use of the Service and/or the Content. It is your obligation to know and understand the scope and extent of your Usage Rights. If you have questions as to the Usage Rights purchased by you and/or your entity or organization, please contact RazorMetrics via email at RMsupport@RazorMetrics.com, or by telephone at (512) 575-2931. If you exceed the scope of your Usage Rights and/or the license rights granted to you in this Agreement, you agree: (i) that RazorMetrics may invoice you for the additional fees commensurate with any such unauthorized use; (ii) that you will pay such invoice within thirty (30) days of your receipt of the invoice; and (iii) that such remedy is not exclusive of the other remedies available to RazorMetrics in connection with such unauthorized use.",
      "2.2 Except as expressly permitted in this Agreement, you agree not to:",
      "2.2.1 License, sublicense (except as expressly permitted by this Agreement), lease, rent, timeshare, distribute, disclose, permit access to, or transfer to any third party, any portion of the Service and/or the Content, whether for profit or without charge;",
      "2.2.2 Store, reproduce, distribute, transmit, modify, adapt, perform, display (including by “framing”), publish or sell the Service and/or the Content;",
      "2.2.3 Translate, reverse engineer, disassemble, decompile, discover, or modify the Service or RazorMetrics' and/or any of its licensors’ software",
      "2.2.4 Remove any copyright and other proprietary notices placed upon the Service or any Content retrieved from the Service;",
      "2.2.5 Circumvent any use-limitation or protection device contained in or placed upon the Service or any Content retrieved from the Service or access or attempt to access any portion of the Service or Content that you are not authorized to access;",
      "2.2.6 Use the Service to execute denial of service attacks;",
      '2.2.7 Perform automated searches against RazorMetrics\' and/or its licensors’ systems (except for non-burdensome federated search services), including automated "bots", link checkers or other scripts, without prior written permission from RazorMetrics;',
      "2.2.8 Use the Service or the Content to create products or perform services which compete or interfere with those of RazorMetrics or its licensors;",
      "2.2.9 Text mine, data mine or harvest metadata from the Service;",
      "2.2.10 Impair or overburden the Service or any servers or systems associated with the Service;",
      "2.2.11 Upload, transmit, or otherwise publish any communication or content, including without limitation, any of Your Content, to or through the Service that: (i) advertises or solicits the sale of goods or services; (ii) is obscene, indecent, or otherwise sexually explicit or abusive; (iii) defames any person or entity or otherwise invades anyone’s rights or causes any form of injury to any other person or entity; or (iv) contains software viruses or any other malicious code;",
      "2.2.12 Use the Service to send the same or substantially similar unsolicited mail messages, whether commercial or not, to multiple recipients;",
      "2.2.13 Impersonate any person or entity or otherwise misrepresent your affiliation with any person or entity;",
      "2.2.14 Violate any applicable local, state, national or international law;",
      "2.2.15 Use the Service and/or the Content in any manner that violates, infringes, or misappropriates the intellectual property rights, publicity or privacy rights, or other proprietary rights of any third party;",
      "2.2.16 Download all or parts of the Service and/or the Content in a systematic or regular manner or so as to create a collection of materials comprising all or a material subset of the Service and/or the Content, in any form; or",
      "2.2.17 Use the Service and/or the Content in connection with life support systems, medical devices, or any application or other high risk activities where failure or malfunction could lead to possible loss of life.",
      "2.3 You acknowledge and agree that the Service and the Content are licensed and not sold. The Service and/or the Content are owned by RazorMetrics and/or its licensors, and are protected by United States trademark and copyright laws and international treaty provisions. RazorMetrics and/or its licensors own and retain all right, title and interest in and to the Service and/or the Content, including any and all patents, trademarks, copyrights, trade secrets and other intellectual property rights embodied or contained therein. Your possession or use of Service and/or the Content does not transfer to you any right, title or interest in any of the foregoing and you will not acquire any such right, title or interest, except as expressly set forth in this Agreement.",
      "2.4 You acknowledge and agree that all Service Data is and shall remain the sole and exclusive property of RazorMetrics and/or its licensors. For purposes of clarity, RazorMetrics may make any legal use of the Service Data without notifying you or sharing such Service Data with you. Specifically, RazorMetrics and/or its licensors may publish and share Service Data with others in aggregate or statistical form to promote the Service and/or the Content, and for evaluating the efficiency, utility and functionality of the Service and/or the Content.",
      "3. Availability. To the extent the Service or Content is hosted by RazorMetrics, RazorMetrics shall use commercially reasonable efforts to ensure that the Service and the Content are available for use and/or access by end users on an uninterrupted basis. However, you acknowledge and agree that the Service and/or the Content will not always be available, as a result of, among other things, scheduled maintenance, system downtime, failures of the Internet generality, and other causes. RazorMetrics shall not be liable for any failure or delay or interruption in the Service and/or the Content due to failure of any equipment or telecommunications, or for failures resulting from any cause beyond RazorMetrics' reasonable control.",
      "4. Modifications or Changes. RazorMetrics and/or its licensors further reserve the right to modify, alter and/or change the Service at any time, including without limitation, by modifying, altering and/or changing the Content or any Service features or functions, or by removing or disabling certain Content or Service features or functions altogether. You acknowledge and agree that neither RazorMetrics nor any of its licensors shall have any liability to you with respect to any such modifications, alterations or changes, or with respect to any Content or Service features or functions that have been disabled or removed from the Service.",
      "5. Your Account.",
      "5.1 In order to access and use the Service, you may be required to register for a user account (“Your Account”). In registering for Your Account, you must provide accurate, current and complete information as may be prompted by any registration forms or as otherwise requested by RazorMetrics (collectively, “Registration Data”). By submitting the Registration Data, you represent and warrant that the Registration Data is accurate, current and complete. If you are registering on behalf of an entity or organization, you further represent and warrant that you are: (i) an employee or representative of that entity or organization; and (ii) are authorized to accept and agree to this Agreement on behalf of the entity or organization and to otherwise bind the entity or organization to the terms and conditions hereof.",
      "5.2 You are fully responsible for all usage and activity on the Service under Your Account and shall be liable for all such use and activity. You shall be solely responsible for maintaining the confidentiality and security of your login and password(s), and shall immediately notify RazorMetrics of any known or suspected unauthorized use(s) of Your Account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your login and password. You further agree to: (i) take reasonable efforts to resolve any unauthorized access to the Service; and (ii) reasonably cooperate with RazorMetrics in resolving the unauthorized access. Notwithstanding anything else herein, RazorMetrics reserves the right to pursue any and all claims against you under Your Account.",
      "5.3 Except as otherwise authorized in writing by RazorMetrics and/or as permitted by your Usage Rights, the license rights granted in this Agreement are limited solely for your individual use in accessing and/or using the Service and/or the Content. Except as otherwise authorized in writing by RazorMetrics and/or as permitted by your Usage Rights, you agree that under no circumstances will you permit any other person or entity, including your fellow employees or your entity or organization, to use Your Account for the purpose of accessing the Service, nor may you access and/or use the Service and/or the Content using the account information of any other person, entity or organization.",
      "6. Specific Content Terms. While appropriate care has been taken in organizing and presenting the Content, neither RazorMetrics nor any of its licensors warrant or guarantee its completeness, legality, correctness, accuracy, or timeliness. You acknowledge and agree that the Content has not been prepared to meet your (or any other User’s) individual requirements; rather, the Content is provided by RazorMetrics for general educational and informational purposes only. RazorMetrics does not perform any independent analysis or investigation of the Content or any specific information, data or content set forth in the Content. You are solely responsible for complying with applicable local, state and federal laws in connection with your use of the Service and/or the Content. You further acknowledge and agree that:",
      "6.1 Medical Content. The Content may include content and information regarding drugs and medications, as well as medical procedures or treatments (“Medical Content”). Medical Content is provided for educational and informational purposes only, and is not a substitute for the advice, opinion or diagnosis of a trained medical professional. You acknowledge and agree that neither RazorMetrics nor any of its licensors is endorsing or advocating the use of any particular drug, product, procedure, treatment, pharmaceutical or medication described in the Medical Content. RazorMetrics and its licensors expressly disclaim responsibility for any consequence of the use or misuse of any particular drug, product, procedure, treatment, pharmaceutical or medication due to any typographical error or other inaccuracy. Additional information on any particular drug, product, procedure, treatment, pharmaceutical or medication may be obtained from the manufacturer or supplier.",
      "6.2 CE Content. The Content may include certain web-based continuing educational tools and/or modules (“CE Content”). The CE Content may be used by Users to meet their continuing education requirements or to comply with professional certifications or standards (collectively, “Standard(s)”). Where RazorMetrics indicates that the CE Content complies with a particular Standard, RazorMetrics will use commercially reasonable efforts to ensure such compliance; however, neither RazorMetrics nor any of its licensors warrant or guaranty such compliance. In the event of any failure of the CE Content to comply with such Standards, you acknowledge and agree that RazorMetrics’ sole and exclusive responsibility, and your sole and exclusive remedy, shall be for RazorMetrics to: (i) correct the CE Content such that the CE Content complies with the identified Standard(s); or (ii) terminate your subscription to access and/or use the non-compliant CE Content, in which case, RazorMetrics will refund to you, on a pro-rata basis, any fees prepaid by you but unearned by RazorMetrics as of the date on which RazorMetrics terminates your subscription rights.",
      "6.3 Third Party Materials. The Service may display or make available, and the Content may include, third party content (including data, information, applications and other products services and/or materials) or provide links to third party websites or services (collectively, “Third Party Materials”). You acknowledge and agree that except as otherwise set forth in this Agreement or where RazorMetrics expressly states otherwise, RazorMetrics is not responsible for the Third Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. RazorMetrics does not assume and will not have any liability or responsibility to you or any other person or entity for any Third Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.",
      "7. Your Content.",
      "7.1 The Service may permit end users to upload and/or transmit communications, content, materials and other information owned and/or controlled by such end users (collectively, “Your Content”). Your Content is and shall at all times remain your sole and exclusive property, provided that, you hereby grant to RazorMetrics a non-exclusive, nontransferrable, non-sublicensable license to load, transmit, distribute and/or make available Your Content in and through the Service. You are solely responsible for any and all of Your Content uploaded and/or transmitted in and/or through the Service. You represent and warrant to RazorMetrics: (i) that you own Your Content or otherwise have sufficient rights in Your Content to grant the license rights granted to RazorMetrics in this Section; (ii) that Your Content does not, and its use by RazorMetrics as contemplated in this Section will not, violate, infringe, or misappropriate the intellectual property rights, publicity or privacy rights, or other proprietary rights of any third party; and (iii) that there are no claims currently pending or threatened as to your ownership of or rights in Your Content, or as to your violation, infringement or misappropriation of any third party intellectual property rights, publicity or privacy rights, or other proprietary rights with respect to Your Content, and to your knowledge, you have not engaged in any acts or omissions likely to result in any such claims.",
      "7.2 In the event that RazorMetrics discovers that Your Content is in violation of this Section 7 or Section 2.2.11 above, RazorMetrics may, without limiting its rights or creating any liability therefor, immediately remove Your Content from the Service, and may further block and/or restrict your access thereto pending resolution of any such violation.",
      "7.3 RazorMetrics shall use commercially reasonable efforts to back up Your Content as part of its standard processes and procedures with respect to the backing up of the Service and/or the Content generally. However, as between the parties, you are responsible for keeping appropriate copies and records of Your Content. Except as otherwise expressly set forth herein, RazorMetrics has no obligation to back up or maintain Your Content, and RazorMetrics takes no responsibility and assumes no liability for Your Content, including without limitation any loss or damage thereto.",
      "8. Payments. You will pay all fees specified in any order forms or similar documentation under which you acquired your license rights in and to the Service (an “Order Form”), in each case, pursuant to the terms thereof. Except as otherwise specified herein or in an Order Form: (i) fees are based on the license rights purchased and not actual usage; and (ii) except as otherwise set forth herein, payment obligations are non-cancelable and fees paid are non-refundable.",
      "9. Forums. The Service may include features such as chat rooms, bulletin board services or other interactive communication facilities (collectively, “Forum(s)”). RazorMetrics cannot and does not undertake to review all communications made on or through the Forums. RazorMetrics shall have the right, but not the obligation, to monitor the Forums and to edit or delete any materials that RazorMetrics in its sole discretion determines are in violation of this Agreement (including without limitation, Section 2.2 above), or that are otherwise contrary to RazorMetrics’ business interests and/or customer relations.",
      "10. Videos. The Service may include certain functionality which permits end users to record videos and then upload and/or transmit those videos using the Service (“User Videos”). The User Videos are and shall remain the sole and exclusive property of RazorMetrics, and you acknowledge that you have no right, title or interest in such User Videos, whether by implication, estoppel or otherwise. You hereby consent to being filmed and/or otherwise recorded in and through the Service. You further irrevocably consent, without compensation, to the use of your name, image, likeness, biographical information, and other publicity rights, in any and all media, whether now or hereafter devised, throughout the universe in perpetuity, in connection with RazorMetrics’ providing the Service and/or the Content to you pursuant to this Agreement.",
      "11. Term and Termination.",
      "11.1 Term. This Agreement will commence as of the date you first click “I ACCEPT THE TERMS OF THE LICENSE AGREEMENT” on the registration page or otherwise first indicate your acceptance of this Agreement when provided the opportunity to do so by RazorMetrics (the “Effective Date”) and will continue until terminated as set forth in this Agreement.",
      "11.2 Termination.",
      "11.2.1 This Agreement will terminate (or partially terminate with respect to a particular Service) as follows: (i) in the event you are no longer authorized to access and/or use a particular Service as a result of the expiration or termination of your Usage Rights pursuant to the applicable contractual agreement between you and/or your entity or organization and RazorMetrics; (ii) upon written notice to you effective immediately, if you commit a material breach of this Agreement; and (iii) at any time upon thirty (30) days’ prior notice to you.",
      "11.2.2 If this Agreement is terminated in whole or in part due to your breach: (i) RazorMetrics will disable access to any terminated Service; (ii) you agree to destroy any and all Content, and all other files, information, data or software derived from any terminated Service in your possession or control, and certify destruction upon request; (iii) you forfeit all prepaid fees; and (iv) RazorMetrics reserves the right to pursue all available legal remedies. The parties intend that the parties’ rights, obligations and responsibilities set forth in Sections 1, 2.3, 2.4, 6, 7.1, 8, 10, 11.2.2, 12, 14, 15, 17, 18.2, 22, 23, 24 and 25 shall survive the termination of this Agreement.",
      "12. Indemnity. You agree to defend, indemnify and hold harmless RazorMetrics, its licensors, affiliates, successors and assigns, and each of their respective officers, directors, agents, and employees (the “Indemnitees”), for, from and against any and all claims, demands, loss, damage, liability, or expense (including, but not limited to, attorneys’ fees at trial, on appeal, and on any petition for review), arising out of or related to the breach of this Agreement by you, or otherwise arising from the use of the Service and/or the Content by you, including without limitation, any actual or threatened suit, demand or claim made against the Indemnitees arising out of or relating to your conduct, your violation of this Agreement, your violation of the rights of any third party, or the Content.",
      "13. Audits. RazorMetrics may retain administrative access to Your Account through the Service for purposes of auditing your compliance with the terms of this Agreement, including without limitation, your usage of the Service and/or the Content. RazorMetrics and/or its designees shall further have the right, upon reasonable prior notice to you, to audit and inspect your systems, devices, and/or records for purposes of determining your compliance with this Agreement, which audit right may include the right to access and inspect your facilities and copy any documents or records in connection with such audit. You agree to cooperate with RazorMetrics in connection with any such audit. In the event any such audit discloses use of the Service in excess of your Usage Rights or any other material breach of this Agreement, you acknowledge and agree that RazorMetrics shall have the rights set forth in Section 2.1.2 above, and you further agree to reimburse RazorMetrics for the costs and expenses incurred by RazorMetrics in connection with such audit.",
      "14. Disclaimer.",
      "14.1 EXCEPT AS EXPRESSLY WARRANTED HEREIN, THE SERVICE AND THE CONTENT IS PROVIDED “AS IS” AND “AS AVAILABLE.” RazorMetrics AND ITS LICENSORS DISCLAIM ALL OTHER REPRESENTATIONS AND WARRANTIES OF ANY KIND RELATING TO THE SERVICE AND/OR THE CONTENT, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AVAILABILITY, ACCURACY, TIMELINESS, CORRECTNESS, RELIABILITY, CURRENCY, OR COMPLETENESS OF THE SERVICE, THE CONTENT OR ANY INFORMATION OR RESULTS OBTAINED THROUGH THE SERVICE, EVEN IF ASSISTED BY RazorMetrics. RazorMetrics SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY FOR DETERMINING THE COMPATIBILITY OF ANY HARDWARE OR SOFTWARE NOT SUPPLIED BY RazorMetrics WITH THE SERVICE AND PROVIDES NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE OPERATION OF SUCH HARDWARE OR SOFTWARE WITH THE SERVICE.",
      "14.2 WHILE RazorMetrics HAS USED COMMERCIALLY REASONABLE EFFORTS TO ENSURE THAT THE CONTENT IS MATERIALLY UP TO DATE AND RELIABLE, RazorMetrics DOES NOT DIRECTLY OR INDIRECTLY PRACTICE MEDICINE OR DISPENSE MEDICAL ADVICE OR SERVICES AND THEREFORE ASSUMES NO LIABILITY FOR THE CONTENT. YOU AGREE TO EXERCISE YOUR OWN INDEPENDENT SKILL, EXPERIENCE, KNOWLEDGE AND PROFESSIONAL MEDICAL JUDGMENT IN MAKING CLINICAL, TREATMENT AND/OR PRESCRIPTION DECISIONS, OR TO OTHERWISE CONSULT OR SEEK THE ADVICE, OPINION OR DIAGNOSIS OF A TRAINED MEDICAL PROFESSIONAL. THE SOLE RISK OF USING THE SERVICE AND/OR THE CONTENT IS WITH YOU.",
      "15. Limitation of Liability. THE MAXIMUM LIABILITY OF RazorMetrics AND/OR ITS LICENSORS ARISING OUT OF OR RELATING TO THE SERVICE, THE CONTENT AND/OR THIS AGREEMENT SHALL BE LIMITED TO THE TOTAL AMOUNT OF FEES RECEIVED BY RazorMetrics FROM YOU AND/OR YOUR ENTITY OR ORGANIZATION, AS APPLICABLE, IN THE 12 MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR WHICH SUCH DAMAGES ARE ALLEGED TO BE OWED. IN NO EVENT SHALL RazorMetrics AND/OR ITS LICENSORS BE LIABLE TO YOU FOR: (i) ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES; (ii) ANY CLAIM RELATED TO YOUR CONTENT OR ANY OTHER USER GENERATED CONTENT OR MATERIALS, OR ANY THIRD PARTY MATERIALS; OR (iii) ANY CLAIM RELATED TO THE UNAUTHORIZED ACCESS TO AND/OR USE OF THE SERVICE AND/OR THE CONTENT.",
      "16. Communications. By accessing and/or using the Service, you agree to receive certain electronic communications from RazorMetrics.",
      "17. Privacy.",
      "17.1 Any personal data supplied by you, including Registration Data, will be collected, used and disclosed in accordance with RazorMetrics’ Privacy Policy. Please refer to RazorMetrics’ Privacy Policy for information on how RazorMetrics collects, uses, and discloses personal data from its users, including the use of cookies.",
      "17.2 You acknowledge and agree that your personal data may be transferred to other countries or jurisdictions outside of your country or jurisdiction of residence, and that the protections afforded such personal data under the laws and regulations of the country or jurisdiction to which such personal data is transferred may not be comparable to or as protective as the protections afforded such personal data in your country or jurisdiction of residence. You further expressly consent to RazorMetrics’ transfer of your personal data to such countries or jurisdictions.",
      "18. Remedial Action.",
      "18.1 Without limiting the above, RazorMetrics may suspend delivery of the Service and/or the Content if it reasonably determines that you are not in compliance with this Agreement, including without limitation, by not making full and timely payment to RazorMetrics and/or exceeding your Usage Rights. If delivery is suspended, RazorMetrics will restore your access as soon as you come back into compliance with the terms of this Agreement. RazorMetrics’ suspension of the Service and/or the Content is without prejudice to any right, claim or remedy of RazorMetrics under this Agreement, including without limitation, RazorMetrics’ rights under Section 11 above.",
      "18.2 You hereby acknowledge and agree that, in the event of any breach or threatened breach of your obligations or responsibilities under Section 2 above or any other provision affecting RazorMetrics and/or its licensors intellectual property rights in the Service and/or the Content, RazorMetrics may suffer irreparable injury for which damages at law may not be an adequate remedy. Accordingly, without prejudice to any other rights and remedies otherwise available to RazorMetrics at law or equity, RazorMetrics shall be entitled to seek equitable relief, including injunctive relief and specific performance, for any such breach or threatened breach of this Agreement by you.",
      "19. Export Regulation. The Service and/or the Content may be subject to United States’ export control laws, including the U.S. Export Administration Act and its associated regulations. You agree as follows:",
      "19.1 You represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.",
      "19.2 You shall not, directly or indirectly, export, re-export or release the Service and/or the Content to, or make the Service and/or the Content accessible from, any jurisdiction or country to which export, re-export or release is prohibited by law, rule or regulation. You shall comply with all applicable federal laws, regulations and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing or otherwise making the Service and/or the Content available outside the United States.",
      "20. Government Rights. The Service may constitute commercial computer software, as such term is defined in 48 C.F.R. § 2.101. Accordingly, if you are an agency of the U.S. Government or any contractor therefor, you receive only those rights with respect to the Service as are granted to all other end users under license, in accordance with: (i) 48 C.F.R. § 227.7201 through 48 C.F.R. § 227.7204, with respect to the Department of Defense and their contractors; or (ii) 48 C.F.R. §12.212, with respect to all other U.S. Government licensees and their contractors.",
      "21. Third Party Beneficiaries. You acknowledge and agree that RazorMetrics’ licensors may be third party beneficiaries under this Agreement having the right, but not the obligation, to enforce this Agreement in accordance with its terms. Except as set forth in the preceding sentence or elsewhere in this Agreement, and except as set forth in Section 12 above, there are no third party beneficiaries to this Agreement.",
      "22. Amendments. We may update or modify this Agreement and any policies affecting the Service and/or the Content immediately upon notice to you posted to the Service or distributed via electronic mail. You will be required to click “I ACCEPT THE TERMS OF THE LICENSE AGREEMENT” upon any subsequent use of the Service and/or the Content following such an update or modification. Your clicking “I ACCEPT THE TERMS OF THE LICENSE AGREEMENT” and your subsequent use of the Service and/or the Content shall be conclusively deemed an acceptance of all such updates or modifications. If you do not wish to be bound by such update or modification and so notify us prior to your clicking “I ACCEPT THE TERMS OF THE LICENSE AGREEMENT,” and we will refund any unearned fees that you have paid. Any updates or modifications to this Agreement shall be deemed to supersede and/or replace the Agreement in its prior form, to the extent set forth in such updates or modifications.",
      "23. Governing Law; Disputes. This Agreement is governed by the laws of the United States and the State of California, without reference to conflict of laws principles. Except where prohibited, you agree that all disputes, claims and legal proceedings in any way arising out of or relating to the Service, the Content, or this Agreement shall be resolved individually, without resort to any form of class action, and exclusively in the state or federal courts located in San Joaquin County, California. You waive all defenses of lack of personal jurisdiction, improper venue, “forum non conveniens” and the like with respect to such courts. TO THE EXTENT ALLOWED UNDER APPLICABLE LAW, EACH OF THE PARTIES HERETO HEREBY IRREVOCABLY WAIVES ALL RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR COUNTERCLAIM (WHETHER BASED ON CONTRACT, TORT OR OTHERWISE) ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE ACTIONS OF ANY PARTY HERETO IN THE NEGOTIATION, ADMINISTRATION, PERFORMANCE OR ENFORCEMENT HEREOF.",
      "24. Entire Agreement. This Agreement constitutes the entire agreement between the parties hereto with respect to its subject matter and supersedes all previous and contemporaneous agreements between the parties with respect to the same subject matter hereof and may not, except as otherwise set forth in this Agreement, be amended or modified except in a writing signed by the parties. The terms of your purchase orders, if any, are for your convenience and do not supersede any term or condition of this Agreement.",
      "25. General. The parties agree as follows: (i) no waiver will be binding on a party unless it is in writing and signed by the party making the waiver and a party’s waiver of a breach of a provision of this Agreement will not be a waiver of any other provision or a waiver of a subsequent breach of the same provision; (ii)the parties will have all remedies available to them at law or in equity; (iii) if any term or provision of this Agreement is determined to be unenforceable in any respect, the enforceability of the term or provision in any other respect and of the remaining provisions of this Agreement will not be impaired; and (iv) both parties have full power and authority to enter into and perform this Agreement.",
    ],
  },
  FEEDBACK_PAGE: {
    FEEDBACK_EMAIL_SUBJECT: "App Feedback",
    TITLE: "Share your feedback!",
    FEEDBACK_CALL_TO_ACTION_TEXT: "Share your thoughts at the email address",
    OR_CLICK_BELOW_TEXT: "or click below",
    CONTACT_US_BUTTON: "Contact us!",
  },
  PHARMACIES_PAGE: {
    NO_PHARMACY_YET_TEXT: "No pharmacies yet! Check again later!",
    UNKNOWN_PHARMACY_TEXT: "Unknown Pharmacy",
  },
  PRESCRIBER_PAGE: {
    UNKNOWN_PRESCRIBER_TEXT: "Unknown Prescriber",
    NO_PRESCRIBER_YET_TEXT: "No prescribers yet! Check again later!",
  },
  DRUGS: {
    UNSPECIFIED_DRUG_NAME_TEXT: "Drug Name not Specified",
    INVALID_DAYS_SUPPLY_TEXT: "N/A days supply",
    NO_DRUGS_YET_TEXT: "No drugs yet! Check again later!",
    DRUG_LIST_TITLE: "Additional Prescriptions",
    MEMBER_PAID_OUT_OF_POCKET: "Member paid out of pocket",
    ESTIMATED_SAVINGS_TEXT: "Estimated Savings",
    WAITING_FOR_PHARMACY_PICKUP: "Waiting for pickup at your pharmacy",
  },
  DASHBOARD_PAGE: {
    INTRODUCTORY_TEXT:
      "Each time your medication provider approves a prescription change, RxAdvocate will text you with your prescription updates.",
    WELCOME_TEXT: "Welcome {{userName}}!",
    ENROLLMENT_TEXT:
      "You are now enrolled in {{clientName}} prescription savings plan.",
    ADD_NEW_FEATURE_BUTTON: "Add New Feature",
    WIDGET: {
      SHOW_ALL_OVERLAY: {
        HEADING: "Select the available features you would like to use",
      },
      SAVINGS_TOTAL: {
        DISPLAY_NAME: "Savings Total",
        SAVINGS_SINCE_TEXT: "Your savings since {{savingsSinceDate}}",
      },
      SAVINGS_ASSISTANT: {
        DISPLAY_NAME: "Savings Assistant",
        TITLE: "Savings Assistant",
        SAVINGS_IDENTIFIED_TEXT_1: "I have identified the",
        SAVINGS_IDENTIFIED_TEXT_2: " BEST SAVINGS ",
        SAVINGS_IDENTIFIED_TEXT_3: "for your medications!",
        MONTHLY_SAVINGS_TEXT: "Monthly savings",
        SOURCE_SHOW_TEXT: "Show",
        SOURCE_ALL_METHOD_TEXT: "All Methods",
        SORTING_METHOD_TEXT: "Sort By",
        NO_SAVINGS_FOUND_TEXT: "No savings found",
        SAVINGS_HIGHEST_TEXT: "Savings Highest",
        SAVINGS_LOWEST_TEXT: "Savings Lowest",
      },
      SAVINGS_START: {
        DISPLAY_NAME: "Savings Start",
        AVAILABLE_TEXT: "Available Savings",
        SAVED_TO_DATE_TEXT: "Saved To Date",
      },
      SAVINGS_READY: {
        DISPLAY_NAME: "Savings Status",
        BANNER_MAIN_TEXT: "You have savings ready.",
        BANNER_SUB_TEXT: "select to save",
      },
      PHARMACY_SAVINGS: {
        DISPLAY_NAME: "Member Savings",
        BANNER_MAIN_TEXT: "See what's waiting at your pharmacy.",
        BANNER_SUB_TEXT: "Click to view",
        MORE_INFO_BUTTON: "More info",
      },
      PRESCRIPTIONS_READY: {
        DISPLAY_NAME: "Prescriptions Ready",
        BANNER_MAIN_TEXT: "You have prescriptions ready.",
        BANNER_SUB_TEXT: "select to refill",
      },
      GIFT_CARD: {
        DISPLAY_NAME: "Gift Cards",
        BANNER_MAIN_TEXT: "You have {{amount}} in Gift Cards!",
        BANNER_SUB_TEXT: "select for more",
      },
    },
  },
  LOGIN_PAGE: {
    PHONE_PROMPT_TEXT: "Please enter your Phone number",
    DATE_OF_BIRTH_PROMPT_TEXT: "Please enter your date of birth (mm/dd/yyyy)",
  },
  SWITCHES: {
    SWITCH_LIST_TITLE: "Updated Prescriptions",
    NO_SWITCHES_YET_TEXT: "No switches yet! Check again later!",
  },
  SWITCH_NOTIFICATION_PAGE: {
    TITLE: "You have savings on a prescription",
    PRESCRIPTION_CHANGE_NOTIFICATION_TEXT:
      "Your prescriber ({{drugPrescriberName}}) has changed your prescription to save you money.",
    ESTIMATED_SAVINGS_TEXT:
      "Your estimated savings with this new prescription are {{savings}}/fill.",
    PLAN_SAVINGS_TEXT:
      "Your plan will save {{savings}} on this new prescription which may reduce your insurance premiums.",
  },
  LOGOUT_PAGE: {
    LOGOUT_TEXT:
      "You have been logged out. You will be redirected to the Login Page in {{remainingTime}} seconds.",
    SAVE_TO_DEVICE_CALL_TO_ACTION_TEXT:
      "Follow the link below to save this link for future use",
    SAVE_TO_DEVICE_BUTTON: "Save To Device",
    GO_TO_LOGIN_BUTTON: "Go to the Login Page",
    TAP_TEXT: "Tap",
    AND_SELECT_TEXT: "and select",
    FOLLOW_STEPS_TEXT:
      "Follow the steps below to save this link for future use",
    ADD_TO_HOME_SCREEN_TEXT: "Add to Home Screen",
  },
  PROFILE_PAGE: {
    TITLE: "My Profile",
    CONFIRM_INFO_TEXT: "Confirm your profile information below",
    PERMANENT_CHANGES_DISCLAIMER_TEXT:
      "Profile details are only saved in your RxAdvocate profile. For permanent changes, please contact your company's HR department",
    EDIT_PREFERRED_BUTTON: "Edit Preferred",
    ADDRESS_SECTION: {
      HEADING:
        "The address will be used by RxAdvocate for contact and shipping purpose",
      STREET_INPUT_PLACEHOLDER: "street",
      STREET_INPUT_LABEL: "Street",
      SUITE_INPUT_PLACEHOLDER: "apt/suite",
      SUITE_INPUT_LABEL: "Apt/Suite",
      CITY_INPUT_PLACEHOLDER: "city",
      CITY_INPUT_LABEL: "City",
      ZIP_CODE_INPUT_PLACEHOLDER: "zip code",
      ZIP_CODE_INPUT_LABEL: "Zip Code",
      PROVIDED_ADDRESS_TEXT: "Provided Address",
      PREFERRED_ADDRESS_TEXT: "Preferred Address",
      STATE_INPUT_LABEL: "State",
      STATE_INPUT_PLACEHOLDER: "state",
    },
    PHONE_SECTION: {
      HEADING:
        "The phone number will be used by RxAdvocate for contact and shipping purpose",
      PHONE_INPUT_LABEL: "Phone",
      PROVIDED_PHONE_TEXT: "Provided Phone",
      PREFERRED_PHONE_TEXT: "Preferred Phone",
    },
    EMAIL_SECTION: {
      HEADING:
        "The email address will be used by RxAdvocate for contact and shipping purpose",
      EMAIL_INPUT_LABEL: "Email",
      EMAIL_INPUT_PLACEHOLDER: "test@test.com",
      PROVIDED_EMAIL_TEXT: "Provided Email",
      PREFERRED_EMAIL_TEXT: "Preferred Email",
    },
  },
  FAQ_PAGE: {
    TITLE: "Frequently Asked Questions",
    QUESTIONS: [
      {
        question: "What is RxAdvocate",
        answer:
          "As you all know prescription cost continue to rise. These costs affect you and your family and keep the cost of insurance high. RxAdvocate benefit program analyzes claims data and can save on average over $300 per member through recommending “switches” to equivalent drug prescriptions. RxAdvocate uses a secure proprietary platform designed by physicians and pharmacists to search for savings on your prescriptions.",
      },
      {
        question: "How does the program work and do I have to do anything",
        answer:
          "No, you don’t have to do anything. Any potential prescription switches that are identified by the RxAdvocate system are then sent directly to your personal prescriber (doctor, nurse practitioner, physician assistant) for review. After reviewing the suggested switches your own physician or prescriber will submit a new prescription to your pharmacy.  At no time is your personal information shared with your employer.",
      },
      {
        question:
          "Will suggested changes and savings be released to my Employer",
        answer:
          "No, RxAdvocate takes your privacy very seriously and will not release your private information to your company. They contracted with RxAdvocate , a HIPAA compliant company to ensure your information is safe and privacy protected. No personal information will be shared with your Employer.",
      },
    ],
    VIEW_COMPLETE_LIST_CALL_TO_ACTION_TEXT:
      "To check out our complete list of FAQs, go to our website",
  },
  DISCOUNT_PAGE: {
    MAIN_TEXT:
      "You have {{number_of_cards}} discount cards available from {{vendors}} for your prescription(s).",
    YOUR_DISCOUNT_CARDS_TEXT: "Your discount Cards",
    USAGE_INSTRUCTIONS: {
      TITLE: "Usage Instructions",
      INSTRUCTION_1_TEXT: "Pick up your prescriptions at your pharmacy.",
      INSTRUCTION_2_TEXT:
        "During prescription pickup, present your Discount Card to your pharmacist at the corresponding pharmacy.",
      INSTRUCTION_3_TEXT:
        "Check with the pharmacy to make sure you recieve savings via this discount card compared to your health plan",
      BOTTOM_TEXT:
        "Questions? Refer to {{link}} Need further help? Email us at {{email}}",
    },
    DISCOUNT_CARD: {
      MAIN_TEXT_1:
        "Show the following {{vendor}} Discount Card to your pharmacist at",
      MAIN_TEXT_2: "for",
      BIN_TEXT: "BIN",
      PCN_TEXT: "PCN",
      GROUP_TEXT: "Group",
      COUPON_TEXT: "Coupon",
    },
    PHARMACY_SUPPORT_TEXT: "Pharmacy Support",
    CUSTOMER_SUPPORT_TEXT: "Customer Support",
  },
  GENERAL: {
    MEMBER_ID: "Member ID",
    BUTTON: {
      CONFIRM: "Confirm",
      WIDGET_HIDE: "hide this",
      START_SAVINGS: "Start Saving!",
      OK_THANKS: "OK, Thanks!",
      THANKS: "Thanks",
      DONE: "Done",
      BACK: "Back",
      CANCEL: "Cancel",
      SAVE: "Save",
      SAVING: "Saving",
      OK: "Ok",
    },
    PHONE_NUMBER_PROMPT_TEXT: "Please enter a phone number",
    DAY: "day",
    ERROR: {
      USER_NOT_AUTHORIZED: "User not Authorized",
      SOMETHING_WENT_WRONG: "Something went wrong",
      AUTH_INTERNAL_ERROR: "Something went wrong!",
      AUTH_INVALID_VERIFICATION_CODE: "Invalid Login Code entered. Try again.",
      AUTH_TOO_MANY_REQUESTS: "Too many requests. Try later.",
      MEMBER_NOT_FOUND: "Member not found",
      USER_ALREADY_EXISTS: "Cannot register. User already exists",
      PHONE_NUMBER_ALREADY_IN_USE: "Phone number is already in use",
      NULL_MEMBER_ID: "You are not logged in.\nPlease login!",
      INVALID_UUID: "Provided Id is incorrect",
      ACCESS_DENIED: "Access denied",
      UNABLE_TO_FIND_USER: "User does not exist",
      INCORRECT_DATE_OF_BIRTH: "Incorrect date of birth entered. Try again!",
      MEMBER_BLOCKED: "Member blocked temporarily. Try again later!",
      INVALID_USER_HIDDEN_MODULE: "Invalid module",
      INVALID_MODULE: "Invalid module",
      MODULE_ALREADY_DISABLED: "Module already disabled",
      INVALID_LOGGED_IN_USER: "Logged in user is invalid",
      SWITCH_NOT_FOUND: "Requested switch does not exist.",
      NO_DISCOUNT_CARDS: "No discount cards found for user.",
      DRUGS_NOT_FOUND: "Could not load drugs!",
      PHARMACIES_NOT_FOUND: "Could not load pharmacies!",
      PRESCRIBERS_NOT_FOUND: "Could not load prescribers!",
      SWITCH_NOT_BELONG_TO_CURRENT_USER:
        "Requested switch belongs to some other user.",
      INTERNAL_SERVER_ERROR: "Something went wrong!",
      BAD_REQUEST: "Something went wrong!",
      FORBIDDEN: "Something went wrong!",
      NOT_FOUND: "Something went wrong!",
      PAGE_NOT_FOUND: "Oops! this page does not exist!",
      BAD_GATEWAY: "Something went wrong!",
      TOO_MANY_REQUESTS: "Too many requests. Try again.",
      UNAUTHORIZED: "You are not logged in/do not have access! Login again!",
      LOGGED_OUT: "You are not logged in.\nPlease login!",
      UNABLE_TO_FIND_MEMBER_DETAILS: "Member not found",
      LOGIN_AGAIN: "Something went wrong! Login Again!",
      CODE_401: "You are not logged in/do not have access! Login again!",
      CODE_429: "Too many requests. Try again.",
      CODE_408: "Request timed out. Try again!",
      INVALID_PHONE_NUMBER: "Invalid Phone Number.",
      INVALID_EMAIL: "Invalid Email Address.",
      EMAIL_LENGTH_EXCEED: "Email can't exceed {{max}} characters",
      EMPTY_PHONE_NUMBER: "Empty Phone Number",
      INVALID_DATE_OF_BIRTH:
        "Please provide a valid date of birth in format mm/dd/yyyy",
      PHONE_NUMBER_NOT_FOUND: "Phone number not found",
      UNSPECIFIED_SWITCH_ID: "Switch id not specified",
      SESSION_EXPIRED: "Your session has expired. Login again!",
      USER_NOT_FOUND: "User not found",
      NUMERIC_ALLOWED: "only numbers allowed",
      ALPHA_ALLOWED: "only letters and space allowed",
      ALPHA_NUMERIC_ALLOWED: "only letters, numbers and space allowed",
      LENGTH_EXCEED: "can't exceed {{max}} characters",
    },
    SPINNER: {
      FETCHING_MEMBER_DATA_TEXT: "fetching Member data",
      FETCHING_FEATURES_TEXT: "fetching features",
      LOADING_TEXT: "Loading",
      VERIFYING_PHONE_NUMBER_TEXT: "Verifying Phone Number",
      VERIFYING_DOB_TEXT: "Verifying date of birth",
      VERIFYING_LOGIN_CODE: "verifying Login Code",
      GETTING_ALL_SWITCHES: "Getting all Switches",
      UPDATING_PROFILE_INFO: "Updating Profile Information",
      UPDATING_LANGUAGE: "Updating Language",
    },
    SUCCESS: {
      USER_REGISTERED: "User Registered!",
      USER_LOGGED_IN: "User Logged in!",
      LOGIN_CODE_SENT: "Login code sent!",
      CONTACT_INFO_UPDATED: "Contact Info Updated",
      LANGUAGE_UPDATED: "Language Updated",
      LOGGED_OUT_SUCCESSFULLY: "Logged out successfully",
    },
    GO_TO_LOGIN_PAGE: "Go to the login page",
    READ_MORE: "read more",
    READ_LESS: "read less",
  },
};

export default translations;
