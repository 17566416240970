import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import SaveToDevice from "../components/common/SaveToDevice";
import useSetPageTitle from "../hooks/custom/useSetPageTitle";
import useTranslationByClient from "../hooks/useTranslationByClient";

export default function LogoutPage() {
  const translate = useTranslationByClient();
  const navigate = useNavigate();
  useSetPageTitle();
  let redirectTimeout: ReturnType<typeof setTimeout>;
  let countInterval: ReturnType<typeof setInterval>;
  const secondsRemaining = useRef(10);
  const theme = useTheme();
  const [displayTime, setDisplayTime] = useState(secondsRemaining.current);

  const countDown = () => {
    countInterval = setInterval(() => {
      //  TODO: handle with just ref
      secondsRemaining.current -= 1;
      setDisplayTime(secondsRemaining.current);
    }, 1000);
  };
  const redirect = () => {
    navigate("/");
  };
  useEffect(() => {
    redirectTimeout = setTimeout(() => redirect(), 10500);
    countDown();
    return () => {
      clearTimeout(redirectTimeout);
      clearInterval(countInterval);
    };
  }, []);

  return (
    <Page isSecondary={false} header={<Header />}>
      <Grid
        item
        xs={12}
        width="100%"
        height="80%"
        minHeight="80vh"
        className="col-center"
        textAlign="center"
        pr={2}
        pl={2}
        pb={4}
      >
        <CheckCircleIcon
          className="errorIcon"
          sx={{ color: theme.palette.info.darker }}
        />
        <br />
        <Typography
          mt={1}
          variant="h6"
          color="info.darker"
          textTransform="uppercase"
        >
          {translate("LOGOUT_PAGE.LOGOUT_TEXT", {
            remainingTime: displayTime.toString(),
          })}
        </Typography>
        <SaveToDevice />
        <Box mt={5}>
          <Button onClick={redirect}>
            <Typography
              variant="h6"
              color="info.darker"
              lineHeight={1}
              fontSize="1.2rem"
              textTransform="uppercase"
              style={{
                textDecoration: "underline",
              }}
            >
              {translate("LOGOUT_PAGE.GO_TO_LOGIN_BUTTON")}
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Page>
  );
}
