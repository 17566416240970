import axios from "axios";
import { useMutation } from "react-query";
import { addHeadersToPublicRequest } from "../config/axiosConfig";
import queryClient from "../config/queryClient";
import { UpdateMemberPreferredContactInfoDto } from "../services/api";
import {
  getBaseUrl,
  getCookie,
  getMemberId,
  setCookies,
} from "../utils/authUtils";
import createToast from "../utils/globalUtils";
import { AuthData } from "./custom/useOTP";
import useGetErrorMessage from "./useGetErrorMessage";
import useTranslationByClient from "./useTranslationByClient";

const updateContactInfo = async (
  updateMemberPreferredContactInfoDto: UpdateMemberPreferredContactInfoDto,
) => {
  const memberId = getMemberId();
  const baseUrl = getBaseUrl();
  const oldToken = getCookie("accessToken");
  const res = await axios.put(
    `${baseUrl}/member-contact-info/${memberId}/update-preferred`,
    updateMemberPreferredContactInfoDto,
    {
      headers: {
        Authorization: `Bearer ${oldToken}`,
        ...addHeadersToPublicRequest(),
      },
    },
  );
  return res;
};

const useUpdateContactInfo = (
  phoneAuthData: AuthData,
  setIsEditing: any,
  setDisableSubmit: any,
  setCanSubmit: any,
  setShowOverlay: any,
) => {
  const translate = useTranslationByClient();
  const getErrorMessage = useGetErrorMessage();
  return useMutation(updateContactInfo, {
    meta: {
      action: "update",
    },
    onSuccess: () => {
      const { tokens } = phoneAuthData;
      if (tokens && tokens.accessToken !== "") {
        setCookies(
          tokens.accessToken,
          tokens.refreshToken,
          tokens.expirationTime,
        );
      }
      queryClient
        .invalidateQueries(["getMemberContactInfo-preferred"])
        .finally(() => {
          setIsEditing(false);
          setDisableSubmit(true);
          setCanSubmit(false);
          setShowOverlay(false);
          createToast(
            `${translate("GENERAL.SUCCESS.CONTACT_INFO_UPDATED")}!`,
            "success",
          );
        });
    },
    onError: (e) => {
      queryClient
        .invalidateQueries(["getMemberContactInfo-preferred"])
        .finally(() => {
          setIsEditing(false);
          setDisableSubmit(true);
          setCanSubmit(false);
          setShowOverlay(false);
          createToast(getErrorMessage(e), "error");
        });
    },
  });
};

export default useUpdateContactInfo;
