import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../config/i18n";
import { AppContext } from "../config/store";
import { TranslationKey } from "../languages/TranslationResource";
import useGetClientNameAbbreviation from "./custom/useGetClientNameAbbreviation";

export type TranslateFunction = (
  string: TranslationKey,
  substitutionMap?: { [key: string]: string | boolean },
) => string;

const useTranslationByClient = () => {
  const { preAuthUser, postAuthUser } = useContext(AppContext);
  const clientNameAbbreviation = useGetClientNameAbbreviation(
    preAuthUser,
    postAuthUser,
  );
  const ns = clientNameAbbreviation;
  const { t: translation } = useTranslation(ns);

  return (
    string: TranslationKey,
    substitutionMap?: { [key: string]: string | boolean },
  ) => {
    return getTranslation(string, translation, ns, substitutionMap);
  };
};

export default useTranslationByClient;
