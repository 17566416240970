import LanguageIcon from "@mui/icons-material/Language";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { LanguageCode, LanguageKey } from "../../config/i18n";
import { AppContext } from "../../config/store";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import useUpdateLanguage from "../../hooks/useUpdateLanguage";
import "../../styles/components/header.scss";
import { ScreenOverlay } from "./ScreenOverlay";
import Spinner from "./Spinner";

function LanguageDropdown() {
  const { mutate: updateLanguage, isLoading } = useUpdateLanguage();
  const theme = useTheme();
  const translate = useTranslationByClient();
  const { preAuthUser, postAuthUser } = useContext(AppContext);

  let language =
    postAuthUser.memberLanguage && postAuthUser.memberLanguage in LanguageCode
      ? postAuthUser.memberLanguage
      : preAuthUser.memberLanguage;
  if (!language) {
    language = "";
  }

  const getRenderValue = (value: string) => {
    return value === "" ? (
      <LanguageIcon sx={{ color: theme.palette.info.darker }} />
    ) : (
      LanguageCode[value as LanguageKey]
    );
  };

  const onChangeLanguage = (event: any) => {
    const updatedLanguage = event.target.value;
    if (updatedLanguage in LanguageCode) {
      updateLanguage(updatedLanguage);
    }
  };

  if (isLoading) {
    return (
      <ScreenOverlay
        centerContent
        isSecondary
        mainSection={
          <Box className="spinner-container" aria-label="spinner">
            <Spinner text={translate("GENERAL.SPINNER.UPDATING_LANGUAGE")} />
          </Box>
        }
      />
    );
  }

  return (
    <FormControl variant="standard">
      <Select
        displayEmpty
        value={language}
        style={{ color: theme.palette.info.darker }}
        renderValue={getRenderValue}
        onChange={onChangeLanguage}
      >
        {Object.keys(LanguageCode).map((lngKey: string) => {
          return (
            <MenuItem
              key={lngKey}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: `${theme.palette.info.darker}70`,
                  "&.Mui-focusVisible": {
                    background: `${theme.palette.info.darker}70`,
                  },
                },
                minHeight: "20px",
              }}
              value={lngKey}
            >
              <Typography variant="body2">
                {lngKey
                  .toLowerCase()
                  .replace(/^./, (char) => char.toUpperCase())}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default LanguageDropdown;
