/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, useTheme } from "@mui/material";
import React from "react";

export interface DashedContainerProps {
  isExpanded?: boolean;
  moreInfo?: string;
  bgColor?: string;
  handleExpansion?: () => void;
}
function DashedContainer({
  children,
  isExpanded,
  handleExpansion,
  moreInfo,
  bgColor,
}: React.PropsWithChildren<DashedContainerProps>) {
  const theme = useTheme();
  return (
    <Box
      mr={2}
      ml={2}
      pt={1.5}
      pb={1.5}
      display="flex"
      className="col-center"
      sx={{
        borderRadius: "0.5rem",
        border: `2px dashed ${theme.palette.info.dark}`,
        backgroundColor: `${bgColor || theme.palette.info.light}`,
      }}
    >
      {children}

      {/* {isExpanded !== undefined ? (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography
            pl={2}
            variant="subtitle2"
            color="info.infoDarker"
            textAlign="left"
            style={{ textDecoration: "underline" }}
            onClick={handleExpansion}
          >
            <b> {isExpanded === true ? "less info" : "more info"}</b>
          </Typography>
          {isExpanded === true && (
            <>
              <Divider
                style={{
                  width: "100%",
                  marginTop: "1.25em",
                  border: "2x dashed #CCCCCC",
                }}
              />
              <Box minHeight="100px" className="row-center">
                <Typography variant="subtitle2" color="info.infoDarker">
                  <b> {moreInfo ?? "More info about item here"}</b>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      ) : null} */}
    </Box>
  );
}
export default DashedContainer;
