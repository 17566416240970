import { Box, useTheme } from "@mui/material";
import React, { useLayoutEffect } from "react";
import "../../styles/components/spinner.scss";
import setBackgroundColor from "../../utils/setBackgroundColor";
import { ScreenOverlay } from "../common/ScreenOverlay";
import Spinner from "../common/Spinner";

interface WidgetHideOverlayProps {
  text: string;
  isSecondary?: boolean;
}
function SpinnerOverlay({ text, isSecondary }: WidgetHideOverlayProps) {
  const theme = useTheme();

  useLayoutEffect(() => {
    setBackgroundColor(
      isSecondary ?? false,
      theme.palette.primary.main,
      theme.palette.secondary.main,
    );
  }, []);

  return (
    <ScreenOverlay
      centerContent
      isSecondary={isSecondary ?? false}
      mainSection={
        <Box className="spinner-container" aria-label="spinner">
          <Spinner text={text} />
        </Box>
      }
    />
  );
}

export default SpinnerOverlay;
