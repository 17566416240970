/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import React, { Component, ReactNode } from "react";
import ErrorBox from "../components/common/ErrorBox";

interface StateType {
  hasError: boolean;
}
export default class ErrorBoundary extends Component<ReactNode, StateType> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log("Error Boundary Caught : ", error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <Box className="container">
        <ErrorBox error="Oops! Something went wrong" />
      </Box>
    ) : (
      this.props.children
    );
  }
}
