import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../config/store";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { clearSession } from "../../utils/authUtils";
import history from "./../../config/history";

export interface LandingComponentProp {
  clientName?: string;
  setMemberId?: any;
}

export function LandingComponent({
  clientName,
  setMemberId,
}: LandingComponentProp) {
  const navigate = useNavigate();
  const translate = useTranslationByClient();
  const { setPreferredPhone, setPreAuthUser, setPostAuthUser } =
    useContext(AppContext);
  const onClickGo = () => {
    navigate("/");
  };

  const onCancel = () => {
    setMemberId(null);
    setPreferredPhone(null);
    setPreAuthUser({
      memberInfoId: "",
      memberPhone: "",
      firebaseUserId: "",
    });
    setPostAuthUser({});
    clearSession();
    navigate("/");
  };

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        onCancel();
      }
    };
  }, [history]);

  return (
    <Grid
      xs={12}
      width="100%"
      height="100%"
      mx={3.75}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignContent="center"
      flexGrow={1}
    >
      <Grid xs={12} mt={2}>
        <Typography
          variant="h2"
          fontSize="2rem"
          letterSpacing={-1.86}
          mb={4}
          textAlign="center"
          sx={{ wordSpacing: "3px" }}
        >
          {translate("PROGRAM_DETAIL_PAGE.TITLE")}
        </Typography>
        <Grid xs={12} width="100%">
          <Typography
            variant="body1"
            textAlign="center"
            mb={5}
            lineHeight={1.5}
            pr={2}
            pl={2}
          >
            {translate("PROGRAM_DETAIL_PAGE.INTRODUCTORY_TEXT", {
              clientName: clientName || "",
            })}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            mb={5}
            lineHeight={1.5}
            pr={2}
            pl={2}
          >
            {translate("PROGRAM_DETAIL_PAGE.NOTIFICATION_TEXT")}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            lineHeight={1.5}
            mb={5}
            pr={2}
            pl={2}
          >
            <b> {translate("PROGRAM_DETAIL_PAGE.CALL_TO_REG_ACTION_TEXT")}:</b>
          </Typography>
        </Grid>
      </Grid>

      <Box
        width="100%"
        mb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
        className="justify-center"
      >
        <Button variant="primary" onClick={onClickGo} aria-label="submit">
          {translate("PROGRAM_DETAIL_PAGE.PROCEED_REGISTRATION_BUTTON")}
        </Button>
        <Button onClick={onCancel}>
          <Typography variant="subtitle1" sx={{ textDecoration: "underline" }}>
            {translate("GENERAL.BUTTON.CANCEL")}
          </Typography>
        </Button>
      </Box>
    </Grid>
  );
}
