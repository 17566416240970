import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { convertStringToRegExp } from "../../utils/globalUtils";

interface InputFieldProps {
  label: string;
  placeholder: string;
  type?: string;
  onChange: any;
  value: string | undefined;
  ariaLabel?: string;
  pattern?: string;
  errorMessage?: string;
  autoFocus?: boolean;
}

export default function InputField({
  label,
  placeholder,
  type,
  onChange,
  value,
  ariaLabel,
  pattern,
  errorMessage,
  autoFocus,
}: InputFieldProps) {
  const [message, setMessage] = useState<string>("");
  const inputPatternRegularExpresion =
    pattern && convertStringToRegExp(pattern) !== null
      ? convertStringToRegExp(pattern)
      : /^[A-Za-z0-9\s]+$/;
  return (
    <>
      <Grid item xs={12} mb="0.3rem">
        <Typography variant="body1_secondary" lineHeight={1.267}>
          {label}
        </Typography>
      </Grid>
      <TextField
        autoFocus={autoFocus}
        className="secondary"
        fullWidth
        type={type ?? "text"}
        placeholder={placeholder}
        aria-label={ariaLabel}
        value={value}
        onChange={(e) => {
          if (
            e.target.value === "" ||
            inputPatternRegularExpresion!.test(e.target.value)
          ) {
            setMessage("");
          } else {
            setMessage(errorMessage ?? "Invalid character entered");
          }
          onChange(e);
        }}
        sx={{
          marginBottom: "0.4rem",
          "& input": {
            border:
              !value ||
              value === "" ||
              (value && inputPatternRegularExpresion!.test(value))
                ? "initial"
                : "1px solid red",
            color: "info.darker",
          },
        }}
        helperText={message}
      />
    </>
  );
}
