import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import useTranslationByClient, {
  TranslateFunction,
} from "../../hooks/useTranslationByClient";
import "../../styles/blurry-scroll.scss";
import { formatPhoneNumber } from "../../utils/globalUtils";
import ScrollableList from "../common/ScrollableList";

export interface PrivacyComponentProps {
  handleClick: () => void;
}

const getPolicies = (translate: TranslateFunction) => {
  const terms: any = translate("PRIVACY_POLICY_PAGE.POLICIES", {
    returnObjects: true,
  });
  return typeof terms !== "string" ? (Object.values(terms) as string[]) : [];
};

function PrivacyComponent({ handleClick }: PrivacyComponentProps) {
  const theme = useTheme();
  const translate = useTranslationByClient();
  const paragraphs = getPolicies(translate);

  return (
    <Grid
      // item xs={12} sm={10} textAlign="center" mt={2.5} mx={3.75} mb={3.75}
      container
      xs={12}
      width="100%"
      height="auto"
      mx={3.75}
      justifyContent="center"
      alignContent="space-around"
      flexGrow={1}
    >
      <Grid item xs={12} className="col-center">
        <Typography
          variant="h2"
          fontSize="2rem"
          lineHeight={0.97}
          letterSpacing={-1.86}
          mb={1}
          textAlign="center"
          sx={{ wordSpacing: "3px" }}
        >
          {translate("PRIVACY_POLICY_PAGE.TITLE")}
        </Typography>

        <ScrollableList>
          <>
            {paragraphs.map((paragraph) => {
              return (
                <Typography
                  key={paragraph}
                  mt={3}
                  variant="body1"
                  textAlign="justify"
                  sx={{
                    hyphens: "auto",
                    wordSpacing: "-0.1em",
                  }}
                >
                  {paragraph}
                </Typography>
              );
            })}
            <Typography
              mt={3}
              variant="body1"
              textAlign="justify"
              sx={{
                hyphens: "auto",
                wordSpacing: "-0.12em",
              }}
            >
              {translate("PRIVACY_POLICY_PAGE.EXERCISE_PRIVACY_RIGHTS")}:{" "}
              <a
                style={{ color: `${theme.palette.info.darker}` }}
                href="https://razormetrics.com/legal/opt-out/"
              >
                https://razormetrics.com/legal/opt-out/
              </a>{" "}
              {translate("PRIVACY_POLICY_PAGE.OR_BY_CALLING")}:{" "}
              <a
                href="tel:+18559729070"
                style={{ color: `${theme.palette.info.darker}` }}
              >
                {formatPhoneNumber("+1 (855) 972-9070")}
              </a>
            </Typography>
          </>
        </ScrollableList>
      </Grid>
      <Box className="page-back-button">
        <Button
          variant="primary"
          aria-label="agree to privacy policy"
          onClick={handleClick}
          className="disabled-hover-effects"
        >
          {translate("GENERAL.BUTTON.OK")}
        </Button>
      </Box>
    </Grid>
  );
}

export default PrivacyComponent;
