import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import discountLogo from "./../../assets/razor-discount-logo-lg.png";
import { DiscountCardDto } from "./../../services/api/models/DiscountCardDto";
import DiscountCard from "./DiscountCard";
import UsageInstructions from "./UsageInstructions";

export interface DiscountDetailComponentProps {
  discountCards: DiscountCardDto[];
}

function DiscountDetailComponent({
  discountCards,
}: DiscountDetailComponentProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard");
  };

  const translate = useTranslationByClient();
  return (
    <Box width="100%" height="100%">
      <Grid
        container
        xs={12}
        width="100%"
        height="auto"
        px={2}
        justifyContent="center"
        alignContent="space-around"
      >
        <Grid item xs={12} mb={10} className="col-center">
          <img
            src={discountLogo}
            alt="RazorMetrics logo"
            aria-label="RazorMetrics logo"
            style={{ marginBlock: "0.8rem" }}
          />
          <Box>
            <Typography
              variant="body1"
              sx={{
                overflow: "visible",
                color: "info.darker",
                fontSize: "1rem",
                padding: "0.5rem",
              }}
              textAlign="center"
            >
              {translate("DISCOUNT_PAGE.MAIN_TEXT", {
                number_of_cards: `${discountCards?.length ?? 0}`,
                vendors: Array.from(
                  new Set(
                    discountCards.map(
                      (discountCard) => discountCard?.vendorInfoDto?.vendorName,
                    ),
                  ),
                ).join(","),
              })}
            </Typography>
            <Typography
              variant="h3"
              fontSize="1.5rem"
              lineHeight={0.97}
              mb={2}
              mt={2}
              color="info.darker"
              textAlign="center"
              sx={{ wordSpacing: "3px", textTransform: "capitalize" }}
            >
              {translate("DISCOUNT_PAGE.YOUR_DISCOUNT_CARDS_TEXT")}
            </Typography>
            <UsageInstructions />
            {discountCards.map((discountCard) => (
              <DiscountCard
                key={discountCard.drugName}
                discountCard={discountCard}
              />
            ))}
          </Box>
        </Grid>
        <Box className="page-back-button">
          <Button
            variant="primary"
            aria-label="back"
            onClick={handleClick}
            className="disabled-hover-effects"
          >
            {translate("GENERAL.BUTTON.OK_THANKS")}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default DiscountDetailComponent;
