import { AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import axiosConfig from "../config/axiosConfig";
import { LanguageKey } from "../config/i18n";
import { AppContext } from "../config/store";
import createToast from "../utils/globalUtils";
import useTranslationByClient from "./useTranslationByClient";

const updateLanguage = async (language: LanguageKey) => {
  const res = await axiosConfig.put(`/user/update-language`, language, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
  return res;
};

const useUpdateLanguage = () => {
  const { setPostAuthUser, setPreAuthUser, postAuthUser, preAuthUser } =
    useContext(AppContext);

  const translate = useTranslationByClient();
  return useMutation(updateLanguage, {
    meta: {
      action: "update",
    },
    onSuccess: (response: AxiosResponse<string>) => {
      setPostAuthUser({ ...postAuthUser, memberLanguage: response.data });
      setPreAuthUser({ ...preAuthUser, memberLanguage: response.data });
      createToast(
        `${translate("GENERAL.SUCCESS.LANGUAGE_UPDATED")}!`,
        "success",
      );
    },
  });
};

export default useUpdateLanguage;
