import { FormHelperText, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Input, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { localEnvs } from "../../utils/globalUtils";
import CountryFlag from "../loginPage/CountryFlag";

interface ProfilePhoneSectionProps {
  isEditingPreferredPhone: boolean;
  defaultPhone: string;
  editablePreferredPhone: string;
  setEditablePreferredPhone: any; // React.Dispatch<React.SetStateAction<string>>;
  currentPreferredPhone: string | undefined | null;
}

function ProfilePhoneSection({
  isEditingPreferredPhone,
  defaultPhone,
  editablePreferredPhone,
  setEditablePreferredPhone,
  currentPreferredPhone,
}: ProfilePhoneSectionProps) {
  const defaultCountry = localEnvs.includes(`${process.env.REACT_APP_ENV}`)
    ? "PK"
    : "US";

  const theme = useTheme();
  const translate = useTranslationByClient();

  const preferredPhoneJSX = isEditingPreferredPhone ? (
    <>
      <Grid item xs={12} my={1} className="col-center">
        <Typography variant="body1" textAlign="center">
          {translate("PROFILE_PAGE.PHONE_SECTION.HEADING")}.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        my={1}
        p={2}
        sx={{
          border: "0.5px solid",
          borderColor: `${theme.palette.info.dark}`,
          backgroundColor: `${theme.palette.info.main}`,
        }}
        className="rounded-container"
      >
        <Grid item xs={12}>
          <Typography variant="body1_secondary" lineHeight={1.267}>
            {translate("PROFILE_PAGE.PHONE_SECTION.PHONE_INPUT_LABEL")}
          </Typography>
        </Grid>
        <Grid item xs={12} container aria-label="Phone Number" spacing={0}>
          <Grid item xs={2}>
            <CountryFlag
              country={defaultCountry}
              color={`${theme.palette.info.dark}`}
              borderWidth="0.2px"
            />
          </Grid>
          <Grid item xs={10}>
            <Input
              international
              defaultCountry={defaultCountry}
              country={defaultCountry}
              // placeholder="(123) 456-7890"
              style={{
                textAlign: "left",
                paddingLeft: "8px",
                maxWidth: "calc(99.5% - 10px)",
                borderColor: `${theme.palette.info.dark}`,
                borderLeft: 0,
                color: `${theme.palette.primary.darker}`,
                backgroundColor: `${theme.palette.info.darker}10`,
                caretColor: `${theme.palette.primary.darker}`,
                border:
                  editablePreferredPhone === "" ||
                  (editablePreferredPhone &&
                    isValidPhoneNumber(
                      `+${getCountryCallingCode(
                        defaultCountry,
                      )}${editablePreferredPhone}`,
                    ))
                    ? `0.2px solid ${theme.palette.info.dark}`
                    : "0.2px solid red",
              }}
              className="phone-number-input-secondary row-center"
              value={`+${getCountryCallingCode(
                defaultCountry,
              )}${editablePreferredPhone}`}
              onChange={(value: string) => {
                setEditablePreferredPhone(
                  value?.replace(
                    new RegExp(`^[+]${getCountryCallingCode(defaultCountry)}`),
                    "",
                  ),
                );
              }}
            />
            {!(
              editablePreferredPhone === "" ||
              (editablePreferredPhone &&
                isValidPhoneNumber(
                  `+${getCountryCallingCode(
                    defaultCountry,
                  )}${editablePreferredPhone}`,
                ))
            ) && (
              <FormHelperText>
                {translate("GENERAL.ERROR.INVALID_PHONE_NUMBER")}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : null;

  return (
    <div>
      <Grid item xs={12} my={1} className="col-center">
        <Typography variant="body1" textAlign="center">
          {translate("PROFILE_PAGE.PHONE_SECTION.PROVIDED_PHONE_TEXT")}
        </Typography>
      </Grid>
      <Typography
        variant="h2"
        mb={1}
        textAlign="center"
        fontWeight={600}
        sx={{
          textDecoration:
            isEditingPreferredPhone || currentPreferredPhone
              ? "line-through"
              : "",
        }}
      >
        {defaultPhone}
      </Typography>
      {(isEditingPreferredPhone ||
        (currentPreferredPhone && currentPreferredPhone !== "")) && (
        <Grid item xs={12} className="col-center">
          <Typography variant="body1" mb={1} textAlign="center">
            {translate("PROFILE_PAGE.PHONE_SECTION.PREFERRED_PHONE_TEXT")}
          </Typography>
        </Grid>
      )}

      {!isEditingPreferredPhone &&
        currentPreferredPhone &&
        currentPreferredPhone !== "" && (
          <Typography variant="h2" mb={1} textAlign="center" fontWeight={600}>
            {currentPreferredPhone}
          </Typography>
        )}
      {preferredPhoneJSX}
    </div>
  );
}

export default ProfilePhoneSection;
