// import admin from "firebase-admin";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import auth from "../config/firebaseConfig";

// add properties to window object
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
  }
}

// create capchta element
export const createCaptcha = (
  successCallback: () => void,
  expiredCallback: () => void,
) => {
  // only add recpatcha container if does not exists already
  if (document.getElementById("recaptcha-container") === null) {
    const captchaDiv = document.createElement("div");
    captchaDiv.id = "recaptcha-container";
    document.body.appendChild(captchaDiv);
  }
  return new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback: () => {
        successCallback();
      },
      "expired-callback": () => {
        expiredCallback();
      },
    },
    auth,
  );
};

// remove captcha element
export const removeCaptcha = () => {
  const captchaDiv: HTMLElement | null = document.getElementById(
    "recaptcha-container",
  );
  if (captchaDiv) {
    captchaDiv.remove();
    window.recaptchaVerifier.clear();
  }
};

// send otp
export const sendOtpCode = async (phoneNo: string) => {
  const res = await signInWithPhoneNumber(
    auth,
    phoneNo,
    window.recaptchaVerifier,
  );
  return res;
};
