import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { TranslationKey } from "../../languages/TranslationResource";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

function UsageInstructions() {
  const translate = useTranslationByClient();
  const [expanded, setExpanded] = useState(true);
  const instructions = [
    "INSTRUCTION_1_TEXT",
    "INSTRUCTION_2_TEXT",
    "INSTRUCTION_3_TEXT",
  ];

  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.info.dark}`,
        padding: "0.5rem",
        borderRadius: "8px",
      }}
    >
      <Accordion
        style={{
          borderRadius: "8px",
          width: "100%",
          backgroundColor: `${theme.palette.info.lighter}50`,
        }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          sx={{ minWidth: "fit-content" }}
          expandIcon={
            <Box
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="fit-content"
              bgcolor={`${theme.palette.info.light}`}
            >
              <ExpandMoreIcon sx={{ color: "info.darker" }} />
            </Box>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid xs={10} maxWidth="90%">
            <Typography
              width="100%"
              variant="body3"
              fontWeight="700"
              fontSize="1.25rem"
              color="info.darker"
              paddingLeft={2}
            >
              {translate("DISCOUNT_PAGE.USAGE_INSTRUCTIONS.TITLE")}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px",
            margin: "0px",
            overflow: "visible",
            backgroundColor: `${theme.palette.info.lighter}50`,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="column"
            pb={2}
            px={2}
            overflow="visible"
            sx={{ gap: "0.5rem" }}
          >
            {/* <ol type="1" style={{ listStylePosition: "inside" }}>
              {instructions.map((ins) => (
                <li key={ins}>
                  {translate(
                    `DISCOUNT_PAGE.USAGE_INSTRUCTIONS.${ins}` as TranslationKey,
                  )}
                </li>
              ))}
            </ol> */}

            {instructions.map((ins, ind) => (
              <Box
                key={ins}
                display="flex"
                width="100%"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap={1}
              >
                <Typography
                  component="span"
                  variant="body1"
                  fontWeight={500}
                  fontSize="1rem"
                  color="info.darker"
                  sx={{
                    overflow: "visible",
                  }}
                  aria-label={ins}
                >
                  {ind + 1}.
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  fontWeight={500}
                  fontSize="1rem"
                  color="info.darker"
                  sx={{ wordBreak: "break-word", letterSpacing: "initial" }}
                  aria-label={ins}
                >
                  {translate(
                    `DISCOUNT_PAGE.USAGE_INSTRUCTIONS.${ins}` as TranslationKey,
                  )}
                </Typography>
              </Box>
            ))}

            <Typography
              variant="body1"
              fontWeight={500}
              fontSize="1rem"
              color="info.darker"
              sx={{ wordBreak: "break-word" }}
            >
              {translate("DISCOUNT_PAGE.USAGE_INSTRUCTIONS.BOTTOM_TEXT", {
                link: "razormetrics.com/faqs",
                email: "rmsupport@razormetrics.com",
              })}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default UsageInstructions;
