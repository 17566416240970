import DoneIcon from "@mui/icons-material/Done";
import { Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import formatCurrency from "../../../utils/currencyFormatter";
import notifyLogo from "./../../../assets/notify-logo-switch.svg";
import discountLogo from "./../../../assets/razor-discount-logo-sm.png";

function DrugItem({ drug, onSelect, index }: any) {
  const theme = useTheme();
  const translate = useTranslationByClient();
  const notifySource = "NOTIFY";
  const getItemLogo = () => {
    return drug.source == "NOTIFY" ? notifyLogo : discountLogo;
  };

  const isDisabled = drug.source == notifySource || drug.source == "DISCOUNT";
  const shouldWait = drug.source == notifySource;
  return (
    <Grid
      mt={1}
      width="100%"
      gap={1}
      className="drug-pill"
      sx={{
        backgroundColor: theme.palette.grayShades.light,
        border: `1px solid ${theme.palette.grayShades.main}`,
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      key={drug.name}
    >
      <Grid xs={7} display="flex" alignItems="flex-start" gap="15px">
        <Box
          sx={{
            borderColor: `${theme.palette.info.light}`,
            backgroundColor: `${theme.palette.info.main}`,
            borderRadius: "50%",
            cursor: isDisabled ? "not-allowed" : "pointer",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minWidth="30px"
          maxWidth="40px"
          maxHeight="40px"
          width="15%"
          minHeight="30px"
          onClick={() => {
            if (isDisabled) {
              return;
            }
            onSelect(index);
          }}
          aria-label="item-checkbox"
        >
          {drug.isSelected ? (
            <DoneIcon
              sx={{
                fontSize: "2rem",
                stroke: isDisabled
                  ? `${theme.palette.grayShades.light}`
                  : `${theme.palette.info.dark}`,
              }}
              className="widget-done-button"
            />
          ) : null}
        </Box>

        <Box display="flex" width="100%" flexDirection="column">
          <Typography
            variant="body3"
            fontWeight="500"
            fontSize="1.3rem"
            color="info.darker"
            aria-label="drug name"
            sx={{ wordWrap: "break-word" }}
          >
            {drug.drugName && drug.drugName !== ""
              ? drug.drugName
              : translate("DRUGS.UNSPECIFIED_DRUG_NAME_TEXT")}
          </Typography>
          <Typography
            variant="body3"
            fontWeight="400"
            fontSize="0.6rem"
            color="info.darker"
            mb={1}
            aria-label="drug strength and dosage"
            sx={{
              wordWrap: "break-word",
            }}
          >
            {drug.drugStrength ?? ""}
          </Typography>
          <img
            src={getItemLogo()}
            alt="RazorMetrics logo"
            aria-label="RazorMetrics logo"
            width="80"
            height="15"
          />
        </Box>
      </Grid>
      <Grid
        xs={5}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box className="drug-dosage" textAlign="right">
          <Typography
            variant="body3"
            fontWeight="400"
            fontSize="1.8rem"
            color="info.darker"
            aria-label="drug strength and dosage"
            sx={{
              wordWrap: "break-word",
              paddingBottom: 0,
            }}
          >
            {formatCurrency(drug.estimatedSavings, 2)}
          </Typography>
        </Box>
        <Typography
          variant="body3"
          fontWeight="400"
          fontSize="0.7rem"
          fontFamily="Roboto"
          textAlign="right"
          color="info.darker"
          aria-label="drug strength and dosage"
          sx={{
            wordWrap: "break-word",
          }}
        >
          {translate("DRUGS.ESTIMATED_SAVINGS_TEXT")}
        </Typography>
        {shouldWait && (
          <Typography
            variant="body3"
            fontWeight="400"
            fontSize="0.5rem"
            fontFamily="Roboto"
            textAlign="right"
            color="info.darker"
            aria-label="drug strength and dosage"
            sx={{
              wordWrap: "break-word",
            }}
          >
            *{translate("DRUGS.WAITING_FOR_PHARMACY_PICKUP")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default DrugItem;
