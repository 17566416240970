/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import history from "../../config/history";
import { AppContext } from "../../config/store";
import useGetMemberfromPhone from "../../hooks/auth/useGetMemberfromPhone";
import useVerifyMemberDateOfBirth from "../../hooks/auth/useVerifyMemberDateOfBirth";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import createToast, { isValidPhone, localEnvs } from "../../utils/globalUtils";
import SaveToDevice from "../common/SaveToDevice";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";
import useStyles from "../styles";
import CountryFlag from "./CountryFlag";

export default function LoginComponent({
  setGetMember,
  setMemberPhone,
  isCaptchaSuccessful,
}: any) {
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const { preAuthUser } = useContext(AppContext);
  const phoneRef = useRef(null);
  const inputRef = useRef(null);
  const dobRef = useRef("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  useEffect(() => {
    if (inputRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [phoneNumber]);

  useEffect(() => {
    dobRef.current = dateOfBirth;
  }, [dateOfBirth]);

  const { isLoading, refetch, isFetching, status, data } =
    useGetMemberfromPhone(phoneNumber, dateOfBirth);
  const {
    isLoading: isDOBLoading,
    refetch: verifyDOB,
    isFetching: isDOBFetching,
    status: dobStatus,
    data: dobData,
  } = useVerifyMemberDateOfBirth(dateOfBirth);

  const translate = useTranslationByClient();

  const defaultCountry = localEnvs.includes(`${process.env.REACT_APP_ENV}`)
    ? "PK"
    : "US";
  const handleSubmit = () => {
    if (!phoneRef.current) {
      createToast(
        `${translate("GENERAL.ERROR.EMPTY_PHONE_NUMBER")} ${translate(
          "GENERAL.PHONE_NUMBER_PROMPT_TEXT",
        )}`,
        "error",
        3000,
      );
      return;
    }
    if (!isValidPhone(phoneRef.current)) {
      createToast(
        translate("GENERAL.ERROR.INVALID_PHONE_NUMBER"),
        "error",
        3000,
      );
      return;
    }
    // @ts-ignore
    if (dobRef.current == null || dobRef.current?.length != 8) {
      createToast(
        `${translate("GENERAL.ERROR.INVALID_DATE_OF_BIRTH")}`,
        "error",
        3000,
      );
      return;
    }

    if (preAuthUser.memberInfoId !== "" && !preAuthUser.firebaseUserId) {
      verifyDOB();
    } else {
      refetch();
    }
  };

  const reverifyCaptcha = async () => {
    await window.recaptchaVerifier.verify();
    handleSubmit();
  };

  const navigate = useNavigate();

  useEffect(() => {
    phoneRef.current = phoneNumber;
  }, [phoneNumber]);

  useEffect(() => {
    if (status === "success" && data) {
      localStorage.setItem("memberId", encodeURIComponent(data.data));
      localStorage.setItem("phone", phoneNumber);
      setGetMember(encodeURIComponent(data.data));
      setMemberPhone(phoneNumber);
      navigate("/otp");
    }
  }, [status, data]);

  useEffect(() => {
    if (dobStatus === "success" && dobData && phoneRef.current) {
      setMemberPhone(phoneRef.current);
      localStorage.setItem("phone", phoneRef.current);
      history.push("/otp");
    }
  }, [dobStatus, dobData]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter" ||
        event.keyCode === 13
      ) {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const theme = useTheme();

  const classes = useStyles(
    theme.palette.info.light,
    theme.palette.info.darker!,
  )();

  return (
    <Grid
      xs={12}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={0.8}
      flexDirection="column"
      display="flex"
    >
      <Grid xs={12} item className="col-center" width="100%">
        <Grid xs={10} mb={4} mt={4}>
          <Typography variant="body1" lineHeight={1.267}>
            {translate("LOGIN_PAGE.PHONE_PROMPT_TEXT")}
          </Typography>
        </Grid>
        <Grid container aria-label="dateOfBirth" spacing={0}>
          <Grid item xs={2}>
            <CountryFlag country={defaultCountry} />
          </Grid>
          <Grid item xs={10}>
            <Input
              key="phone-number"
              name="phone"
              defaultCountry={defaultCountry}
              country={defaultCountry}
              autoFocus
              ref={inputRef}
              // placeholder="(123) 456-7890"
              value={phoneNumber}
              onChange={setPhoneNumber}
              style={{
                borderBlock: "2.5px solid",
                borderRight: "2.5px solid",
                backgroundColor: `${theme.palette.info.light}`,
                borderColor: `${theme.palette.info.dark}`,
                color: `${theme.palette.info.darker}`,
                caretColor: `${theme.palette.info.darker}`,
              }}
              className="phone-number-input row-center"
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justifyContent="center"
          textAlign="center"
        >
          <Grid xs={10} mb={3} mt={5}>
            <Typography variant="body1" lineHeight={1.267}>
              {translate("LOGIN_PAGE.DATE_OF_BIRTH_PROMPT_TEXT")}
            </Typography>
          </Grid>
          <Grid
            mb={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
            aria-label="otp code"
          >
            <OtpInput
              value={dateOfBirth}
              onChange={setDateOfBirth}
              numInputs={8}
              renderSeparator={<span />}
              containerStyle="otp-input otpContainer"
              inputStyle={classes.toDetailPage}
              renderInput={(props: any) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input {...props} />{" "}
                </div>
              )}
              inputType="tel"
            />
          </Grid>
        </Grid>

        <SaveToDevice />
      </Grid>
      <Box className="public-page-back-button">
        <Button
          variant="primary"
          onClick={isCaptchaSuccessful ? handleSubmit : reverifyCaptcha}
          aria-label="submit"
          disabled={
            phoneNumber === undefined ||
            phoneNumber.length == 0 ||
            dateOfBirth.length !== 8
          }
        >
          {translate("GENERAL.BUTTON.CONFIRM")}
        </Button>
      </Box>
      {preAuthUser.externalMemberId && (
        <Typography ml={1} className="page-member-id-text">
          {translate("GENERAL.MEMBER_ID")}: {preAuthUser.externalMemberId}
        </Typography>
      )}
      {isLoading || isFetching ? (
        <SpinnerOverlay
          text={translate("GENERAL.SPINNER.VERIFYING_PHONE_NUMBER_TEXT")}
        />
      ) : null}
      {isDOBLoading || isDOBFetching ? (
        <SpinnerOverlay
          text={translate("GENERAL.SPINNER.VERIFYING_DOB_TEXT")}
        />
      ) : null}
    </Grid>
  );
}
