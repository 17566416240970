import { AxiosError } from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import axiosConfig from "../../config/axiosConfig";
import history from "../../config/history";
import { AppContext } from "../../config/store";
import { clearSession } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import useGetErrorMessage from "../useGetErrorMessage";
import usePersistentContext from "../usePersistentContext";
import useTranslationByClient from "../useTranslationByClient";

const logoutUser = async () => {
  const res = await axiosConfig.get("user/logout");
  return res;
};

const useLogoutUser = () => {
  const { setValue: toggleDrawer } = usePersistentContext("toggleDrawer");
  const { setPostAuthUser, setPreAuthUser } = useContext(AppContext);
  const getErrorMessage = useGetErrorMessage();
  const translate = useTranslationByClient();
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["logoutUser"],
    logoutUser,
    {
      enabled: false,
      onSuccess: () => {
        clearSession();
        setPreAuthUser({
          memberInfoId: "",
          memberPhone: "",
          firebaseUserId: "",
        });
        setPostAuthUser({});
        toggleDrawer("false");
        createToast(
          translate("GENERAL.SUCCESS.LOGGED_OUT_SUCCESSFULLY"),
          "success",
        );
        history.push("/logout");
      },
      onError: (err: AxiosError) => {
        createToast(getErrorMessage(err), "error", 3000);
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
};

export default useLogoutUser;
