import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SadSmileyIcon from "../../../assets/SadSmileyIcon";
import SavingsAssistantIcon from "../../../assets/SavingsAssistantIcon";
import { AppContext } from "../../../config/store";
import useGetErrorMessage from "../../../hooks/useGetErrorMessage";
import useGetUserAllSavings from "../../../hooks/useGetUserAllSavings";
import useSendNotificationForDiscountCards from "../../../hooks/useSendNotificationForDiscountCards";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import "../../../styles/components/widgets.scss";
import formatCurrency from "../../../utils/currencyFormatter";
import createToast from "../../../utils/globalUtils";
import ScrollableList from "../../common/ScrollableList";
import Spinner from "../../common/Spinner";
import DrugItem from "./DrugItem";
import Filter from "./Filter";

function SavingsAssistant() {
  const theme = useTheme();
  const translate = useTranslationByClient();
  const { data, refetch, isLoading, isFetching, error } =
    useGetUserAllSavings();
  const { postAuthUser } = useContext(AppContext);
  const originalList = useRef([]);
  const getErrorMessage = useGetErrorMessage();
  const { mutate } = useSendNotificationForDiscountCards();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.data?.savings) {
      // @ts-ignore
      originalList.current = data.data.savings.map((item) => ({
        ...item,
        isSelected: true,
      }));

      setDrugList(originalList.current);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      createToast(getErrorMessage(error), "error");
    }
  }, [error]);

  const [drugList, setDrugList] = useState(
    data?.data?.savings
      ? data.data.savings.map((item) => ({
          ...item,
          isSelected: true,
        }))
      : [],
  );

  const onSelect = (index: number) => {
    const temp = [...drugList];
    temp[index].isSelected = !temp[index].isSelected;
    setDrugList([...temp]);
  };

  const sourceList = [
    {
      name: translate(
        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SOURCE_ALL_METHOD_TEXT",
      ),
      value: "ALL",
    },
    { name: "RazorNotify", value: "NOTIFY" },
    { name: "RazorDiscount", value: "DISCOUNT" },
  ];
  const sortingMethods = [
    {
      name: translate(
        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SAVINGS_HIGHEST_TEXT",
      ),
      value: "DESC",
    },
    {
      name: translate(
        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SAVINGS_LOWEST_TEXT",
      ),
      value: "ASC",
    },
  ];

  const [selectedSortingMethod, setselectedSortingMethod] = useState(
    sortingMethods[0],
  );
  const [selectedSource, setselectedSource] = useState(sourceList[0]);

  useEffect(() => {
    setselectedSortingMethod({
      ...sortingMethods.filter(
        (s) => s.value === selectedSortingMethod.value,
      )[0],
    });
    setselectedSource({
      ...sourceList.filter((s) => s.value === selectedSource.value)[0],
    });
  }, [postAuthUser]);

  const navigate = useNavigate();

  return (
    <Box mr={2} ml={2} position="relative" zIndex="10">
      <Box mt="3.5em" borderRadius="8px" bgcolor="info.light" minHeight="100px">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={8}
          mb={3}
        >
          <Typography
            variant="body3"
            fontWeight="500"
            fontSize="1.563rem"
            lineHeight="1.12"
            color="info.darker"
          >
            {translate("DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.TITLE")}
          </Typography>
          <Typography
            mt={1.5}
            textAlign="center"
            variant="body1"
            fontWeight="400"
            color="info.darker"
          >
            {translate(
              "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SAVINGS_IDENTIFIED_TEXT_1",
            )}
            <b>
              {" "}
              {translate(
                "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SAVINGS_IDENTIFIED_TEXT_2",
              )}
            </b>
            {translate(
              "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SAVINGS_IDENTIFIED_TEXT_3",
            )}
          </Typography>

          {/* <PharmacyMedicine /> */}

          <Box width="90%">
            {error ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <SadSmileyIcon
                  fill={theme.palette.info.darker!}
                  className="exclamation-mark"
                />
              </Box>
            ) : isLoading || isFetching ? (
              <Spinner text="" />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  mt={1.5}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Filter
                      title={translate(
                        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SOURCE_SHOW_TEXT",
                      )}
                      setSelectedOption={setselectedSource}
                      optionsList={sourceList}
                      selectedOption={selectedSource}
                      onSelect={(event: any) => {
                        const source = event.target.value;

                        if (source == "ALL") {
                          setDrugList(originalList.current);
                        } else {
                          setDrugList(
                            originalList.current
                              ? // @ts-ignore
                                originalList.current.filter(
                                  (item: any) => item.source == source,
                                )
                              : [],
                          );
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Filter
                      title={translate(
                        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.SORTING_METHOD_TEXT",
                      )}
                      setSelectedOption={setselectedSortingMethod}
                      optionsList={sortingMethods}
                      selectedOption={selectedSortingMethod}
                      onSelect={(event: any) => {
                        setDrugList(
                          drugList.sort((a, b) => {
                            if (event.target.value == "ASC") {
                              return a.estimatedSavings - b.estimatedSavings;
                            } else {
                              return b.estimatedSavings - a.estimatedSavings;
                            }
                          }),
                        );
                      }}
                    />
                  </Box>
                </Box>
                {drugList && drugList.length > 0 ? (
                  <ScrollableList
                    maxHeight="40vh"
                    height="initial
                  "
                  >
                    <List className="drug-list list-style">
                      {drugList.map((drug: any, index: number) => (
                        <ListItem className="list-item" key={drug.name}>
                          <DrugItem
                            drug={drug}
                            index={index}
                            onSelect={onSelect}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </ScrollableList>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                    alignItems="center"
                    mt={2}
                  >
                    <Typography
                      variant="body3"
                      color="info.darker"
                      fontSize="1rem"
                      textTransform="capitalize"
                    >
                      {translate(
                        "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.NO_SAVINGS_FOUND_TEXT",
                      )}
                      !
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
          {!(isLoading || isFetching) && (
            <>
              <Box display="flex" alignItems="center" mt={2.5} mb={1}>
                <Typography
                  variant="figure"
                  className="monthlySavingsh1"
                  fontSize="2.125rem"
                  mr={1}
                  aria-labelledby="monthly-saving"
                >
                  {formatCurrency(
                    drugList
                      .filter((drug) => drug.isSelected)
                      .reduce((a, b) => a + b.estimatedSavings, 0.0),
                  )}
                </Typography>
                <Typography
                  variant="body3"
                  className="monthlySavingsh5"
                  fontSize="0.9rem"
                  fontWeight="500"
                  id="monthly-saving"
                >
                  {translate(
                    "DASHBOARD_PAGE.WIDGET.SAVINGS_ASSISTANT.MONTHLY_SAVINGS_TEXT",
                  )}
                </Typography>
              </Box>
              {drugList.filter((drug) => drug.source == "DISCOUNT").length >
                0 && (
                <Box width="100%" mt={1} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    onClick={() => {
                      mutate();
                      navigate("/discount-opportunities");
                    }}
                  >
                    {translate("GENERAL.BUTTON.START_SAVINGS")}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        width="7em"
        height="7em"
        borderRadius="50%"
        position="absolute"
        top="0"
        left="0"
        right="0"
        margin="auto"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: `${theme.palette.info.light}95` }}
        border={`1px solid ${theme.palette.info.darker}`}
      >
        <SavingsAssistantIcon fill={theme.palette.info.darker!} className="" />
      </Box>
    </Box>
  );
}
export default SavingsAssistant;
