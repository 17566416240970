import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import TermsAndConditionComponent from "../components/termsAndConditionsPage/TermsAndConditonComponent";

function TermsAndConditionPage() {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/dashboard");
  }, []);

  return (
    <Page isSecondary={false} header={<Header />} displayExtMemIdAtBottom>
      <TermsAndConditionComponent handleClick={handleClick} />
    </Page>
  );
}

export default TermsAndConditionPage;
