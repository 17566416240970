import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import React from "react";
/* polyfills.js */
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { QueryClientProvider } from "react-query";
import App from "./App";
import queryClient from "./config/queryClient";
import "./index.css";
import ErrorBoundary from "./pages/ErrorBoundary";
import "./polyfill";
import reportWebVitals from "./reportWebVitals";
import getDeviceName from "./utils/getDeviceName";
import { getPageTitle } from "./utils/routeUtils";

ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
  gaOptions: {
    device_name: getDeviceName(),
    page_title: getPageTitle(),
    page_location: window.location.href,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
