// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { getCookie } from "./authUtils";

export interface DecodeToken {
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  exp: number;
}
// checks if member id currenlty received and member id in token is same,
// if its is user is lauthenticated
// else user has to login again
export const decodeToken = () => {
  const token = getCookie("accessToken");
  const decoded: DecodeToken = jwt_decode(token);
  return decoded;
};
