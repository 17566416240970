import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import axiosConfig from "../config/axiosConfig";
import { DiscountCardListDto } from "../services/api";

const getDiscountCards = async () => {
  const res = await axiosConfig.get(`/discount`);
  return res;
};

const useGetUserAllDiscountCards = () => {
  const [gotSwitches, setGotSwitches] = useState(true);
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getUserAllDiscountCards"],
    () => getDiscountCards(),
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<DiscountCardListDto>) => {
        setGotSwitches(true);
        return response;
      },
      onError: (err: AxiosError) => {
        setGotSwitches(true);
        return err;
      },
    },
  );
  return {
    isLoading,
    refetch,
    data,
    error,
    isFetching,
    isSuccess,
    gotSwitches,
  };
};

export default useGetUserAllDiscountCards;
