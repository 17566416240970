import axios, { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";
import getMessageFromCode from "../utils/errorLookup";
import useTranslationByClient from "./useTranslationByClient";

// get error message
export default function useGetErrorMessage() {
  const translate = useTranslationByClient();
  return (error: FirebaseError | Error | AxiosError | string | unknown) => {
    if (typeof error === "string") {
      return error;
    }
    if (error instanceof TypeError) {
      return translate("GENERAL.ERROR.SOMETHING_WENT_WRONG");
    }
    if (error instanceof FirebaseError) {
      return getMessageFromCode(error.code, translate);
    }
    if (axios.isAxiosError(error)) {
      return error.response?.status
        ? getMessageFromCode(
            error.response.data.errorCode ?? error.response.status,
            translate,
          )
        : translate("GENERAL.ERROR.SOMETHING_WENT_WRONG");
    }
    if (error instanceof Error && error.message) {
      return error.message;
    }
    return translate("GENERAL.ERROR.SOMETHING_WENT_WRONG");
  };
}
