import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { SwitchListItemDto } from "../../services/api/models/SwitchListItemDto";

interface SwitchItemContainerProps {
  handleChange: (id: string) => void;
  provider: SwitchListItemDto;
}
export default function SwitchItemContainer({
  handleChange,
  provider,
}: SwitchItemContainerProps) {
  const theme = useTheme();
  return (
    <Box
      className="switch-pill"
      width="100%"
      display="flex"
      justifyContent="space-between"
      gap={1}
      alignItems="center"
      sx={{ backgroundColor: `${theme.palette.info.main}` }}
      onClick={() => handleChange(provider.switchInfoUuid)}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        textAlign="left"
        textTransform="capitalize"
      >
        <Typography
          textAlign="justify"
          fontFamily="Roboto"
          fontWeight="700"
          fontSize="1.1rem"
          color="info.darker"
          aria-label="source-drug-name"
          overflow="visible"
          sx={{
            wordWrap: "break-word",
            width: "fit-content",
            marginBlock: 0,
            maxWidth: "42%",
          }}
          className="switch-drug"
        >
          {provider.sourceDrugName}
        </Typography>

        <ArrowRightAltIcon
          className="switch-arrow-icon"
          sx={{ color: "info.darker", maxWidth: "8%" }}
        />
        <Typography
          textAlign="justify"
          fontFamily="Roboto"
          fontWeight="700"
          fontSize="1.1rem"
          color="info.darker"
          aria-label="target-drug-name"
          overflow="visible"
          sx={{
            wordWrap: "break-word",
            width: "fit-content",
            marginBlock: 0,
            maxWidth: "42%",
          }}
          className="switch-drug"
        >
          {provider.targetDrugName}
        </Typography>
      </Box>
      <Box
        bgcolor="info.light"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ChevronRightIcon sx={{ color: "info.darker" }} />
      </Box>
    </Box>
  );
}
