import { Grid } from "@mui/material";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { RouteProps } from "../../config/routes";
import { AppContext } from "../../config/store";
import { getThemeColors } from "../../config/theme";
import useSetPageTitle from "../../hooks/custom/useSetPageTitle";
import useGetUser from "../../hooks/useGetUser";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import ErrorPage from "../../pages/ErrorPage";
import { hasAuthority, isLoggedIn } from "../../utils/authUtils";
import getMessageFromCode from "../../utils/errorLookup";
import createToast from "../../utils/globalUtils";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";

export interface PrivateRouteProps {
  route: RouteProps;
  path: string;
}

function PrivateRoute({
  children,
  route,
  path,
}: React.PropsWithChildren<PrivateRouteProps>) {
  const { refetch, error, data, isSuccess, isFetching } = useGetUser();
  const [isAuthorized, setIsAuthorized] = useState<string>("");
  const { setPostAuthUser, setThemeColors, postAuthUser } =
    useContext(AppContext);
  const translate = useTranslationByClient();

  useLayoutEffect(() => {
    setIsAuthorized("");
    if (!isLoggedIn()) {
      createToast(getMessageFromCode("LOGGED_OUT", translate), "error");
      localStorage.setItem("original-url", path);
      setIsAuthorized("no-login");
      return;
    }
    refetch();
  }, [path]);

  useEffect(() => {
    if (isSuccess && data && !isFetching) {
      setPostAuthUser(data.data);
    }
  }, [isSuccess, isFetching, data]);

  useEffect(() => {
    if (postAuthUser?.uuid && postAuthUser.uuid !== "") {
      const auth = postAuthUser.authorities ?? [];
      if (hasAuthority(auth, route.authority!)) {
        const clientColors = getThemeColors(postAuthUser);
        setThemeColors({ ...clientColors });
        setIsAuthorized("yes");
      } else {
        setIsAuthorized("no");
      }
    }
  }, [postAuthUser, isFetching]);

  useSetPageTitle();

  return (
    <>
      {error && !isFetching ? (
        <ErrorPage error={error} />
      ) : isAuthorized === "no" ? (
        <ErrorPage error={translate("GENERAL.ERROR.USER_NOT_AUTHORIZED")} />
      ) : isAuthorized === "yes" && isLoggedIn() && isSuccess && !isFetching ? (
        children
      ) : isAuthorized === "no-login" ? (
        <Navigate to="/" />
      ) : (
        <Grid container className="container">
          <SpinnerOverlay
            text={translate("GENERAL.SPINNER.FETCHING_MEMBER_DATA_TEXT")}
            isSecondary
          />
        </Grid>
      )}
    </>
  );
}

export default PrivateRoute;
