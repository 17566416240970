import { RouteProps, routesList } from "../config/routes";

export default function setTabTitle(pageTitle: string) {
  document.title =
    pageTitle === "" ? "RazorMetrics™" : `${pageTitle} - RazorMetrics™`;
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export const getPageTitle = () => {
  let pathName = window.location.pathname;

  if (window.location.pathname.endsWith("/")) {
    pathName = pathName.slice(0, pathName.length - 1);
  }
  const routeTitle = routesList.find(
    (route: RouteProps) => route.path === pathName,
  )?.title;
  return routeTitle ?? "Welcome";
};
