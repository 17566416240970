import React from "react";

export interface SadSmileyIconProps {
  fill: string;
  className: string;
}

export default function HappySmileyIcon({
  fill,
  className,
}: SadSmileyIconProps) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 64 64"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 0C14.3269 0 0 14.3269 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0ZM18.4004 35.2002C18.4004 32.9911 20.1913 31.2002 22.4004 31.2002C23.4613 31.2002 24.4787 31.6216 25.2288 32.3718C25.979 33.1219 26.4004 34.1393 26.4004 35.2002C26.4004 36.2611 25.979 37.2785 25.2288 38.0286C24.4787 38.7788 23.4613 39.2002 22.4004 39.2002C20.1913 39.2002 18.4004 37.4093 18.4004 35.2002ZM37.5996 35.2002C37.5996 32.9911 39.3905 31.2002 41.5996 31.2002C43.8088 31.2002 45.5996 32.9911 45.5996 35.2002C45.5996 37.4093 43.8088 39.2002 41.5996 39.2002C39.3905 39.2002 37.5996 37.4093 37.5996 35.2002ZM6.40041 32.0005C6.40041 46.1125 17.8884 57.6005 32.0004 57.6005C46.1124 57.6005 57.6004 46.1125 57.6004 32.0005C57.6004 29.4725 57.2164 27.0405 56.5444 24.7685C54.2404 25.3125 51.8404 25.6005 49.3444 25.6005C38.5604 25.6005 29.0244 20.2565 23.2324 12.0645C20.0964 19.7125 14.1124 25.8885 6.56041 29.2485C6.40041 30.1445 6.40041 31.0725 6.40041 32.0005Z"
        fill={fill}
      />
    </svg>
  );
}
