import React from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import ProfileCompomnent from "../components/profilePage/ProfileComponent";
import useGetContactInfo from "../hooks/useGetContactInfo";
import { getMemberId } from "../utils/authUtils";

export default function ProfilePage() {
  const encryptedMemberUuid = getMemberId()!;

  const {
    isLoading: isLoadingDefaultContactInfo,
    error: errorDefaultContactInfo,
    data: defaultContactInfo,
  } = useGetContactInfo(encryptedMemberUuid, true);
  const {
    isLoading: isLoadingPreferredContactInfo,
    error: errorPreferredContactInfo,

    data: preferredContactInfo,
  } = useGetContactInfo(encryptedMemberUuid, false);

  return (
    <Page
      isSecondary
      header={<Header />}
      isLoading={isLoadingPreferredContactInfo || isLoadingDefaultContactInfo}
      error={errorDefaultContactInfo ?? errorPreferredContactInfo}
    >
      <ProfileCompomnent
        preferredContactInfo={preferredContactInfo?.data}
        defaultContactInfo={defaultContactInfo?.data}
      />
    </Page>
  );
}
