import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import CreditCardIcon from "../../../assets/CreditCardIcon";
import { AppContext } from "../../../config/store";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import "../../../styles/components/widgets.scss";
import formatCurrency from "../../../utils/currencyFormatter";
import WidgetBanner from "../../common/WidgetBanner";

function GiftCardWidget() {
  const { setOverlayName } = useContext(AppContext);

  const handleClick = () => {
    setOverlayName("GIFT_CARD");
  };
  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <WidgetBanner
      img={
        <CreditCardIcon
          fill={theme.palette.info.darker!}
          className="exclamation-mark"
        />
      }
      text={translate("DASHBOARD_PAGE.WIDGET.GIFT_CARD.BANNER_MAIN_TEXT", {
        amount: formatCurrency(350, 2),
      })}
      subText={translate("DASHBOARD_PAGE.WIDGET.GIFT_CARD.BANNER_SUB_TEXT")}
      handleClick={handleClick}
    />
  );
}

export default GiftCardWidget;
