import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import PrescriberComponent from "../components/prescriberPage/PrescriberComponent";
import useGetPrescriber from "../hooks/module/useGetPrescriber";

function PrescriberPage() {
  const { data, refetch, isLoading, isFetching, error } = useGetPrescriber();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Page
      isLoading={isLoading || isFetching}
      error={error}
      isSecondary={false}
      header={<Header />}
      displayExtMemIdAtBottom
    >
      <PrescriberComponent pharmacyList={data?.data ?? []} />
    </Page>
  );
}

export default PrescriberPage;
