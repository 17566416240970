import { useMutation, useQuery, useQueryClient } from "react-query";

const asyncLocalStorage = {
  setItem(key: string, value: string) {
    return Promise.resolve().then(() => {
      localStorage.setItem(key, value);
    });
  },
};

export default function usePersistentContext(key: string) {
  const queryClient = useQueryClient();

  const { data: value } = useQuery(key, () => localStorage.getItem(key), {
    initialData: localStorage.getItem(key),
  });

  const { mutateAsync: setValue } = useMutation(
    (inputValue: string) => {
      return asyncLocalStorage.setItem(key, inputValue);
    },
    {
      onMutate: (mutatedData) => {
        const current = value;
        queryClient.setQueryData(key, mutatedData);
        return current;
      },
      onError: (_, __, rollback) => {
        queryClient.setQueryData(key, rollback);
      },
    },
  );

  return { value, setValue };
}
