import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../../config/store";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import formatCurrency from "../../../utils/currencyFormatter";
import DashedContainer from "../../common/DashedContainer";

export default function SavingStartWidget() {
  const { setOverlayName } = useContext(AppContext);
  const translate = useTranslationByClient();

  const handleClick = () => {
    setOverlayName("DRUG_SAVING");
  };
  return (
    <DashedContainer>
      {/* {false ? (
        <Spinner text="fetching modules" theme="dark" />
      ) : ( */}
      <>
        <Typography
          variant="figure"
          color="info.darker"
          fontFamily="Roboto"
          fontSize="4rem"
          aria-label="currently available savings"
        >
          {formatCurrency(820.67)}
        </Typography>
        <Typography
          variant="body1"
          fontSize="1.5rem"
          fontWeight="400"
          lineHeight="2.5rem"
          textTransform="uppercase"
          color="info.darker"
          className="savingsText"
        >
          {translate("DASHBOARD_PAGE.WIDGET.SAVINGS_START.AVAILABLE_TEXT")}
        </Typography>
        <Typography
          variant="figure"
          color="info.darker"
          fontFamily="Roboto"
          fontSize="4rem"
          aria-label="savings to date"
        >
          {formatCurrency(1972)}
        </Typography>
        <Typography
          variant="body1"
          fontSize="1.5rem"
          fontWeight="400"
          lineHeight="2.5rem"
          className="savingsText"
          textTransform="uppercase"
          color="info.darker"
        >
          {translate("DASHBOARD_PAGE.WIDGET.SAVINGS_START.SAVED_TO_DATE_TEXT")}
        </Typography>
        <Box width="60%" mt={1}>
          <Button fullWidth variant="contained" onClick={handleClick}>
            {translate("GENERAL.BUTTON.START_SAVINGS")}
          </Button>
        </Box>
      </>
      {/* )} */}
    </DashedContainer>
  );
}
