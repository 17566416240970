import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/rm-rxadvocate-outlined-large.png";
import "../../styles/components/header.scss";
import LanguageDropdown from "./LanguageDropdown";

interface NavbarProps {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  drawer: boolean;
}
function NavBar({ toggleDrawer, drawer }: NavbarProps) {
  const navigate = useNavigate();
  return (
    <Grid container className="header" aria-label="navbar" role="navigation">
      <Grid
        xs={6}
        md={4}
        onClick={() => {
          navigate("/");
        }}
      >
        <picture>
          <source
            media="(min-width:600px)"
            width="200"
            height="44"
            srcSet={logo}
          />
          <source
            media="(max-width:300px)"
            width="120"
            height="27"
            srcSet={logo}
          />
          <img
            src={logo}
            alt="RazorMetrics logo"
            aria-label="RazorMetrics logo"
            width="157"
            height="35"
          />
        </picture>
      </Grid>
      <div>
        <LanguageDropdown />
        <IconButton
          onClick={() => {
            toggleDrawer(!drawer);
          }}
        >
          <MenuIcon fontSize="medium" sx={{ color: "info.darker" }} />
        </IconButton>
      </div>
    </Grid>
  );
}

export default NavBar;
