import { Box, Input, MenuItem, Typography, useTheme } from "@mui/material";
import Select from "@mui/material/Select";
import React from "react";

type optionType = { name: string; value: string };
interface FilterProps {
  title: string;
  optionsList: optionType[];
  selectedOption: optionType;
  setSelectedOption: any;
  onSelect: any;
}

function Filter({
  title,
  optionsList,
  selectedOption,
  setSelectedOption,
  onSelect,
}: FilterProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign="center"
        variant="body1"
        fontWeight="400"
        color="info.darker"
        fontSize="0.8rem"
      >
        {title}:
      </Typography>
      <Select
        className="saving-select"
        displayEmpty
        value={selectedOption.name}
        onChange={(event) => {
          setSelectedOption(
            optionsList.filter((opt) => opt.value == event.target.value)[0],
          );
          onSelect(event);
        }}
        input={
          <Input
            sx={{
              fontSize: "0.8rem",
              background: `${theme.palette.info.light}`,
              border: "none",
              textDecoration: "underline",
              fontWeight: 800,
              color: `${theme.palette.info.darker}`,
            }}
          />
        }
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => selected}
      >
        {optionsList.map((source: { name: string; value: string }) => (
          <MenuItem
            key={source.name}
            value={source.value}
            color="info.dark"
            style={{
              backgroundColor: `${theme.palette.info.light}`,
              color: `${theme.palette.info.darker}`,
            }}
          >
            {source.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
export default Filter;
