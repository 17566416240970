import { Box, useTheme } from "@mui/material";
import React from "react";
import useScrollableList from "../../hooks/custom/useScrollableList";
import "../../styles/blurry-scroll.scss";

export interface ScrollableListProps {
  height?: string;
  maxHeight?: string;
}
export default function ScrollableList({
  children,
  height,
  maxHeight,
}: React.PropsWithChildren<ScrollableListProps>) {
  const { bottomScrollOverlay, topScrollOverlay, handleScroll } =
    useScrollableList("list-parent");

  const theme = useTheme();

  return (
    <Box className="scroll-container">
      <Box
        aria-hidden="true"
        sx={{
          position: "relative",
          zIndex: 3000,
          boxShadow: topScrollOverlay
            ? `0px 0px 5em 5em ${theme.palette.primary.main}90`
            : "none",
        }}
        className={topScrollOverlay ? "shadow-bottom" : ""}
      />
      <div
        id="list-parent"
        className="list-parent"
        style={{ height: height ?? "70vh", maxHeight: maxHeight ?? "70vh" }}
        onScroll={handleScroll}
      >
        {children}
      </div>
      <Box
        aria-hidden="true"
        sx={{
          height: "20px",
          position: "relative",
          boxShadow: bottomScrollOverlay
            ? `0px -30px 50px 50px ${theme.palette.primary.main}90`
            : "none",
        }}
        className={bottomScrollOverlay ? "shadow-bottom" : ""}
      />
    </Box>
  );
}
