import { createUseStyles } from "react-jss";

export default function useStyles(
  primaryColor: string,
  secondaryColor: string,
) {
  return createUseStyles({
    toDetailPage: {
      outline: "none",
      border: `2px solid ${secondaryColor}`,
      backgroundColor: `${primaryColor}`,
      maxWidth: "1em",
      marginLeft: "5px",
      marginRight: "5px",
      padding: "10% 0% 5% 0%",
      borderRadius: "0.5rem",
      color: `${secondaryColor}`,
      fontSize: "5rem",
      textAlign: "center",
      "@media screen and (max-width: 600px)": {
        border: "2px solid",
        borderRadius: "0.5rem",
        fontSize: "3rem",
      },
      "@media screen and (max-width: 450px)": {
        paddingTop: "15%",
        border: "1px solid",
        borderRadius: "0.25rem",
        fontSize: "2.0rem",
      },
      "@media screen and (max-width: 350px)": {
        border: "1px solid",
        borderRadius: "0.25rem",
        fontSize: "1.25rem",
      },
      "@media screen and (min-width: 900px)": {
        border: "2px solid !important",
        borderRadius: "0.5rem !important",
        fontSize: "5rem !important",
      },
      "@media screen and (min-width: 1200px)": {
        border: `2px solid  ${secondaryColor}`,
        padding: "10% 0% 5% 0% !important",
        borderRadius: "0.5rem !important",
        fontSize: "5rem !important",
      },
      "&::selection": {
        color: `${primaryColor}`,
        background: `${secondaryColor}`,
      },
    },
  });
}
