import { FirebaseError } from "firebase/app";
import { UserCredential } from "firebase/auth";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../../config/store";
import { TokenData } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import { removeCaptcha, sendOtpCode } from "../../utils/otpUtils";
import useLoginUser from "../auth/useLoginUser";
import useRegisterMember from "../auth/useRegisterMember";
import useGetErrorMessage from "../useGetErrorMessage";
import useTranslationByClient from "../useTranslationByClient";
import useRecaptcha from "./useRenderRecaptcha";

interface useOTPReturnType {
  otp: string;
  setOtp: Dispatch<SetStateAction<string>>;
  onSubmitOtp: () => Promise<void>;
  sendOtp: () => void;
  spinner: boolean;
  spinnerText: string;
}

export enum UserAction {
  AUTHENTICATE,
  REAUTHENTICATE,
}

export enum ActionToTake {
  REAUTHENTICATE = "REAUTHENTICATE",
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
}

export interface AuthData {
  action: ActionToTake | null;
  tokens: TokenData | null;
  firebaseId: string;
}

export default function useOTP(
  preferredPhone: string,
  userAction: UserAction | null = null,
  setShowOverlay?: any,
  setPhoneAuthData?: any,
  setCanSubmit?: any,
): useOTPReturnType {
  const [spinnerText, setSpinnerText] = useState("");
  const [spinner, setSpinner] = useState(false);
  const { renderCaptcha } = useRecaptcha();
  const { preAuthUser, postAuthUser } = useContext(AppContext);
  const [otp, setOtp] = useState("");
  const getErrorMessage = useGetErrorMessage();
  const otpRef = useRef<string>("");
  const [authData, setAuthData] = useState<AuthData>({
    action: null,
    firebaseId: "",
    tokens: null,
  });
  const { refetch: login } = useLoginUser(setSpinner, authData.tokens);
  const translate = useTranslationByClient();
  const { refetch: register } = useRegisterMember(
    setSpinner,
    authData.firebaseId,
    authData.tokens,
  );
  useEffect(() => {
    if (authData.action && authData.tokens) {
      if (authData.firebaseId && authData.firebaseId !== "") {
        if (authData.action === ActionToTake.REGISTER) {
          localStorage.setItem("isNewUser", "true");
          register();
        } else if (authData.action === ActionToTake.REAUTHENTICATE) {
          setPhoneAuthData(authData);
          setCanSubmit(true);
        }
      } else if (authData.action === ActionToTake.LOGIN) {
        localStorage.setItem("isNewUser", "false");
        login();
      }
    }
  }, [authData]);

  const sendOtp = async () => {
    if (window.recaptchaVerifier) {
      setSpinner(false);
      try {
        if (!preferredPhone) {
          createToast(
            `${translate("GENERAL.ERROR.PHONE_NUMBER_NOT_FOUND")} ${translate(
              "GENERAL.PHONE_NUMBER_PROMPT_TEXT",
            )}`,
            "error",
            3000,
          );
          return;
        }
        window.confirmationResult = await sendOtpCode(preferredPhone);
        createToast(
          translate("GENERAL.SUCCESS.LOGIN_CODE_SENT"),
          "success",
          3000,
        );
        setSpinner(false);
      } catch (error) {
        console.log("yooooo", error);
        setSpinner(false);
        createToast(getErrorMessage(error as FirebaseError), "error", 5000);
      }
    }
  };

  const onSubmitOtp = async () => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSpinnerText(translate("GENERAL.SPINNER.VERIFYING_LOGIN_CODE"));
      setSpinner(true);
      const optConfirm = window.confirmationResult;
      const res: UserCredential = await optConfirm.confirm(otpRef.current);
      const user = JSON.parse(JSON.stringify(res.user.toJSON()));
      const { isNewUser } = JSON.parse(JSON.stringify(res))._tokenResponse;
      setOtp("");
      otpRef.current = "";
      let uid = "";
      let actionToTake: ActionToTake | null = null;
      if (userAction === UserAction.REAUTHENTICATE) {
        uid = user.uid;
        actionToTake = ActionToTake.REAUTHENTICATE;
      } else if (preAuthUser!.firebaseUserId && isNewUser === false) {
        actionToTake = ActionToTake.LOGIN;
      } else {
        uid = user.uid;
        actionToTake = ActionToTake.REGISTER;
      }

      setAuthData({
        action: actionToTake,
        firebaseId: uid,
        tokens: {
          accessToken: user.stsTokenManager.accessToken,
          refreshToken: user.stsTokenManager.refreshToken,
          expirationTime: user.stsTokenManager.expirationTime,
        },
      });
      if (setShowOverlay) {
        setShowOverlay(false);
      }
    } catch (error) {
      setSpinner(false);
      console.log("yoooo", error);
      createToast(getErrorMessage(error as FirebaseError), "error", 3000);
    }
  };
  // submit otp on enter key press
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter" ||
        event.keyCode === 13
      ) {
        event.preventDefault();
        if (otpRef.current.length === 6) {
          onSubmitOtp();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    const renderCaptchaElement = async () => {
      if (
        !window.recaptchaVerifier ||
        !document.getElementById("recaptcha-container")
      ) {
        await renderCaptcha();
      }
    };
    renderCaptchaElement();
  }, []);

  useEffect(() => {
    const renderCaptchaElement = async () => {
      if (
        window.recaptchaVerifier &&
        document.getElementById("recaptcha-container") &&
        postAuthUser &&
        userAction === UserAction.REAUTHENTICATE
      ) {
        await sendOtp();
      } else if (
        window.recaptchaVerifier &&
        document.getElementById("recaptcha-container") &&
        preAuthUser &&
        userAction === UserAction.AUTHENTICATE
      ) {
        await sendOtp();
      }
    };
    renderCaptchaElement();
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      otpRef.current = otp;
    }
  }, [otp]);

  useEffect(() => {
    return () => {
      removeCaptcha();
    };
  }, []);

  return { otp, setOtp, onSubmitOtp, sendOtp, spinnerText, spinner };
}
