import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../config/store";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import formatCurrency from "../../utils/currencyFormatter";
import { DiscountCardDto } from "./../../services/api/models/DiscountCardDto";

export interface DiscountCardProps {
  discountCard: DiscountCardDto;
}
function DiscountCard({ discountCard }: DiscountCardProps) {
  const { postAuthUser } = useContext(AppContext);
  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <Box
      sx={{
        marginBlock: 2,
        border: `2px solid ${theme.palette.info.dark}`,
        padding: "0.5rem",
        borderRadius: "8px",
        boxShadow: "0px 2px 6px 0px #00000026",
      }}
    >
      <Grid
        container
        xs={12}
        width="100%"
        height="auto"
        px={2}
        borderRadius="8px"
        justifyContent="center"
        alignContent="space-around"
        bgcolor={`${theme.palette.info.light}`}
      >
        <Typography
          width="100%"
          variant="body3"
          fontSize="1rem"
          color="info.darker"
          my={2}
          overflow="visible"
          textAlign="center"
        >
          {translate("DISCOUNT_PAGE.DISCOUNT_CARD.MAIN_TEXT_1", {
            vendor: `${discountCard?.vendorInfoDto?.vendorName}` ?? "",
          })}
          &nbsp;
          <b>{discountCard.memberPharmacyName}</b>&nbsp;
          {translate("DISCOUNT_PAGE.DISCOUNT_CARD.MAIN_TEXT_2")}&nbsp;
          <b>{discountCard.drugName}</b>
        </Typography>
        <Divider
          style={{
            width: "100%",
            borderWidth: 1,
            marginBlock: 5,
            borderColor: `${theme.palette.info.darker}`,
          }}
          orientation="horizontal"
        />
        <div style={{ textAlign: "center", width: "100%" }}>
          <div className="container">
            <div className="top-container">
              {discountCard.memberPharmacyName}
            </div>
            <div>
              <h2 className="price">
                {formatCurrency(discountCard.newGrossCost, 2)}
              </h2>
            </div>
            <table style={{ margin: "0 auto", border: "none" }}>
              <tbody style={{ textAlign: "left" }}>
                <tr>
                  <td
                    style={{
                      color: `${theme.palette.info.darker}`,
                      paddingRight: "2rem",
                    }}
                  >
                    {translate("DISCOUNT_PAGE.DISCOUNT_CARD.BIN_TEXT")}
                  </td>
                  <td>
                    <b> {discountCard.vendorInfoDto?.vendorBin}</b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: `${theme.palette.info.darker}`,
                      paddingRight: "2rem",
                    }}
                  >
                    {translate("DISCOUNT_PAGE.DISCOUNT_CARD.PCN_TEXT")}
                  </td>
                  <td>
                    <b>{discountCard.vendorInfoDto?.vendorPcn} </b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: `${theme.palette.info.darker}`,
                      paddingRight: "2rem",
                    }}
                  >
                    {translate("DISCOUNT_PAGE.DISCOUNT_CARD.GROUP_TEXT")}
                  </td>
                  <td>
                    <b>{discountCard.vendorInfoDto?.vendorGroupNumber}</b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: `${theme.palette.info.darker}`,
                      paddingRight: "2rem",
                    }}
                  >
                    {translate("GENERAL.MEMBER_ID")}
                  </td>
                  <td>
                    <b>{postAuthUser.externalMemberId}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <Divider
              style={{
                width: "100%",
                borderWidth: 1,
                marginBlock: 5,
                borderColor: `${theme.palette.info.dark}`,
              }}
              orientation="horizontal"
            />
            <div>
              <div className="logo">
                <img
                  src={`${discountCard.vendorInfoDto?.vendorLogo}`}
                  alt="Vendor Logo"
                  width="70"
                  height="14"
                />
                <p>
                  &nbsp;{translate("DISCOUNT_PAGE.DISCOUNT_CARD.COUPON_TEXT")}
                </p>
              </div>
              <div className="contacts">
                <p>
                  {translate("DISCOUNT_PAGE.PHARMACY_SUPPORT_TEXT")}: 1 (855)
                  268-2822
                </p>
                <p>
                  {translate("DISCOUNT_PAGE.CUSTOMER_SUPPORT_TEXT")}: 1 (855)
                  782-3703
                </p>
              </div>
              <Divider
                style={{
                  width: "100%",
                  borderWidth: 1,
                  marginBlock: 1,
                  borderColor: `${theme.palette.info.darker}`,
                  marginBottom: 10,
                }}
                orientation="horizontal"
              />
            </div>
          </div>
        </div>
      </Grid>
    </Box>
  );
}

export default DiscountCard;
