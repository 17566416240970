import { ClickAwayListener, ThemeProvider } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import history from "./config/history";
import i18n, {
  LanguageCode,
  LanguageKey,
  loadClientTranslations,
} from "./config/i18n";
import RoutesList from "./config/routes";
import { AppContext, initialState } from "./config/store";
import createAppTheme, { ThemeColors } from "./config/theme";
import useRenderCount from "./hooks/custom/useRenderCount";
import { LoggedInUserDto } from "./services/api/models/LoggedInUserDto";
import { MemberInfoDto } from "./services/api/models/MemberInfoDto";
import "./styles/index.scss";
import variables from "./styles/variables.module.scss";
import getDeviceName from "./utils/getDeviceName";
import { getPageTitle } from "./utils/routeUtils";

function App() {
  const [postAuthUser, setPostAuthUser] = useState<LoggedInUserDto>(
    initialState.postAuthUser,
  );
  const [preAuthUser, setPreAuthUser] = useState<MemberInfoDto>(
    initialState.preAuthUser,
  );
  const [themeColors, setThemeColors] = useState<ThemeColors>({
    primaryColor: localStorage.getItem("primaryColor") ?? variables.primary,
    secondaryColor:
      localStorage.getItem("secondaryColor") ?? variables.secondary,
    infoMainColor: localStorage.getItem("infoMainColor") ?? variables.infoMain,
    infoLightColor:
      localStorage.getItem("infoLightColor") ?? variables.infoLight,
    infoDarkColor: localStorage.getItem("infoDarkColor") ?? variables.infoDark,
    infoDarkerColor:
      localStorage.getItem("infoDarkerColor") ?? variables.infoDarker,
    infoLighterColor:
      localStorage.getItem("infoLighterColor") ?? variables.infoLighterColor,
  });

  const [overlayName, setOverlayName] = useState<string>("NONE");

  const [preferredPhone, setPreferredPhone] = useState<string | null>(null);

  ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
    gaOptions: {
      device_name: getDeviceName(),
      page_title: getPageTitle(),
      page_location: window.location.href,
    },
  });
  useRenderCount("App");
  console.log("React App Env : ", process.env.REACT_APP_ENV);
  const value = useMemo(
    () => ({
      postAuthUser,
      setPostAuthUser,
      preAuthUser,
      setPreAuthUser,
      overlayName,
      setOverlayName,
      preferredPhone,
      setPreferredPhone,
      themeColors,
      setThemeColors,
    }),
    [postAuthUser, preAuthUser, overlayName, preferredPhone, themeColors],
  );

  const themeObject = useMemo(() => {
    return createAppTheme({ ...themeColors });
  }, [themeColors]);

  const loadAllTranslationsForClient = (clientNameAbbreviation: string) => {
    Object.keys(LanguageCode).forEach((lngKey: string) => {
      const languageCode = LanguageCode[lngKey as LanguageKey];
      if (!i18n.hasResourceBundle(languageCode, clientNameAbbreviation!)) {
        try {
          const clientTranslations =
            // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
            require(
              `./languages/${languageCode}/${clientNameAbbreviation}.ts`,
            ).default;
          loadClientTranslations(
            clientNameAbbreviation!,
            languageCode,
            clientTranslations,
          );
        } catch (err) {
          console.log(
            `could not load ${lngKey} translations for ${clientNameAbbreviation}`,
          );
        }
      }
    });
  };

  const setLanguage = () => {
    if (
      (preAuthUser.memberLanguage &&
        preAuthUser.memberLanguage in LanguageCode) ||
      (postAuthUser.memberLanguage &&
        postAuthUser.memberLanguage in LanguageCode)
    ) {
      const languageCode =
        postAuthUser.memberLanguage &&
        postAuthUser.memberLanguage in LanguageCode
          ? LanguageCode[postAuthUser.memberLanguage as LanguageKey]
          : LanguageCode[preAuthUser.memberLanguage as LanguageKey];
      i18n.changeLanguage(languageCode);
    }
  };

  useEffect(() => {
    let clientNameAbbreviation: string | null = null;
    if (postAuthUser?.clientNameAbbreviation) {
      clientNameAbbreviation = postAuthUser.clientNameAbbreviation;
    } else if (preAuthUser?.clientNameAbbreviation) {
      clientNameAbbreviation = preAuthUser.clientNameAbbreviation;
    }

    if (clientNameAbbreviation !== null) {
      loadAllTranslationsForClient(clientNameAbbreviation.toLowerCase());
      setLanguage();
    }
  }, [preAuthUser, postAuthUser]);

  return (
    <ThemeProvider theme={themeObject}>
      {/* <div style={{ height: "100%", minHeight: "100%" }}> */}
      <>
        <AppContext.Provider value={value}>
          <HistoryRouter history={history}>
            <RoutesList />
          </HistoryRouter>
        </AppContext.Provider>

        <ClickAwayListener
          onClickAway={() => {
            // toast.dismiss();
          }}
        >
          <div>
            <ToastContainer style={{ marginTop: "8vh" }} />
          </div>
        </ClickAwayListener>
      </>
    </ThemeProvider>
  );
}

export default App;
