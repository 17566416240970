import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { ReactElement } from "react";

export interface WidgetBannerProps {
  img: ReactElement;
  text: string;
  subText: string;
  handleClick: () => void;
}
function WidgetBanner({ img, text, subText, handleClick }: WidgetBannerProps) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      bgcolor="info.light"
      display="flex"
      alignItems="center"
      className="saving-status-widget"
      justifyContent="space-between"
      onClick={handleClick}
      sx={{ borderRadius: isMobile ? "none" : "8px" }}
    >
      {img}
      <Typography variant="subtitle3" textAlign="center">
        {text}
      </Typography>
      <Box
        mt={5}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        width="40%"
      >
        <Button onClick={handleClick}>
          <Typography variant="subtitle1">{subText}</Typography>
        </Button>
      </Box>
    </Box>
  );
}
export default WidgetBanner;
