import ReactGA from "react-ga4";
import { TranslateFunction } from "../hooks/useTranslationByClient";
import { getMemberId } from "./authUtils";

const getErrorMapping = (key: string, translate: TranslateFunction) => {
  // map error code to error message
  const errorMapping = new Map([
    ["auth/internal-error", translate("GENERAL.ERROR.AUTH_INTERNAL_ERROR")],
    [
      "auth/invalid-verification-code",
      translate("GENERAL.ERROR.AUTH_INVALID_VERIFICATION_CODE"),
    ],
    [
      "auth/too-many-requests",
      translate("GENERAL.ERROR.AUTH_TOO_MANY_REQUESTS"),
    ],
    ["MEMBER_NOT_FOUND", translate("GENERAL.ERROR.MEMBER_NOT_FOUND")],
    ["USER_ALREADY_EXISTS", translate("GENERAL.ERROR.USER_ALREADY_EXISTS")],
    ["NULL_MEMBER_ID", translate("GENERAL.ERROR.NULL_MEMBER_ID")],
    ["INVALID_UUID", translate("GENERAL.ERROR.INVALID_UUID")],
    ["ACCESS_DENIED", translate("GENERAL.ERROR.ACCESS_DENIED")],
    ["UNABLE_TO_FIND_USER", translate("GENERAL.ERROR.UNABLE_TO_FIND_USER")],
    [
      "INCORRECT_DATE_OF_BIRTH",
      translate("GENERAL.ERROR.INCORRECT_DATE_OF_BIRTH"),
    ],
    [
      "MEMBER_BLOCKED_FROM_RXADVOCATE",
      translate("GENERAL.ERROR.MEMBER_BLOCKED"),
    ],
    [
      "INVALID_USER_HIDDEN_MODULE",
      translate("GENERAL.ERROR.INVALID_USER_HIDDEN_MODULE"),
    ],
    ["INVALID_MODULE", translate("GENERAL.ERROR.INVALID_MODULE")],
    [
      "MODULE_ALREADY_DISABLED",
      translate("GENERAL.ERROR.MODULE_ALREADY_DISABLED"),
    ],
    [
      "INVALID_LOGGED_IN_USER",
      translate("GENERAL.ERROR.INVALID_LOGGED_IN_USER"),
    ],
    ["SWITCH_NOT_FOUND", translate("GENERAL.ERROR.SWITCH_NOT_FOUND")],
    ["DRUGS_NOT_FOUND", translate("GENERAL.ERROR.DRUGS_NOT_FOUND")],
    ["PHARMACIES_NOT_FOUND", translate("GENERAL.ERROR.PHARMACIES_NOT_FOUND")],
    ["PRESCRIBERS_NOT_FOUND", translate("GENERAL.ERROR.PRESCRIBERS_NOT_FOUND")],
    [
      "SWITCH_NOT_BELONG_TO_CURRENT_USER",
      translate("GENERAL.ERROR.SWITCH_NOT_BELONG_TO_CURRENT_USER"),
    ],
    ["INTERNAL SERVER ERROR", translate("GENERAL.ERROR.INTERNAL_SERVER_ERROR")],
    ["BAD REQUEST", translate("GENERAL.ERROR.BAD_REQUEST")],
    ["FORBIDDEN", translate("GENERAL.ERROR.FORBIDDEN")],
    ["NOT FOUND", translate("GENERAL.ERROR.NOT_FOUND")],
    ["PAGE_NOT_FOUND", translate("GENERAL.ERROR.PAGE_NOT_FOUND")],
    ["BAD GATEWAY", translate("GENERAL.ERROR.BAD_GATEWAY")],
    ["TOO MANY REQUESTS", translate("GENERAL.ERROR.TOO_MANY_REQUESTS")],
    ["UNAUTHORIZED", translate("GENERAL.ERROR.UNAUTHORIZED")],
    [
      "PHONE_NUMBER_ALREADY_IN_USE",
      translate("GENERAL.ERROR.PHONE_NUMBER_ALREADY_IN_USE"),
    ],
    ["LOGGED_OUT", translate("GENERAL.ERROR.LOGGED_OUT")],
    [
      "UNABLE_TO_FIND_MEMBER_DETAILS",
      translate("GENERAL.ERROR.UNABLE_TO_FIND_MEMBER_DETAILS"),
    ],
  ]);
  return errorMapping.get(key);
};

const getStatusCodeMapping = (key: number, translate: TranslateFunction) => {
  const statusCodeMapping = new Map([
    [401, translate("GENERAL.ERROR.CODE_401")],
    [429, translate("GENERAL.ERROR.CODE_429")],
    [408, translate("GENERAL.ERROR.CODE_408")],
    [303, "SEE OTHER"],
  ]);
  return statusCodeMapping.get(key);
};

export interface ErrorMessage {
  code: string | number;
}
// lookup error message against code
export default function getMessageFromCode(
  code: string | number,
  translate: TranslateFunction,
): string {
  let val: any =
    typeof code === "string"
      ? getErrorMapping(code, translate)
      : getStatusCodeMapping(code, translate);
  if (val && val === "SEE OTHER") {
    if (!getMemberId()) {
      val = getErrorMapping("LOGGED_OUT", translate);
    } else {
      val = translate("GENERAL.ERROR.SOMETHING_WENT_WRONG");
    }
  }

  if (!val) {
    val = translate("GENERAL.ERROR.SOMETHING_WENT_WRONG");
  }
  ReactGA.event("error_event", {
    error_code: typeof code === "string" ? -1 : code,
    error_text: val,
  });
  return val;
}
