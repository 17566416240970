import { useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExclaimationMarkIcon from "../../../assets/ExclaimationMarkIcon";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import "../../../styles/components/widgets.scss";
import WidgetBanner from "../../common/WidgetBanner";

function PharmacySavingWidget() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/recent-refills");
  };

  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <WidgetBanner
      img={
        <ExclaimationMarkIcon
          fill={theme.palette.info.darker!}
          className="exclamation-mark"
        />
      }
      text={translate(
        "DASHBOARD_PAGE.WIDGET.PHARMACY_SAVINGS.BANNER_MAIN_TEXT",
      )}
      subText={translate(
        "DASHBOARD_PAGE.WIDGET.PHARMACY_SAVINGS.BANNER_SUB_TEXT",
      )}
      handleClick={handleClick}
    />
  );
}
export default PharmacySavingWidget;
