import React, { useState } from "react";
import Drawer from "../components/common/Drawer";
import PageRefactored from "../components/common/PageRefactored";
import DashboardComponent from "../components/dashboardPage/DashboardComponent";

export default function DashboardPage() {
  const [drawer, toggleDrawer] = useState(false);

  return (
    <PageRefactored
      isSecondary
      header={<Drawer drawer={drawer} toggleDrawer={toggleDrawer} />}
    >
      <DashboardComponent />
    </PageRefactored>
  );
}
