import { Box, Typography } from "@mui/material";
import React from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/components/switch.scss";
import formatCurrency from "../../utils/currencyFormatter";
import DashedContainer from "../common/DashedContainer";

export type drug = {
  drugName: string;
  drugStrength: string;
  drugPrice: number;
  drugPharmacyAddress?: string;
  drugPharmacyName?: string;
  drugDaysSupply?: string;
  drugPrescriberName?: string;
  moreInfo?: string;
};

export interface SavingsSwitchingWidgetProps {
  drugInput: drug;
  handleExpansion: (index: number) => void;
  index: number;
  expanded: number | false;
  type: "source" | "target";
  amountPaidByMember?: number;
}
function SavingsSwitchWidget({
  drugInput,
  handleExpansion,
  index,
  expanded,
  type,
  amountPaidByMember,
}: SavingsSwitchingWidgetProps) {
  const translate = useTranslationByClient();
  return (
    <DashedContainer
      isExpanded={expanded === index ?? false}
      handleExpansion={() => handleExpansion(index)}
      moreInfo={drugInput.moreInfo}
    >
      <Box display="flex" width="95%" gap={1} justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              variant="body3"
              fontSize="1.5rem"
              fontWeight="500"
              lineHeight="1.2"
              overflow="visible"
              sx={{
                color: "info.darker",
                wordWrap: "break-word",
                width: "100%",
              }}
              textAlign="left"
              aria-label="drug name"
            >
              {drugInput.drugName}
            </Typography>
            <Typography
              variant="body3"
              fontSize="0.875rem"
              className="drug-saving-drug-strength"
              fontWeight="400"
              lineHeight="1.2"
              sx={{ color: "info.darker", textTransform: "lowercase" }}
              aria-label="drug strength"
            >
              {drugInput.drugStrength}
            </Typography>
            <Typography
              variant="body3"
              fontSize="0.65rem"
              className="drug-saving-drug-pharmacy"
              fontWeight="500"
              sx={{ color: "info.darker" }}
              margin="0"
              textAlign="left"
              aria-label="drug pharmacy name"
            >
              {drugInput.drugPharmacyName}
              <Typography
                variant="body3"
                fontSize="0.65rem"
                className="drug-saving-drug-pharmacy"
                fontWeight="400"
                sx={{ color: "info.darker" }}
                margin="0"
                textAlign="left"
                aria-label="drug pharmacy address"
              >
                &nbsp;
                {drugInput.drugPharmacyAddress &&
                drugInput.drugPharmacyAddress !== ""
                  ? `(${drugInput.drugPharmacyAddress})`
                  : null}
              </Typography>
            </Typography>
            {type === "source" &&
              amountPaidByMember !== undefined &&
              amountPaidByMember > 0 && (
                <Typography
                  margin="0"
                  textAlign="left"
                  variant="body3"
                  fontSize="0.8rem"
                  className="drug-saving-drug-strength"
                  fontWeight="600"
                  lineHeight="1.2"
                  sx={{ color: "info.darker" }}
                  aria-label="amount paid by member"
                >
                  {translate("DRUGS.MEMBER_PAID_OUT_OF_POCKET")}:{" "}
                  {formatCurrency(amountPaidByMember)}
                </Typography>
              )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ overflowX: "visible" }}
        >
          <Box display="flex" flexDirection="column" textAlign="right">
            <Typography
              variant="body1"
              fontSize="1.5rem"
              lineHeight="1.2"
              sx={{ color: "info.darker", fontWeight: "500" }}
              className="drug-saving-drug-price"
              aria-label="drug price"
            >
              {formatCurrency(drugInput.drugPrice)}
            </Typography>
            <Typography
              variant="body1"
              fontSize="0.875rem"
              sx={{ color: "info.darker", fontWeight: "400" }}
              className="drug-saving-drug-dose"
              aria-label="drug daily dosage"
            >
              {drugInput.drugDaysSupply
                ? `/${drugInput.drugDaysSupply} ${translate("GENERAL.DAY")}`
                : translate("DRUGS.INVALID_DAYS_SUPPLY_TEXT")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </DashedContainer>
  );
}
export default SavingsSwitchWidget;
