import { createCaptcha, removeCaptcha } from "../../utils/otpUtils";

export default function useRenderRecaptcha(
  setIsCaptchaSuccessful?: (value: boolean) => void,
) {
  const handleCaptchaSuccess = async () => {
    if (setIsCaptchaSuccessful) setIsCaptchaSuccessful(true);
  };

  const handleCaptchaExpire = () => {
    if (setIsCaptchaSuccessful) setIsCaptchaSuccessful(false);
  };

  const renderCaptcha = async (setIsLoading: any | undefined = undefined) => {
    try {
      console.log("create captcha");
      if (setIsLoading) {
        setIsLoading(true);
      }
      if (window.recaptchaVerifier) {
        removeCaptcha();
      }

      window.recaptchaVerifier = createCaptcha(
        handleCaptchaSuccess,
        handleCaptchaExpire,
      );

      await window.recaptchaVerifier.verify();
    } catch (error) {
      console.log("captcha create error: ", error);
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  return { renderCaptcha };
}
