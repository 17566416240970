import React from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import FAQComponent from "../components/faqPage/FAQComponent";

export default function FAQPage() {
  return (
    <Page isSecondary={false} header={<Header />} displayExtMemIdAtBottom>
      <FAQComponent />
    </Page>
  );
}
