import { Grid } from "@mui/material";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../config/store";
import useSetPageTitle from "../../hooks/custom/useSetPageTitle";
import useGetMember from "../../hooks/useGetMember";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import ErrorPage from "../../pages/ErrorPage";
import LoginPage from "../../pages/LoginPage";
import variables from "../../styles/variables.module.scss";
import { getMemberId, isLoggedIn } from "../../utils/authUtils";
import { removeCaptcha } from "../../utils/otpUtils";
import setBackgroundColor from "../../utils/setBackgroundColor";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";

export interface PublicRouteProps {
  path: string;
}

function PublicRoute({ path, children }: any) {
  const memberIdParam = useParams().memberId;

  const [memberId, setMemberId] = useState(
    memberIdParam ? encodeURIComponent(memberIdParam!) : getMemberId(),
  );

  const { setPreferredPhone, preAuthUser } = useContext(AppContext);
  const { refetch, error, isSuccess } = useGetMember(memberId!);
  const navigate = useNavigate();
  const translate = useTranslationByClient();

  useSetPageTitle();

  useLayoutEffect(() => {
    setMemberId(
      memberIdParam ? encodeURIComponent(memberIdParam!) : getMemberId(),
    );
  }, []);

  useEffect(() => {
    if (
      (!isLoggedIn() && path !== "/") ||
      path === "/member/:memberId" ||
      (memberId && path === "/" && !isLoggedIn())
    ) {
      refetch();
    } else if (isLoggedIn()) {
      removeCaptcha();
      navigate("/dashboard");
    }
  }, [path, memberId]);

  const { setThemeColors } = useContext(AppContext);
  useEffect(() => {
    setThemeColors({
      primaryColor: variables.primary,
      secondaryColor: variables.secondary,
      infoMainColor: variables.infoMain,
      infoLightColor: variables.infoLight,
      infoDarkColor: variables.infoDark,
      infoDarkerColor: variables.infoDarker,
      infoLighterColor: variables.infoLighter,
    });
    setBackgroundColor(false, variables.primary, variables.secondary);
  }, []);

  return (
    <>
      {!isLoggedIn() &&
      isSuccess &&
      path !== "/" &&
      preAuthUser.memberInfoId !== "" ? (
        React.cloneElement(children, { setMemberId })
      ) : error ? (
        <ErrorPage error={error} />
      ) : path === "/" ? (
        <LoginPage
          setMemberId={setMemberId}
          setMemberPhone={setPreferredPhone}
        />
      ) : (
        <Grid container className="container">
          <SpinnerOverlay
            text={translate("GENERAL.SPINNER.FETCHING_MEMBER_DATA_TEXT")}
          />
        </Grid>
      )}
    </>
  );
}

export default PublicRoute;
