import { Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";
import useOTP, { UserAction } from "../../hooks/custom/useOTP";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/components/otp.scss";
import { ScreenOverlay } from "../common/ScreenOverlay";
import useStyles from "../styles";

interface OtpOverlayProps {
  preferredPhone: string;
  setShowOverlay: any;
  setPhoneAuthData: any;
  setCanSubmit: any;
}

export default function OtpOverlay({
  preferredPhone,
  setShowOverlay,
  setPhoneAuthData,
  setCanSubmit,
}: OtpOverlayProps) {
  const { otp, setOtp, onSubmitOtp, sendOtp } = useOTP(
    preferredPhone,
    UserAction.REAUTHENTICATE,
    setShowOverlay,
    setPhoneAuthData,
    setCanSubmit,
  );
  const theme = useTheme();
  const translate = useTranslationByClient();
  const classes = useStyles(
    theme.palette.info.light,
    theme.palette.info.darker!,
  )();
  return (
    <ScreenOverlay
      isSecondary
      topSection={
        <Typography
          className="simple-text"
          variant="body1"
          fontWeight="400"
          mt={5}
          mb={2}
          pr={3}
          pl={3}
          textAlign="center"
        >
          {translate("OTP_PAGE.OTP_PROMPT_TEXT")}
        </Typography>
      }
      mainSection={
        <div>
          <Grid
            xs={10}
            mb={3}
            mt={5}
            direction="row"
            alignItems="center"
            justifyContent="center"
            aria-label="otp code"
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span />}
              containerStyle="otp-input otpContainer"
              inputStyle={classes.toDetailPage}
              renderInput={(props) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input {...props} />{" "}
                </div>
              )}
              inputType="tel"
            />
          </Grid>
          <Grid
            textAlign="center"
            xs={12}
            mb={2.5}
            aria-label="otp resend link"
          >
            <Button onClick={sendOtp}>
              <Typography
                variant="body1"
                lineHeight={1.267}
                style={{
                  textDecoration: "underline",
                }}
              >
                {translate("OTP_PAGE.RESEND_OTP_BUTTON")}
              </Typography>
            </Button>
          </Grid>
        </div>
      }
      bottomSection={
        <Grid container>
          <Grid item xs={6} className="col-center">
            <Button
              variant="secondary"
              onClick={() => {
                setShowOverlay(false);
              }}
              aria-label="cancel"
            >
              {translate("GENERAL.BUTTON.CANCEL")}
            </Button>
          </Grid>
          <Grid item xs={6} className="col-center">
            <Button
              variant="primary"
              onClick={async () => {
                await onSubmitOtp();
              }}
              aria-label="Done"
            >
              {translate("GENERAL.BUTTON.DONE")}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}
