import { createTheme } from "@mui/material";
import { LoggedInUserDto } from "../services/api";
import { isHexColor } from "../utils/globalUtils";

const baseTheme = createTheme();

export type ThemeColors = {
  primaryColor: string;
  secondaryColor: string;
  infoMainColor: string;
  infoLightColor: string;
  infoLighterColor: string;
  infoDarkColor: string;
  infoDarkerColor: string;
};

const createAppTheme = ({
  primaryColor,
  secondaryColor,
  infoMainColor,
  infoLightColor,
  infoLighterColor,
  infoDarkColor,
  infoDarkerColor,
}: ThemeColors) =>
  createTheme(baseTheme, {
    breakpoints: {
      values: {
        // fold: 0,
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1200,
        xl: 1536,
      },
    },
    constants: {
      pageContentMaxWidth: "560px",
      menuSideBarDrawerWidth: "250px",
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      info: {
        main: infoMainColor,
        dark: infoDarkColor,
        darker: infoDarkerColor,
        light: infoLightColor,
        lighter: infoLighterColor,
      },
      grayShades: {
        light: "#FAF6F6",
        main: "#E1E1E1",
      },
    },
    typography: {
      h1: {
        fontSize: "2.938rem",
        overflow: "hidden",
        fontWeight: 700,
        color: infoDarkerColor,
        lineHeight: "fit-content",
        [baseTheme.breakpoints.down(400)]: {
          fontSize: "2.4rem",
          fontWeight: 700,
        },
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "2.1rem",
          fontWeight: 700,
        },
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: "1.75rem",
        fontWeight: 700,
        letterSpacing: -1.4,
        overflow: "hidden",
        lineHeight: 1.321,
        color: infoDarkerColor,
        [baseTheme.breakpoints.down(500)]: {
          fontSize: "1.6rem",
        },
        [baseTheme.breakpoints.down(400)]: {
          fontSize: "1.4rem",
        },
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "1.2rem",
        },
      },
      operator: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: "3.7rem",
        overflow: "hidden",
        fontWeight: 900,
        color: infoDarkerColor,
        lineHeight: "fit-content",
        height: "fit-content",
        [baseTheme.breakpoints.down(400)]: {
          fontSize: "2.4rem",
          fontWeight: 700,
        },
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "2.1rem",
          fontWeight: 700,
        },
      },
      body1: {
        fontFamily: ["Roboto"],
        fontSize: "1rem",
        fontWeight: 300,
        lineHeight: "1.188",
        color: infoDarkerColor,
        overflow: "hidden",

        [baseTheme.breakpoints.down(300)]: {
          fontSize: "0.9rem",
          fontWeight: 300,
        },
      },
      body1_secondary: {
        fontFamily: ["Roboto"],
        fontSize: "1rem",
        fontWeight: 300,
        lineHeight: "1.188",
        color: "black",
        overflow: "hidden",
        marginBottom: "0.4rem",

        [baseTheme.breakpoints.down(300)]: {
          fontSize: "0.9rem",
          fontWeight: 300,
        },
      },
      body2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: "1.563rem",
        fontWeight: 500,
        letterSpacing: -1.3,
        lineHeight: 1.48,
        overflow: "hidden",
        color: infoDarkerColor,
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "1.6rem",
        },
      },
      body3: {
        fontFamily: ["Roboto"],
        fontSize: "1.313rem",
        fontWeight: 400,
        lineHeight: 1.14,
        color: infoDarkerColor,
        overflow: "hidden",
        [baseTheme.breakpoints.down(317)]: {
          fontSize: "1.1rem",
          fontWeight: 400,
        },
      },
      subtitle1: {
        fontFamily: ["Roboto"],
        fontSize: "0.85rem",
        fontWeight: 400,
        lineHeight: 1,
        color: infoDarkerColor,
        overflow: "hidden",

        [baseTheme.breakpoints.down(300)]: {
          // fontSize: "0.9rem",
          fontWeight: 300,
        },
      },
      subtitle2: {
        fontFamily: ["Roboto"],
        fontSize: "0.563rem",
        fontWeight: "600",
        overflow: "hidden",
        lineHeight: "2",
        color: infoDarkerColor,
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "0.65rem",
          fontWeight: 300,
        },
      },
      subtitle3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: "1.75rem",
        fontWeight: 700,
        letterSpacing: -1.4,
        overflow: "hidden",
        lineHeight: 1.321,
        color: infoDarkerColor,
        [baseTheme.breakpoints.down(500)]: {
          fontSize: "1.6rem",
        },
        [baseTheme.breakpoints.down(400)]: {
          fontSize: "1.4rem",
        },
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "1.2rem",
        },
      },
      figure: {
        fontFamily: ["Roboto"],
        fontSize: "4rem",
        overflow: "hidden",
        fontWeight: 900,
        color: infoDarkerColor,
        [baseTheme.breakpoints.down(300)]: {
          fontSize: "2.5rem",
          fontWeight: 800,
        },
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "primary" },
            style: {
              fontSize: "1.18rem",
              fontWeight: 900,
              width: "80%",
              maxWidth: "350px",
              border: `2px solid`,
              borderRadius: "50px",
              textTransform: "none",
              borderColor: infoDarkerColor,
              backgroundColor: infoDarkerColor,
              color: infoLightColor,
              disableRipple: true,
              [baseTheme.breakpoints.down(300)]: {
                fontSize: "0.9rem",
                fontWeight: 900,
                maxHeight: "45px",
              },
              "&:hover": {
                backgroundColor: infoDarkerColor,
                color: `${infoLightColor}`,
              },
              "&:disabled": {
                color: infoLightColor,
                opacity: 0.5,
                cursor: "not-allowed",
                pointerEvents: "all !important",
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              fontSize: "1.18rem",
              fontWeight: 900,
              width: "80%",
              maxWidth: "350px",
              border: `2px solid`,
              borderRadius: "50px",
              textTransform: "none",
              borderColor: infoDarkerColor,
              backgroundColor: infoDarkerColor,
              color: infoLightColor,
              disableRipple: true,
              [baseTheme.breakpoints.down(300)]: {
                fontSize: "0.9rem",
                fontWeight: 900,
                maxHeight: "45px",
              },
              "&:hover": {
                backgroundColor: infoDarkerColor,
                color: `${infoLightColor}`,
              },
              "&:disabled": {
                color: infoLightColor,
                opacity: 0.5,
                cursor: "not-allowed",
                pointerEvents: "all !important",
              },
            },
          },
          {
            props: { variant: "contained" },
            style: {
              fontSize: "1.18rem",
              fontWeight: 900,
              width: "80%",
              maxWidth: "350px",
              border: `2px solid`,
              borderRadius: "50px",
              textTransform: "none",
              borderColor: infoDarkerColor,
              backgroundColor: infoDarkerColor,
              color: infoLightColor,
              disableRipple: true,
              [baseTheme.breakpoints.down(300)]: {
                fontSize: "0.9rem",
                fontWeight: 900,
                maxHeight: "45px",
              },
              "&:hover": {
                borderColor: infoDarkerColor,
                backgroundColor: infoDarkerColor,
                color: infoLightColor,
              },

              "&:disabled": {
                backgroundColor: `${infoDarkerColor}50`,
                color: infoLightColor,
                cursor: "not-allowed",
                pointerEvents: "all !important",
              },
            },
          },

          {
            props: { variant: "outlined" },
            style: {
              fontFamily: ["Roboto"],
              fontWeight: "400",
              fontSize: "1.25rem",
              lineHeight: 1.5,
              textTransform: "none",
              border: "5px dashed",
              borderRadius: "8px",
              borderWidth: "2px",
              borderColor: infoDarkerColor,
              color: infoDarkerColor,
              overflow: "hidden",

              "&:hover": {
                border: "5px dashed",
                borderWidth: "2px",
                borderColor: infoDarkerColor,
              },
            },
          },
          {
            props: { variant: "text" },
            style: {
              textTransform: "none",
            },
          },
        ],
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            // this is styles for the new variants
            "&.secondary": {
              marginBottom: "0.5rem",
              "& fieldset": {
                border: "0.2px solid",
                borderColor: infoLightColor,
              },
              "& input": {
                color: infoDarkerColor,
                caretColor: infoDarkerColor,
                fontSize: "1.2rem",
                borderRadius: "0px 10px 10px 0px",
                paddingBlock: 0,
                height: "clamp(2.5rem, 6vh, 3.5rem)",
                width: "calc(100% - 2.5px)",
                fontWeight: "400",
                backgroundColor: `${infoDarkerColor}10`,
                "&::placeholder": {
                  color: infoDarkerColor,
                  opacity: "1",
                },
              },
              "& input:focus": {
                outline: "none",
              },
              "& .MuiInputBase-input:hover + fieldset": {
                border: "0.2px solid",
                borderColor: infoDarkerColor,
              },
            },
          },
        },
      },
    },
  });

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    figure: true;
    operator: true;
    subtitle3: true;
    body1_secondary: true;
  }
}

declare module "@mui/material/styles" {
  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }
}
declare module "@mui/material/styles" {
  interface Palette {
    grayShades: { main: string; light: string };
  }
  interface PaletteOptions {
    grayShades: { main: string; light: string };
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    constants: {
      pageContentMaxWidth: string;
      menuSideBarDrawerWidth: string;
    };
  }
  interface ThemeOptions {
    constants: {
      pageContentMaxWidth: string;
      menuSideBarDrawerWidth: string;
    };
  }
}

export const getThemeColors = (userData: LoggedInUserDto) => {
  let clientNameAbbreviation = null;
  let clientColors: any = null;
  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const defaultColors: any = require(`../styles/color-theme/default.json`);
  try {
    clientNameAbbreviation = userData.clientNameAbbreviation ?? "default";
    clientNameAbbreviation = clientNameAbbreviation.toLowerCase();
    // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
    clientColors = require(
      `../styles/color-theme/${clientNameAbbreviation}.json`,
    );
  } catch (err) {
    // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
    clientColors = defaultColors;
  }

  const colors = [
    "primaryColor",
    "secondaryColor",
    "infoMainColor",
    "infoLightColor",
    "infoDarkColor",
    "infoLighterColor",
  ];

  const newColors: ThemeColors = {
    primaryColor:
      clientColors.primaryColor && isHexColor(clientColors.primaryColor)
        ? clientColors.primaryColor
        : defaultColors.primaryColor,
    secondaryColor:
      clientColors.secondaryColor && isHexColor(clientColors.secondaryColor)
        ? clientColors.secondaryColor
        : defaultColors.secondaryColor,
    infoMainColor:
      clientColors.infoMainColor && isHexColor(clientColors.infoMainColor)
        ? clientColors.infoMainColor
        : defaultColors.infoMainColor,
    infoLightColor:
      clientColors.infoLightColor && isHexColor(clientColors.infoLightColor)
        ? clientColors.infoLightColor
        : defaultColors.infoLightColor,
    infoDarkColor:
      clientColors.infoDarkColor && isHexColor(clientColors.infoDarkColor)
        ? clientColors.infoDarkColor
        : defaultColors.infoDarkColor,
    infoDarkerColor:
      clientColors.infoDarkerColor && isHexColor(clientColors.infoDarkerColor)
        ? clientColors.infoDarkerColor
        : defaultColors.infoDarkerColor,
    infoLighterColor:
      clientColors.infoDarkerColor && isHexColor(clientColors.infoLighterColor)
        ? clientColors.infoLighterColor
        : defaultColors.infoLighterColor,
  };

  colors.forEach((color: string) => {
    if (
      clientColors[color] !== null &&
      clientColors[color] !== undefined &&
      clientColors[color] !== ""
    ) {
      localStorage.setItem(color, clientColors[color]);
    } else {
      localStorage.setItem(color, defaultColors[color]);
    }
  });

  return newColors;
};

export default createAppTheme;
