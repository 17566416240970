import { AxiosError } from "axios";
import { MutationCache, QueryCache, QueryClient } from "react-query";
import { invalidateSession } from "../utils/authUtils";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
  queryCache: new QueryCache({
    onError: (error) => {
      const err = error as AxiosError;
      if (err?.response?.data.errorCode === "INVALID_TOKEN") {
        invalidateSession();
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      const err = error as AxiosError;
      if (err?.response?.data.errorCode === "INVALID_TOKEN") {
        invalidateSession();
      }
    },
  }),
});

export default queryClient;
