import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import axiosConfig from "../../config/axiosConfig";
import { PrescriberInfoDto } from "../../services/api";

const getPrescriber = async () => {
  const response = await axiosConfig.get("prescribers/get");
  return response;
};

export default function useGetPrescriber() {
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getPrescriber"],
    getPrescriber,
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<PrescriberInfoDto[]>) => {
        return response.data;
      },
      onError: (err: AxiosError) => {
        return err;
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
}
