export default function formatCurrency(
  currency: number,
  minFractionDigits?: number,
  maxFractionDigits?: number,
) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minFractionDigits ?? 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: maxFractionDigits ?? 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(currency);
}
