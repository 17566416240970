import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DownArrowIcon from "../../assets/DownArrowIcon";
import { AppContext } from "../../config/store";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { Saving } from "../../pages/SwitchNotificationPage";
import formatCurrency from "../../utils/currencyFormatter";
import SavingsSwitchWidget, { drug } from "./SavingsSwitchWidget";

export interface SavingNotificationProps {
  targetDrug: drug;
  sourceDrug: drug;
  savings: Saving;
}
function SwitchNotificationComponent({
  targetDrug,
  sourceDrug,
  savings,
}: SavingNotificationProps) {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<number | false>(false);

  const handleExpansion = (panel: number) => {
    setExpanded(expanded === panel ? false : panel);
  };
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const handleClick = () => {
    if (redirect && redirect === "true") {
      navigate("/recent-refills");
    } else {
      navigate("/dashboard");
    }
  };

  const theme = useTheme();
  const { postAuthUser } = useContext(AppContext);
  const translate = useTranslationByClient();

  return (
    <Box
      id="yoo"
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Box sx={{ overflow: "visible" }}>
        <Typography
          variant="h1"
          fontSize="2.75rem"
          textAlign="center"
          textTransform="capitalize"
          lineHeight="1"
          pr={6}
          pl={6}
          pb={4}
        >
          {translate("SWITCH_NOTIFICATION_PAGE.TITLE")}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          px={3}
          mb={3}
        >
          <Typography variant="body1">
            {translate(
              "SWITCH_NOTIFICATION_PAGE.PRESCRIPTION_CHANGE_NOTIFICATION_TEXT",
              {
                drugPrescriberName: sourceDrug.drugPrescriberName || "",
              },
            )}
          </Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            textAlign="center"
            justifyContent="center"
          >
            {sourceDrug && (
              <SavingsSwitchWidget
                drugInput={sourceDrug}
                handleExpansion={handleExpansion}
                expanded={expanded}
                index={0}
                aria-label="source drug details"
                type="source"
                amountPaidByMember={savings.amountPaidByMember}
              />
            )}
            <DownArrowIcon
              fill={theme.palette.info.darker!}
              className="switch-icon"
            />
            {targetDrug && (
              <SavingsSwitchWidget
                drugInput={targetDrug}
                handleExpansion={handleExpansion}
                expanded={expanded}
                index={1}
                aria-label="target drug details"
                type="target"
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            m={2}
            mt={4}
          >
            <Typography variant="body1" fontSize="1rem">
              {savings && (
                <>
                  {savings?.memberSavings && savings?.memberSavings > 10
                    ? translate(
                        "SWITCH_NOTIFICATION_PAGE.ESTIMATED_SAVINGS_TEXT",
                        {
                          savings: formatCurrency(savings?.memberSavings),
                        },
                      )
                    : savings?.planSavings && savings?.planSavings > 0
                    ? translate("SWITCH_NOTIFICATION_PAGE.PLAN_SAVINGS_TEXT", {
                        savings: formatCurrency(savings?.planSavings),
                      })
                    : null}
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="50px" pb={9} mt={2} className="row-center">
        <Button variant="primary" aria-label="submit" onClick={handleClick}>
          {translate("GENERAL.BUTTON.DONE")}
        </Button>
      </Box>
      <Typography mb={1} sx={{ fontSize: "0.75rem !important" }}>
        {translate("GENERAL.MEMBER_ID")}: {postAuthUser.externalMemberId}
      </Typography>
    </Box>
  );
}

export default SwitchNotificationComponent;
