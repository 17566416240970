import React, { useContext } from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import { LandingComponent } from "../components/landingPage/LandingComponent";
import { AppContext } from "../config/store";

function LandingPage({ setMemberId }: any) {
  const { preAuthUser } = useContext(AppContext);

  return (
    <Page isSecondary={false} header={<Header />}>
      <LandingComponent
        setMemberId={setMemberId}
        clientName={preAuthUser.clientName}
      />
    </Page>
  );
}

export default LandingPage;
