import { Box, Button, Grid, Link, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/i18n";
import { AppContext } from "../../config/store";
import useInterval from "../../hooks/custom/useInterval";
import { AuthData } from "../../hooks/custom/useOTP";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import useUpdateContactInfo from "../../hooks/useUpdateContactInfo";
import {
  MemberAddressDto,
  MemberDefaultContactInfoDto,
  MemberPreferredContactInfoDto,
  UpdateMemberPreferredContactInfoDto,
} from "../../services/api";
import "../../styles/components/profile.scss";
import { getCookie } from "../../utils/authUtils";
import { decodeToken } from "../../utils/decodeToken";
import createToast, {
  isValidAddress,
  isValidEmail,
  isValidPhone,
  localEnvs,
} from "../../utils/globalUtils";
import ScrollableList from "../common/ScrollableList";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";
import OtpOverlay from "./OtpOverlay";
import ProfileAddressSection from "./ProfileAddressSection";
import ProfileEmailSection from "./ProfileEmailSection";
import ProfilePhoneSection from "./ProfilePhoneSection";

interface ProfileComponentProps {
  defaultContactInfo: MemberDefaultContactInfoDto;
  preferredContactInfo: MemberPreferredContactInfoDto;
}
export default function ProfileCompomnent({
  defaultContactInfo,
  preferredContactInfo,
}: ProfileComponentProps) {
  const navigate = useNavigate();
  const translate = useTranslationByClient();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [editablePreferredPhone, setEditablePreferredPhone] = useState<string>(
    preferredContactInfo?.phone ?? "",
  );
  const [phoneAuthData, setPhoneAuthData] = useState<AuthData>({
    action: null,
    firebaseId: "",
    tokens: null,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [editablePreferredEmail, setEditablePreferredEmail] = useState<string>(
    preferredContactInfo?.email ?? "",
  );

  const { mutate: updateContactInfo } = useUpdateContactInfo(
    phoneAuthData,
    setIsEditing,
    setDisableSubmit,
    setCanSubmit,
    setShowOverlay,
  );
  const [editablePreferredAddress, setEditablePreferredAddress] =
    useState<MemberAddressDto>(
      preferredContactInfo?.address ?? {
        city: "",
        state: "",
        street: "",
        suite: "",
        zipCode: "",
      },
    );
  const theme = useTheme();
  const { postAuthUser } = useContext(AppContext);

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const defaultCountry = localEnvs.includes(`${process.env.REACT_APP_ENV}`)
    ? "PK"
    : "US";

  const fullPhoneNo = `+${getCountryCallingCode(
    defaultCountry,
  )}${editablePreferredPhone}`;

  const handleSave = async () => {
    const initialData = preferredContactInfo;
    let canSubmitForm = true;
    if (
      editablePreferredPhone !== "" &&
      editablePreferredPhone !== initialData.phone
    ) {
      if (!isValidPhone(fullPhoneNo)) {
        createToast(
          translate("GENERAL.ERROR.INVALID_PHONE_NUMBER"),
          "error",
          3000,
        );
        setDisableSubmit(true);
        return;
      }
      setShowOverlay(true);
      canSubmitForm = false;
    }
    if (
      editablePreferredEmail !== "" &&
      editablePreferredEmail !== initialData.email
    ) {
      if (!isValidEmail(editablePreferredEmail)) {
        createToast(translate("GENERAL.ERROR.INVALID_EMAIL"), "error", 3000);
        setDisableSubmit(true);
        return;
      }
      const maxEmailLength = 255;
      if (editablePreferredEmail.length > maxEmailLength) {
        createToast(
          translate("GENERAL.ERROR.EMAIL_LENGTH_EXCEED", {
            max: maxEmailLength.toString(),
          }),
          "error",
          3000,
        );
        setDisableSubmit(true);
        return;
      }
    }
    if (
      editablePreferredAddress?.city !== initialData?.address?.city ||
      editablePreferredAddress?.state !== initialData?.address?.state ||
      editablePreferredAddress?.street !== initialData?.address?.street ||
      editablePreferredAddress?.suite !== initialData?.address?.suite ||
      editablePreferredAddress?.zipCode !== initialData?.address?.zipCode
    ) {
      if (!isValidAddress(editablePreferredAddress, i18n.language, translate)) {
        setDisableSubmit(true);
        return;
      }
    }

    setCanSubmit(canSubmitForm);
  };

  useEffect(() => {
    if (canSubmit) {
      try {
        const updatedData: UpdateMemberPreferredContactInfoDto = {};
        if (
          editablePreferredPhone !== "" &&
          editablePreferredPhone !== preferredContactInfo.phone
        ) {
          const oldToken = getCookie("accessToken");
          if (!phoneAuthData.tokens) {
            throw Error();
          }

          updatedData.updateMemberPreferredPhoneDto = {
            updateTokenDto: {
              oldToken,
              newToken: phoneAuthData.tokens.accessToken,
              userId: phoneAuthData.firebaseId,
            },
            memberPreferredPhone: fullPhoneNo,
            oldUserId: decodeToken().user_id,
          };
        }

        if (
          editablePreferredEmail !== "" &&
          editablePreferredEmail !== preferredContactInfo.email
        ) {
          updatedData.email = editablePreferredEmail;
        }
        if (
          editablePreferredAddress?.city !==
            preferredContactInfo?.address?.city ||
          editablePreferredAddress?.state !==
            preferredContactInfo?.address?.state ||
          editablePreferredAddress?.street !==
            preferredContactInfo?.address?.street ||
          editablePreferredAddress?.suite !==
            preferredContactInfo?.address?.suite ||
          editablePreferredAddress?.zipCode !==
            preferredContactInfo?.address?.zipCode
        ) {
          updatedData.address = editablePreferredAddress;
        }
        updateContactInfo(updatedData);
      } catch (err) {
        createToast(
          translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"),
          "error",
          3000,
        );
        setIsEditing(false);
        setDisableSubmit(true);
        setCanSubmit(false);
        setShowOverlay(false);
      }
    }
  }, [canSubmit]);

  const handleCancelClick = () => {
    setIsEditing(false);
    setDisableSubmit(true);
    setCanSubmit(false);
    setEditablePreferredAddress(
      preferredContactInfo?.address ?? {
        city: "",
        state: "",
        street: "",
        suite: "",
        zipCode: "",
      },
    );
    setEditablePreferredEmail(preferredContactInfo.email ?? "");
    setEditablePreferredPhone(preferredContactInfo.phone ?? "");
  };

  useEffect(() => {
    const initialData = preferredContactInfo;
    if (
      (editablePreferredEmail === "" ||
        editablePreferredEmail === initialData.email) &&
      (editablePreferredPhone === "" ||
        editablePreferredPhone === initialData.phone) &&
      editablePreferredAddress?.city === initialData?.address?.city &&
      editablePreferredAddress?.state === initialData?.address?.state &&
      editablePreferredAddress?.street === initialData?.address?.street &&
      editablePreferredAddress?.suite === initialData?.address?.suite &&
      editablePreferredAddress?.zipCode === initialData?.address?.zipCode
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [
    editablePreferredAddress,
    editablePreferredEmail,
    editablePreferredPhone,
  ]);

  useInterval(() => {
    const prof = document.getElementsByClassName("list-parent");
    if (prof && prof.length > 0) {
      Array.from(prof as HTMLCollectionOf<HTMLElement>)[0].setAttribute(
        "style",
        "height:60vh;",
      );
    }
  }, 2000);

  if (canSubmit) {
    return (
      <SpinnerOverlay
        text={translate("GENERAL.SPINNER.UPDATING_PROFILE_INFO")}
        isSecondary
      />
    );
  }

  return (
    <>
      <Grid
        container
        width="100%"
        height="auto"
        mx={3.75}
        mt={2}
        justifyContent="center"
        alignContent="space-around"
        flexGrow="1"
      >
        <Grid item xs={12} className="col-center">
          <Typography
            variant="h1"
            fontSize="2rem"
            lineHeight={0.97}
            letterSpacing={0}
            mb={1}
            textAlign="center"
            sx={{ wordSpacing: "3px" }}
          >
            {translate("PROFILE_PAGE.TITLE")}
          </Typography>
          <Typography ml={1} mb={1} sx={{ fontSize: "0.75rem !important" }}>
            {translate("GENERAL.MEMBER_ID")}: {postAuthUser.externalMemberId}
          </Typography>
          <ScrollableList height="60vh">
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              sx={{ width: "100%", height: "100%", marginBottom: 3 }}
            >
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Typography variant="body1" mb={2} textAlign="center">
                  {translate("PROFILE_PAGE.CONFIRM_INFO_TEXT")}.
                </Typography>
                <ProfileAddressSection
                  editablePreferredAddress={editablePreferredAddress}
                  setEditablePreferredAddress={setEditablePreferredAddress}
                  defaultAddress={defaultContactInfo?.address ?? ""}
                  isEditingPreferredAddress={isEditing}
                  currentPreferredAddress={preferredContactInfo?.address}
                />
                <ProfilePhoneSection
                  editablePreferredPhone={editablePreferredPhone}
                  setEditablePreferredPhone={setEditablePreferredPhone}
                  defaultPhone={defaultContactInfo?.phone ?? ""}
                  currentPreferredPhone={preferredContactInfo?.phone}
                  isEditingPreferredPhone={isEditing}
                />
                <ProfileEmailSection
                  editablePreferredEmail={editablePreferredEmail}
                  setEditablePreferredEmail={setEditablePreferredEmail}
                  defaultEmail={defaultContactInfo?.email ?? ""}
                  currentPreferredEmail={preferredContactInfo?.email}
                  isEditingPreferredEmail={isEditing}
                />
              </Grid>

              <Typography
                variant="body1"
                textAlign="center"
                fontSize="0.75rem"
                sx={{
                  hyphens: "auto",
                  wordSpacing: "-0.1em",
                  height: "fit-content",
                  paddingBottom: 3,
                }}
              >
                * {translate("PROFILE_PAGE.PERMANENT_CHANGES_DISCLAIMER_TEXT")}.
                <Link
                  style={{
                    color: `${theme.palette.info.darker}`,
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  target="_blank"
                  href="/privacy-policy/"
                >
                  {translate("PRIVACY_POLICY_PAGE.TITLE")}
                </Link>
              </Typography>
            </Box>
          </ScrollableList>
        </Grid>
      </Grid>
      {isEditing ? (
        <Box className="page-back-button">
          <Button
            variant="secondary"
            className="disabled-hover-effects"
            onClick={handleCancelClick}
            aria-label="cancel"
          >
            {translate("GENERAL.BUTTON.CANCEL")}
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            aria-label="Save"
            className="disabled-hover-effects"
            disabled={disableSubmit}
          >
            {canSubmit
              ? translate("GENERAL.BUTTON.SAVING")
              : translate("GENERAL.BUTTON.SAVE")}
          </Button>
        </Box>
      ) : (
        <Box className="page-back-button">
          <Button
            variant="primary"
            className="disabled-hover-effects"
            onClick={() => {
              setIsEditing(true);
              setEditablePreferredAddress(
                preferredContactInfo?.address ?? {
                  city: "",
                  state: "",
                  street: "",
                  suite: "",
                  zipCode: "",
                },
              );
              setEditablePreferredEmail(preferredContactInfo.email ?? "");
              setEditablePreferredPhone(preferredContactInfo.phone ?? "");
            }}
            aria-label="edit preferred"
          >
            {translate("PROFILE_PAGE.EDIT_PREFERRED_BUTTON")}
          </Button>

          <Button
            className="disabled-hover-effects"
            variant="primary"
            onClick={handleBackClick}
            aria-label="Back"
          >
            {translate("GENERAL.BUTTON.BACK")}
          </Button>
        </Box>
      )}

      {showOverlay && (
        <OtpOverlay
          setPhoneAuthData={setPhoneAuthData}
          preferredPhone={fullPhoneNo}
          setShowOverlay={setShowOverlay}
          setCanSubmit={setCanSubmit}
        />
      )}
    </>
  );
}
