import axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useContext } from "react";
import ReactGA from "react-ga4";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { addHeadersToPublicRequest } from "../../config/axiosConfig";
import { AppContext } from "../../config/store";
import { LoggedInUserDto } from "../../services/api";
import { TokenData, getBaseUrl, setCookies } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import { removeCaptcha } from "../../utils/otpUtils";
import useGetErrorMessage from "../useGetErrorMessage";
import useTranslationByClient, {
  TranslateFunction,
} from "../useTranslationByClient";

const loginUser = async (
  tokens: TokenData | null,
  translate: TranslateFunction,
) => {
  if (!tokens) {
    throw new Error(translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"));
  }

  const baseUrl = getBaseUrl();
  const res = await axios.get(`${baseUrl}/user/login`, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
      ...addHeadersToPublicRequest(),
    },
  });
  return res;
};
const useLoginUser = (
  setSpinner: Dispatch<SetStateAction<boolean>>,
  tokens: TokenData | null,
) => {
  const { setPostAuthUser } = useContext(AppContext);
  const navigate = useNavigate();
  const getErrorMessage = useGetErrorMessage();
  const translate = useTranslationByClient();

  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["loginUser"],
    () => loginUser(tokens, translate),
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<LoggedInUserDto>) => {
        if (!tokens) {
          createToast(
            translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"),
            "error",
            3000,
          );
          navigate("/");
          return;
        }
        setCookies(
          tokens.accessToken,
          tokens.refreshToken,
          tokens.expirationTime,
        );
        setSpinner(false);
        ReactGA.event("login");
        setPostAuthUser(response.data);
        localStorage.removeItem("phone");
        createToast(
          translate("GENERAL.SUCCESS.USER_LOGGED_IN"),
          "success",
          3000,
        );
        removeCaptcha();
        if (localStorage.getItem("original-url")) {
          navigate(`${localStorage.getItem("original-url")}`);
          localStorage.removeItem("original-url");
        } else {
          navigate("/dashboard");
        }
      },
      onError: (err: AxiosError) => {
        setSpinner(false);
        createToast(getErrorMessage(err), "error", 3000);
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
};

export default useLoginUser;
