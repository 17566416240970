/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslationByClient from "../../hooks/useTranslationByClient";

export default function FeedbackComponent() {
  const navigate = useNavigate();
  const translate = useTranslationByClient();
  const handleClick = () => {
    navigate("/dashboard");
  };

  const email = "RMSupport@RazorMetrics.com";
  const body = "";
  const subject = translate("FEEDBACK_PAGE.FEEDBACK_EMAIL_SUBJECT");
  const theme = useTheme();

  return (
    <Grid
      container
      xs={12}
      width="100%"
      height="auto"
      alignItems="center"
      alignContent="space-between"
      sx={{ flexGrow: "1 !important" }}
    >
      <Grid item xs={12} mt={1} overflow="hidden">
        <Typography
          variant="h1"
          fontSize="2.75rem"
          textAlign="center"
          textTransform="capitalize"
          sx={{
            marginBlockStart: "0.6em",
          }}
          lineHeight="1"
          px={6}
          pb={4}
        >
          {translate("FEEDBACK_PAGE.TITLE")}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          px={3}
          mt={2}
          mb={3}
        >
          <Typography variant="body1">
            {translate("FEEDBACK_PAGE.FEEDBACK_CALL_TO_ACTION_TEXT")}
            {": "}
            <b>
              <a
                style={{
                  textDecoration: "none",
                  color: `${theme.palette.info.darker}`,
                }}
                href={`mailto:${email}?subject=${
                  encodeURIComponent(subject) || ""
                }&body=${encodeURIComponent(body) || ""}`}
              >
                {email}
              </a>
            </b>{" "}
            {translate("FEEDBACK_PAGE.OR_CLICK_BELOW_TEXT")}
            {": "}
          </Typography>
        </Box>

        <Box width="100%" mb={4} mt={3} className="justify-center">
          <Button
            sx={{ borderRadius: "0px", borderStyle: "solid" }}
            type="link"
            variant="outlined"
            href={`mailto:${email}?subject=${
              encodeURIComponent(subject) || ""
            }&body=${encodeURIComponent(body) || ""}`}
          >
            {translate("FEEDBACK_PAGE.CONTACT_US_BUTTON")}
          </Button>
        </Box>
      </Grid>
      <Box className="page-back-button">
        <Button
          variant="primary"
          aria-label="back"
          onClick={handleClick}
          className="disabled-hover-effects"
        >
          {translate("GENERAL.BUTTON.DONE")}
        </Button>
      </Box>
    </Grid>
  );
}
