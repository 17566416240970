import axios, { AxiosResponse } from "axios";
import { getCookie } from "./authUtils";

interface RefreshTokenResponse {
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
}

// refresh token
export default async function refreshToken() {
  const refreshCookie = getCookie("refreshToken");
  const response: AxiosResponse<RefreshTokenResponse> = await axios.post(
    `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
    {
      grant_type: "refresh_token",
      refresh_token: refreshCookie,
    },
  );
  const now = new Date().getTime() / 1000; // get current date/time in seconds
  const expiresIn = (now + Number(response.data.expires_in)) * 1000; // convert expires in to milliseconds
  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token,
    expiresIn: `${expiresIn}`,
    userId: response.data.user_id,
  };
}
