import { useQuery } from "react-query";
import axiosConfig from "../../config/axiosConfig";

const getSavingSummary = async () => {
  const response = await axiosConfig.get("user-savings/total-savings");
  return response;
};

export default function useSavingSummaryModule() {
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getSavingSummary"],
    getSavingSummary,
    {
      enabled: false,
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
}
