import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuListItem } from "../../config/menuItems";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { enableScreenScroll } from "../../utils/globalUtils";
import SideBarButton from "./SideBarButton";
import SideBarCollapsibleButton from "./SideBarCollapsibleButton";

interface SideBarContentProps {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  links: MenuListItem[];
  handleLogout: () => void;
}

function SideBarContent({
  toggleDrawer,
  links,
  handleLogout,
}: SideBarContentProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigation = (route: string) => {
    toggleDrawer(false);
    enableScreenScroll();
    navigate(route);
  };

  const [expanded, setExpanded] = React.useState<number>(-1);

  const handleChange = (panel: number) => {
    setExpanded(panel === expanded ? -1 : panel);
  };
  const translate = useTranslationByClient();

  return (
    <Grid width={theme.constants.menuSideBarDrawerWidth} xs={12} container>
      <Grid item xs={12}>
        {links.map((link, index) => {
          return link.children ? (
            <SideBarCollapsibleButton
              handleClick={handleChange}
              handleChildClick={handleNavigation}
              link={link}
              expanded={expanded}
              index={index}
            />
          ) : (
            <SideBarButton
              handleClick={() => handleNavigation(link.link)}
              text={link.text}
            />
          );
        })}
        <SideBarButton
          handleClick={handleLogout}
          text={translate("MENU_ITEMS.LOGOUT")}
        />
      </Grid>
    </Grid>
  );
}

export default SideBarContent;
