import React from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import FeedbackComponent from "../components/feedbackPage/FeedbackComponent";
import "../styles/components/login.scss";

export default function FeedbackPage() {
  return (
    <Page isSecondary={false} header={<Header />} displayExtMemIdAtBottom>
      <FeedbackComponent />
    </Page>
  );
}
