import axios from "axios";
import { useQuery } from "react-query";
import { addHeadersToPublicRequest } from "../../config/axiosConfig";
import { getBaseUrl, getMemberId } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import useGetErrorMessage from "../useGetErrorMessage";

const getMemberfromPhone = async (dateOfBirth: string) => {
  const baseUrl = getBaseUrl();
  const memberId = getMemberId();
  const res = await axios.post(
    `${baseUrl}/user/verify-date-of-birth`,
    { encryptedMemberInfoUuid: memberId, dateOfBirth },
    {
      headers: addHeadersToPublicRequest(),
    },
  );
  return res;
};

const useVerifyMemberDateOfBirth = (dateOfBirth: string) => {
  const getErrorMessage = useGetErrorMessage();
  const { isLoading, refetch, data, error, isFetching, status } = useQuery(
    ["verifyMemberDOB"],
    () => getMemberfromPhone(dateOfBirth),
    {
      retry: 0,
      cacheTime: 10,
      enabled: false,
      onError: (err) => {
        createToast(getErrorMessage(err), "error", 3000);
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, status };
};

export default useVerifyMemberDateOfBirth;
