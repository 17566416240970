/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
import { LoggedInUserDto } from "../services/api/models/LoggedInUserDto";
import { MemberInfoDto } from "../services/api/models/MemberInfoDto";
import variables from "../styles/variables.module.scss";
import { ThemeColors } from "./theme";

export interface IState {
  overlayName: string;
  preferredPhone: string | null;
  setPreferredPhone: (preferredPhone: string | null) => void;
  setOverlayName: (value: string) => void;
  postAuthUser: LoggedInUserDto;
  setPostAuthUser: (postAuthUser: LoggedInUserDto) => void;
  preAuthUser: MemberInfoDto;
  setPreAuthUser: (postAuthUser: MemberInfoDto) => void;
  themeColors: ThemeColors;
  setThemeColors: (themeColors: ThemeColors) => void;
}
export const initialState: IState = {
  overlayName: "NONE",
  preferredPhone: null,
  setPreferredPhone: () => {},
  setOverlayName: () => {},
  postAuthUser: {},
  setPostAuthUser: () => {},
  preAuthUser: {
    memberInfoId: "",
    memberPhone: "",
    firebaseUserId: "",
  },
  setPreAuthUser: () => {},
  themeColors: {
    primaryColor: variables.primaryColor,
    secondaryColor: variables.secondaryColor,
    infoMainColor: variables.infoMain,
    infoLightColor: variables.infoLight,
    infoDarkColor: variables.infoDark,
    infoDarkerColor: variables.infoDarker,
    infoLighterColor: variables.infoLighter,
  },
  setThemeColors: () => {},
};

export const AppContext = createContext(initialState);
