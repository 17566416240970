import { useEffect } from "react";
import ReactGA from "react-ga4";
import setTabTitle, { getPageTitle } from "../../utils/routeUtils";

const useSetPageTitle = () => {
  useEffect(() => {
    const routeTitle = getPageTitle();
    if (routeTitle !== "") {
      ReactGA.event("page_view");
      setTabTitle(routeTitle);
    }
  }, [window.location.pathname]);
};

export default useSetPageTitle;
