import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "../components/common/Drawer";
import PageRefactored from "../components/common/PageRefactored";
import DiscountDetailComponent from "../components/discountDetailPage/DiscountDetailComponent";
import useGetUserAllDiscountCards from "../hooks/useGetUserAllDiscountCards";
import useTranslationByClient from "../hooks/useTranslationByClient";
import createToast from "../utils/globalUtils";

export default function DiscountDetailPage() {
  const [drawer, toggleDrawer] = useState(false);
  const { data, isLoading, error, refetch } = useGetUserAllDiscountCards();
  const translation = useTranslationByClient();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.data.discountCards.length == 0) {
      createToast(translation("GENERAL.ERROR.NO_DISCOUNT_CARDS"), "error");
      navigate("/dashboard");
    }
  }, [data]);

  return (
    <PageRefactored
      isSecondary
      isLoading={isLoading}
      error={error}
      header={<Drawer drawer={drawer} toggleDrawer={toggleDrawer} />}
    >
      <DiscountDetailComponent discountCards={data?.data.discountCards ?? []} />
    </PageRefactored>
  );
}
