import React from "react";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import OtpComponent from "../components/otpPage/OtpComponent";

function OtpPage() {
  return (
    <Page isSecondary={false} header={<Header />}>
      <OtpComponent />
    </Page>
  );
}

export default OtpPage;
