import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import ExclaimationMarkIcon from "../../../assets/ExclaimationMarkIcon";
import { AppContext } from "../../../config/store";
import useTranslationByClient from "../../../hooks/useTranslationByClient";
import "../../../styles/components/widgets.scss";
import WidgetBanner from "../../common/WidgetBanner";

function PrescriptionsReadyWidget() {
  const { setOverlayName } = useContext(AppContext);

  const handleClick = () => {
    setOverlayName("PRESCRIPTION_REFILL");
  };

  const theme = useTheme();
  const translate = useTranslationByClient();
  return (
    <WidgetBanner
      img={
        <ExclaimationMarkIcon
          fill={theme.palette.info.darker!}
          className="exclamation-mark"
        />
      }
      text={translate(
        "DASHBOARD_PAGE.WIDGET.PRESCRIPTIONS_READY.BANNER_MAIN_TEXT",
      )}
      subText={translate(
        "DASHBOARD_PAGE.WIDGET.PRESCRIPTIONS_READY.BANNER_SUB_TEXT",
      )}
      handleClick={handleClick}
    />
  );
}
export default PrescriptionsReadyWidget;
