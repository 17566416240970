import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorBox from "../components/common/ErrorBox";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import SpinnerOverlay from "../components/otpPage/SpinnerOverlay";
import { drug } from "../components/switchNotificationPage/SavingsSwitchWidget";
import SwitchNotificationComponent from "../components/switchNotificationPage/SwitchNotificationComponent";
import useGetSwitchDetails from "../hooks/useGetSwitchDetails";
import useTranslationByClient from "../hooks/useTranslationByClient";

export interface Saving {
  memberSavings?: number | null;
  planSavings?: number;
  amountPaidByMember?: number;
}
function SwitchNotificationPage() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [targetDrug, setTargetDrug] = useState<drug | undefined>(undefined);
  const [sourceDrug, setSourceDrug] = useState<drug | undefined>(undefined);
  const [savings, setSavings] = useState<Saving | undefined>(undefined);
  const { isLoading, error, isSuccess, refetch } = useGetSwitchDetails({
    id,
    setSourceDrug,
    setTargetDrug,
    setSavings,
  });
  const translate = useTranslationByClient();
  useEffect(() => {
    if (id) refetch();
  }, []);

  return (
    <Page
      isLoading={isLoading}
      error={error}
      isSecondary={false}
      header={<Header />}
    >
      {id && isSuccess && sourceDrug && targetDrug && savings ? (
        <SwitchNotificationComponent
          targetDrug={targetDrug!}
          sourceDrug={sourceDrug!}
          savings={savings}
        />
      ) : !id ? (
        <ErrorBox error={translate("GENERAL.ERROR.UNSPECIFIED_SWITCH_ID")} />
      ) : (
        <SpinnerOverlay text={translate("GENERAL.SPINNER.LOADING_TEXT")} />
      )}
    </Page>
  );
}

export default SwitchNotificationPage;
