import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Grid,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DashboardModules from "../../config/dashboardModules";
import { AppContext } from "../../config/store";
import useRenderCount from "../../hooks/custom/useRenderCount";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { GrantedAuthority } from "../../services/api/models/GrantedAuthority";
import "../../styles/components/dashboard.scss";
import AuthorityCheck from "../widgets/AuthorityCheck";

export const SavingsContext = React.createContext<null | {
  savings: number;
  userCreationDate: string;
}>(null);

export default function DashboardComponent() {
  const { postAuthUser, setOverlayName } = useContext(AppContext);
  const translate = useTranslationByClient();

  const [name, setName] = useState("");

  const [authorities, setAuthorities] = useState<GrantedAuthority[]>([]);
  const [searchParams] = useSearchParams();
  const paymentIntent = searchParams.get("payment_intent");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const savingModule = "SAVINGS_TOTAL";

  const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;

  const shouldShowWelcomeText = () => {
    const isNewUser = localStorage.getItem("isNewUser");
    return isNewUser && isNewUser == "true";
  };

  useEffect(() => {
    return () => {
      setOverlayName("NONE");
      localStorage.removeItem("isNewUser");
    };
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      setOverlayName("PAYMENT_SUCCESS");
      const nextURL = `${window.location.pathname}`;
      window.history.replaceState({}, "", nextURL);
    }
  }, []);

  useEffect(() => {
    if (postAuthUser) {
      const userAuthorities: GrantedAuthority[] =
        postAuthUser.authorities ?? [];

      setName(postAuthUser.name ?? " ");
      setAuthorities(userAuthorities);
    }
  }, [postAuthUser]);
  useRenderCount("Dashboard Component");

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Grid justifyContent="center" display="flex" width="100%">
      <Grid container key="right">
        <Grid container mt={2} mb={2} mx={1.5}>
          <Grid item xs={11}>
            <Typography
              variant="h1"
              fontSize="2.5rem"
              textAlign="center"
              textTransform="capitalize"
            >
              {translate("DASHBOARD_PAGE.WELCOME_TEXT", {
                userName: name.split(" ")[0],
              })}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {!shouldShowWelcomeText() && (
              <IconButton onClick={handleInfoClick}>
                <InfoOutlinedIcon sx={{ color: "info.darker" }} />
              </IconButton>
            )}
          </Grid>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ backgroundColor: "info.main" }}>
              <Typography
                variant="body1"
                sx={{
                  overflow: "visible",
                  color: "info.darker",
                  fontSize: "0.8rem",
                  padding: "0.5rem",
                }}
                textAlign="center"
              >
                {translate("DASHBOARD_PAGE.ENROLLMENT_TEXT", {
                  clientName: `${postAuthUser.clientName}'${
                    postAuthUser.clientName?.endsWith("s") ? " " : "s "
                  }`,
                })}
                &nbsp;
                {translate("DASHBOARD_PAGE.INTRODUCTORY_TEXT")}
              </Typography>
            </Box>
          </Popover>
        </Grid>
        <Grid xs={12} mb={1.5} display="flex" alignItems="center" width="100%">
          <Grid xs={4} sm={3}>
            <Box
              bgcolor="info.light"
              height="60px"
              borderRadius={isMobile ? "0px 8px 8px 0px" : "8px 8px 8px 8px"}
              className="row-center"
            >
              <img
                src={postAuthUser.logoUrl}
                alt="clientLogo"
                aria-label="client-logo"
                width="50"
                height="50"
              />
            </Box>
          </Grid>
          <Grid xs={8} sm={9} px={shouldShowWelcomeText() ? 1 : 0}>
            {shouldShowWelcomeText() ? (
              <Typography variant="body1" textAlign="center">
                {translate("DASHBOARD_PAGE.ENROLLMENT_TEXT", {
                  clientName: `${postAuthUser.clientName}'${
                    postAuthUser.clientName?.endsWith("s") ? " " : "s "
                  }`,
                })}
              </Typography>
            ) : (
              <AuthorityCheck
                key={savingModule}
                authority={savingModule}
                authorityList={authorities}
                // setOverlayName={setOverlayName}
              />
            )}
          </Grid>
        </Grid>

        {shouldShowWelcomeText() && (
          <Grid xs={12} mb={2.5} className="row-center">
            <Grid
              xs={10}
              md={12}
              className="row-center"
              mb={2.5}
              sx={{ overflow: "visible" }}
            >
              <Typography
                variant="body1"
                sx={{
                  overflow: "visible",
                }}
                textAlign="center"
              >
                {translate("DASHBOARD_PAGE.INTRODUCTORY_TEXT")}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid mt={2} mb={2} style={{ width: "100%" }} aria-label="widgets-list">
          {DashboardModules.map((moduleName: string) => (
            <>
              {!shouldShowWelcomeText() && moduleName == savingModule ? null : (
                <AuthorityCheck
                  key={moduleName}
                  authority={moduleName}
                  authorityList={authorities}
                  // setOverlayName={setOverlayName}
                />
              )}
            </>
          ))}
        </Grid>
        <Typography ml={1} fontSize="0.75rem">
          {translate("GENERAL.MEMBER_ID")}: {postAuthUser.externalMemberId}
        </Typography>
      </Grid>
    </Grid>
  );
}
