import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import i18n from "../../config/i18n";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { MemberAddressDto } from "../../services/api/models/MemberAddressDto";
import InputField from "./InputField";

interface ProfileAddressSectionProps {
  isEditingPreferredAddress: boolean;
  defaultAddress: string;
  editablePreferredAddress: MemberAddressDto;
  setEditablePreferredAddress: React.Dispatch<
    React.SetStateAction<MemberAddressDto>
  >;
  currentPreferredAddress: MemberAddressDto | undefined | null;
}

function ProfileAddressSection({
  isEditingPreferredAddress,
  defaultAddress,
  editablePreferredAddress,
  setEditablePreferredAddress,
  currentPreferredAddress,
}: ProfileAddressSectionProps) {
  const theme = useTheme();
  const translate = useTranslationByClient();
  const addressString =
    currentPreferredAddress &&
    Object.values(currentPreferredAddress).filter((val) => val && val !== "")
      .length > 0
      ? Object.values(currentPreferredAddress)
          .filter((val) => val && val !== "")
          .join(", ")
      : null;

  let alphaPattern = "^[A-Za-z ]+$";
  let alphaNumericPattern = "^[A-Za-z 0-9]+$";

  if (i18n.language === "es") {
    alphaPattern = "^[A-Za-z ÁáÉéÍíÑñÓóÚúÜü¿¡ªº]+$";
    alphaNumericPattern = "^[A-Za-z ÁáÉéÍíÑñÓóÚúÜü¿¡ªº0-9]+$";
  }

  const preferredAddressJSX = isEditingPreferredAddress ? (
    <>
      <Grid item xs={12} my={1} className="col-center">
        <Typography variant="body1" textAlign="center">
          {translate("PROFILE_PAGE.ADDRESS_SECTION.HEADING")}.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        my={1}
        p={2}
        className="rounded-container"
        sx={{
          border: "0.5px solid",
          borderColor: `${theme.palette.info.dark}`,
          backgroundColor: `${theme.palette.info.main}`,
        }}
      >
        <Grid item xs={12} width="100%">
          <InputField
            placeholder={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.STREET_INPUT_PLACEHOLDER",
            )}
            autoFocus
            aria-label="street field"
            value={editablePreferredAddress?.street}
            onChange={(event: any) => {
              setEditablePreferredAddress({
                ...editablePreferredAddress,
                street: event.target.value,
              });
            }}
            pattern={alphaNumericPattern}
            label={translate("PROFILE_PAGE.ADDRESS_SECTION.STREET_INPUT_LABEL")}
            errorMessage={translate("GENERAL.ERROR.ALPHA_NUMERIC_ALLOWED")}
          />
          <InputField
            placeholder={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.SUITE_INPUT_PLACEHOLDER",
            )}
            label={translate("PROFILE_PAGE.ADDRESS_SECTION.SUITE_INPUT_LABEL")}
            ariaLabel="suite field"
            pattern={alphaNumericPattern}
            errorMessage={translate("GENERAL.ERROR.ALPHA_NUMERIC_ALLOWED")}
            value={editablePreferredAddress?.suite}
            onChange={(event: any) => {
              setEditablePreferredAddress({
                ...editablePreferredAddress,
                suite: event.target.value,
              });
            }}
          />
          <InputField
            placeholder={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.CITY_INPUT_PLACEHOLDER",
            )}
            label={translate("PROFILE_PAGE.ADDRESS_SECTION.CITY_INPUT_LABEL")}
            onChange={(event: any) => {
              setEditablePreferredAddress({
                ...editablePreferredAddress,
                city: event.target.value,
              });
            }}
            pattern={alphaPattern}
            errorMessage={translate("GENERAL.ERROR.ALPHA_ALLOWED")}
            value={editablePreferredAddress?.city}
            ariaLabel="city field"
          />
          <InputField
            placeholder={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.STATE_INPUT_PLACEHOLDER",
            )}
            label={translate("PROFILE_PAGE.ADDRESS_SECTION.STATE_INPUT_LABEL")}
            onChange={(event: any) => {
              setEditablePreferredAddress({
                ...editablePreferredAddress,
                state: event.target.value,
              });
            }}
            pattern={alphaPattern}
            errorMessage={translate("GENERAL.ERROR.ALPHA_ALLOWED")}
            value={editablePreferredAddress?.state}
            ariaLabel="state field"
          />
          <InputField
            placeholder={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.ZIP_CODE_INPUT_PLACEHOLDER",
            )}
            label={translate(
              "PROFILE_PAGE.ADDRESS_SECTION.ZIP_CODE_INPUT_LABEL",
            )}
            ariaLabel="zip code field"
            pattern="^[0-9]+$"
            errorMessage={translate("GENERAL.ERROR.NUMERIC_ALLOWED")}
            value={editablePreferredAddress?.zipCode}
            onChange={(event: any) => {
              setEditablePreferredAddress({
                ...editablePreferredAddress,
                zipCode: event.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  ) : null;

  return (
    <div>
      <Grid item xs={12} className="col-center">
        <Typography variant="body1" mb={1} textAlign="center">
          {translate("PROFILE_PAGE.ADDRESS_SECTION.PROVIDED_ADDRESS_TEXT")}
        </Typography>
      </Grid>
      <Typography
        variant="h2"
        mb={1}
        textAlign="center"
        fontWeight={600}
        sx={{
          textDecoration:
            isEditingPreferredAddress || (addressString && addressString !== "")
              ? "line-through"
              : "",
        }}
      >
        {defaultAddress}
      </Typography>
      {(isEditingPreferredAddress ||
        (addressString && addressString !== "")) && (
        <Grid item xs={12} className="col-center">
          <Typography variant="body1" mb={1} textAlign="center">
            {translate("PROFILE_PAGE.ADDRESS_SECTION.PREFERRED_ADDRESS_TEXT")}
          </Typography>
        </Grid>
      )}

      {!isEditingPreferredAddress && addressString && addressString !== "" && (
        <Typography variant="h2" mb={1} textAlign="center" fontWeight={600}>
          {addressString}
        </Typography>
      )}
      {preferredAddressJSX}
    </div>
  );
}

export default ProfileAddressSection;
