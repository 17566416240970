import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/rm-rxadvocate-outlined-large.png";
import "../../styles/components/header.scss";
import { isLoggedIn } from "../../utils/authUtils";
import LanguageDropdown from "./LanguageDropdown";

function Header({ hideLanguageDropdown }: { hideLanguageDropdown?: boolean }) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      style={{ height: "fit-content" }}
      display="flex"
      justifyContent="center"
      className="header"
    >
      <Grid
        style={{ height: "fit-content" }}
        display="flex"
        justifyContent="center"
        item
        xs={7}
        sm={5}
        md={4}
        lg={3}
        xl={2}
        onClick={() => {
          navigate("/");
        }}
      >
        <picture>
          <source
            media="(min-width:600px)"
            width="200"
            height="44"
            srcSet={logo}
          />
          <source
            media="(max-width:300px)"
            width="120"
            height="27"
            srcSet={logo}
          />
          <img
            width="157"
            height="35"
            src={logo}
            alt="RazorMetrics logo"
            className="image-cover"
            aria-label="RazorMetrics logo"
          />
        </picture>
      </Grid>
      {!hideLanguageDropdown && isLoggedIn() && <LanguageDropdown />}
    </Grid>
  );
}

export default Header;
