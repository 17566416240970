import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import {
  getCutOffString,
  getNoOfSpaces,
  getRemainingString,
  maxSpaces,
} from "../../utils/globalUtils";

interface questionAnswerType {
  question: string;
  answer: string;
  index?: number;
  handleExpansionChange?: (index: number) => void;
  activeIndex?: number;
}
function QuestionAnswer({
  question,
  answer,
  index,
  activeIndex,
  handleExpansionChange,
}: questionAnswerType) {
  const noOfSpaces = getNoOfSpaces(answer);

  const containerRef = useRef(null);
  const translate = useTranslationByClient();

  useEffect(() => {
    if (containerRef?.current) {
      const container = containerRef.current as HTMLElement;
      container.style.fontSize = window.screen.width >= 300 ? "1rem" : "0.9rem";
    }
  }, [activeIndex]);

  return (
    <Grid
      borderRadius="5px"
      xs={12}
      md={12}
      mb={2}
      sx={{ backgroundColor: "info.light" }}
      p={2}
    >
      <Box display="flex" alignItems="flex-start" gap={1} width="100%">
        <Typography
          aria-label="question"
          variant="subtitle3"
          sx={{ overflow: "visible" }}
        >
          Q
        </Typography>

        <Typography
          marginBottom={1.5}
          marginTop={0.5}
          lineHeight={1.267}
          variant="body1"
          fontWeight="500"
        >
          {question}?
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-start" gap={1} ml={4}>
        <Typography
          aria-label="answer"
          variant="subtitle3"
          sx={{ overflow: "visible" }}
        >
          A
        </Typography>

        <Typography
          marginTop={0.5}
          marginBottom={2}
          lineHeight={1.267}
          variant="body1"
          ref={containerRef}
          textAlign="justify"
          sx={{
            hyphens: "auto",
            wordSpacing: "-0.05em",
          }}
        >
          {getCutOffString(answer, noOfSpaces)}

          {noOfSpaces > maxSpaces && (
            <>
              {activeIndex === index && (
                <Box component="span">
                  &nbsp; {getRemainingString(answer, noOfSpaces)}
                </Box>
              )}
              &nbsp;
              <Box
                component="span"
                sx={{ textDecoration: "underline" }}
                onClick={() =>
                  handleExpansionChange && handleExpansionChange(index!)
                }
              >
                {activeIndex !== index
                  ? `... ${translate("GENERAL.READ_MORE")}`
                  : translate("GENERAL.READ_LESS")}
              </Box>
            </>
          )}
        </Typography>
      </Box>
    </Grid>
  );
}

export default QuestionAnswer;
