import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../languages/en/default";
import es from "../languages/es/default";

export type LanguageKey = "ENGLISH" | "SPANISH";

export const LanguageCode: { [key in LanguageKey]: string } = {
  ENGLISH: "en",
  SPANISH: "es",
};

const resources = {
  [LanguageCode.ENGLISH]: {
    default: en,
  },

  [LanguageCode.SPANISH]: {
    default: es,
  },
};

export const getBrowserLang = () => {
  const languageWithRegion =
    navigator.language || (navigator.languages || [LanguageCode.ENGLISH])[0];
  return languageWithRegion.split("-")[0];
};

export const FALLBACK_NS = "default";
export const FALLBACK_LNG = "en";

(async () => {
  await i18n.use(initReactI18next).init({
    resources,
    lng: getBrowserLang(),
    fallbackLng: FALLBACK_LNG, // Fallback language for other clients
    compatibilityJSON: "v3",
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "default",
    fallbackNS: [FALLBACK_NS], // fallback namespace if no client name given
  });
})();

export function loadClientTranslations(
  clientName: string,
  language: string,
  clientTranslations: any,
) {
  i18n.addResourceBundle(language, clientName, clientTranslations, true);
}

function getValueByPath(object: any, path: string) {
  if (object === undefined) return undefined;
  const keys = path.split(".");
  let current = object;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (current[key] === undefined) {
      return undefined;
    }
    current = current[key];
  }
  return current;
}

export const getTranslation = (
  key: string,
  translation: TFunction,
  ns?: string,
  substitutionMap?: { [key: string]: string | boolean },
) => {
  let translatedKey = key;

  if (
    ns !== undefined &&
    getValueByPath(i18n.getResourceBundle(i18n.language, ns!), key) !==
      undefined
  ) {
    // if key is present in current namespace and languauge, return translation from it
    translatedKey = translation(key, substitutionMap!);
  } else if (
    getValueByPath(i18n.getResourceBundle(i18n.language, FALLBACK_NS), key) !==
    undefined
  ) {
    // if key is not present in current namespace, fallback to fallback ns of same language
    translatedKey = translation(key, {
      ns: FALLBACK_NS,
      ...substitutionMap,
    });
  } else if (
    ns !== undefined &&
    getValueByPath(i18n.getResourceBundle(FALLBACK_LNG, ns!), key) !== undefined
  ) {
    // if key is not present in fallback namespace, fallback to same namespace of fallback language
    translatedKey = translation(key, {
      ns,
      lng: FALLBACK_LNG,
      ...substitutionMap,
    });
  } else if (
    getValueByPath(i18n.getResourceBundle(FALLBACK_LNG, FALLBACK_NS!), key) !==
    undefined
  ) {
    // if key is not present in same namespace of fallback language, fallback to fallback namespace of fallback language
    translatedKey = translation(key, {
      ns: FALLBACK_NS,
      lng: FALLBACK_LNG,
      ...substitutionMap,
    });
  }

  return translatedKey;
};

export default i18n;
