import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import axiosConfig from "../../config/axiosConfig";
import { DrugDto } from "../../services/api/models/DrugDto";

const getDrug = async () => {
  const response = await axiosConfig.get("drugs/get");
  return response;
};

export default function useGetDrug() {
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getDrug"],
    getDrug,
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<DrugDto[]>) => {
        return response.data;
      },
      onError: (err: AxiosError) => {
        return err;
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
}
