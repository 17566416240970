import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import history from "../config/history";
import { AppContext } from "../config/store";
import { MemberInfoDto } from "../services/api/models/MemberInfoDto";
import {
  clearSession,
  getBaseUrl,
  isDifferentUserAccess,
  setMemberId,
} from "../utils/authUtils";
import useTranslationByClient from "./useTranslationByClient";

const getMemberData = async (id: string) => {
  const baseUrl = getBaseUrl();
  const res = await axios.get(`${baseUrl}/members/${id}`, {
    headers: {
      "X-Application-Identifier": `${process.env.REACT_APP_APPLICATION_IDENTIFIER}`,
    },
  });
  return res;
};

const useGetMember = (inputMemberId: string) => {
  const location = useLocation();
  const translation = useTranslationByClient();
  const { setPreAuthUser } = useContext(AppContext);
  const { isLoading, error, data, isSuccess, refetch, isFetching } = useQuery(
    ["getMember", inputMemberId],
    () => getMemberData(inputMemberId),
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<MemberInfoDto>) => {
        if (isDifferentUserAccess(translation, response.data.firebaseUserId)) {
          clearSession();
          history.push(`/member/${inputMemberId}`);
        }
        setPreAuthUser(response.data);
        setMemberId(response.data.memberInfoId);
        if (
          response.data.firebaseUserId &&
          location.pathname.includes("/member/")
        ) {
          history.push("/");
        }
      },
    },
  );
  return { isLoading, error, data, isSuccess, refetch, isFetching };
};

export default useGetMember;
