import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { TranslateFunction } from "../hooks/useTranslationByClient";
import { MemberAddressDto } from "../services/api";

export default function createToast(
  toastText: string,
  toastType: "success" | "error" | "info",
  autoClose?: number,
) {
  toast(toastText, {
    position: "top-right",
    autoClose: autoClose ?? 2000,
    type: toastType,
    theme: "colored",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
}

export const disableScreenScroll = () => {
  const htmlElement = document?.querySelector("html") as HTMLElement;

  if (!document.body.classList.contains("noscroll")) {
    document.body.classList.add("noscroll");
  }
  if (!htmlElement.classList.contains("fix-html")) {
    htmlElement?.classList.add("fix-html");
  }
};

export const enableScreenScroll = () => {
  const htmlElement = document?.querySelector("html") as HTMLElement;

  document.body.classList.remove("noscroll");
  htmlElement?.classList.remove("fix-html");
};

// list of testing envs
export const localEnvs = ["develop", "development", "qa", "qa2", ""];

// utils to cutt off faqs answer strings
export const getMaxSpaces = () => {
  let no = 30;
  if (window.screen.width >= 650) {
    no = 50;
  }
  if (window.screen.width >= 1000) {
    no = 70;
  }
  return no;
};

export const maxSpaces = getMaxSpaces();

export const getNoOfSpaces = (answerText: string) =>
  (answerText.match(/ /g) || []).length;

export const getCutOffString = (answerText: string, noOfSpaces: number) => {
  const a = answerText.split(" ").slice(0, maxSpaces).join(" ");
  return noOfSpaces <= maxSpaces ? answerText : a;
};

export const getRemainingString = (answerText: string, noOfSpaces: number) => {
  const a = answerText.split(" ").slice(maxSpaces).join(" ");
  return noOfSpaces <= maxSpaces ? answerText : a;
};

export const isValidPhone = (phone: string) => {
  return isValidPhoneNumber(phone);
};

export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const isValidAddress = (
  address: MemberAddressDto,
  language: string,
  translate: TranslateFunction,
) => {
  let alphaRegex = /^[A-Za-z\s]+$/;
  let alphaNumericRegex = /^[A-Za-z0-9\s]+$/;

  if (language === "es") {
    alphaRegex = /^[A-Za-z\sÁáÉéÍíÑñÓóÚúÜü¿¡ªº]+$/;
    alphaNumericRegex = /^[A-Za-z0-9\sÁáÉéÍíÑñÓóÚúÜü¿¡ªº]+$/;
  }

  if (address.city && !alphaRegex.test(address.city)) {
    createToast(
      `[${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.CITY_INPUT_LABEL",
      )}]: ${translate("GENERAL.ERROR.ALPHA_ALLOWED")}`,
      "error",
      3000,
    );
    return false;
  }
  if (address.state && !alphaRegex.test(address.state)) {
    createToast(
      `[${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.STATE_INPUT_LABEL",
      )}]: ${translate("GENERAL.ERROR.ALPHA_ALLOWED")}`,
      "error",
      3000,
    );
    return false;
  }
  if (address.zipCode && !/^[0-9]+$/.test(address.zipCode)) {
    createToast(
      `[${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.ZIP_CODE_INPUT_LABEL",
      )}]: ${translate("GENERAL.ERROR.NUMERIC_ALLOWED")}`,
      "error",
      3000,
    );
    return false;
  }
  if (address.suite && !alphaNumericRegex.test(address.suite)) {
    createToast(
      `[${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.SUITE_INPUT_LABEL",
      )}]: ${translate("GENERAL.ERROR.ALPHA_NUMERIC_ALLOWED")}`,
      "error",
      3000,
    );
    return false;
  }
  if (address.street && !alphaNumericRegex.test(address.street)) {
    createToast(
      `[${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.STREET_INPUT_LABEL",
      )}]: ${translate("GENERAL.ERROR.ALPHA_NUMERIC_ALLOWED")}`,
      "error",
      3000,
    );
    return false;
  }
  if (address?.city && address.city.length > 50) {
    createToast(
      `${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.CITY_INPUT_LABEL",
      )} ${translate("GENERAL.ERROR.LENGTH_EXCEED", {
        max: (50).toString(),
      })}`,
      "error",
      3000,
    );
    return false;
  }
  if (address?.state && address.state.length > 50) {
    createToast(
      `${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.STATE_INPUT_LABEL",
      )} ${translate("GENERAL.ERROR.LENGTH_EXCEED", {
        max: (50).toString(),
      })}`,
      "error",
      3000,
    );
    return false;
  }
  if (address?.zipCode && address.zipCode.length > 10) {
    createToast(
      `${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.ZIP_CODE_INPUT_LABEL",
      )} ${translate("GENERAL.ERROR.LENGTH_EXCEED", {
        max: (10).toString(),
      })}`,
      "error",
      3000,
    );
    return false;
  }
  if (address?.suite && address.suite.length > 50) {
    createToast(
      `${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.SUITE_INPUT_LABEL",
      )} ${translate("GENERAL.ERROR.LENGTH_EXCEED", {
        max: (50).toString(),
      })}`,
      "error",
      3000,
    );
    return false;
  }
  if (address?.street && address.street.length > 50) {
    createToast(
      `${translate(
        "PROFILE_PAGE.ADDRESS_SECTION.STREET_INPUT_LABEL",
      )} ${translate("GENERAL.ERROR.LENGTH_EXCEED", {
        max: (50).toString(),
      })}`,
      "error",
      3000,
    );
    return false;
  }
  return true;
};

export const isHexColor = (value: string) => {
  const regex = /^#([0-9A-F]{3}){1,2}$/i;
  return regex.test(value);
};

export const isIOSDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () => {
  return (
    "standalone" in (window as any).navigator &&
    (window as any).navigator.standalone
  );
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const convertStringToRegExp = (str: string) => {
  try {
    const regExp = new RegExp(str);
    return regExp;
  } catch (err) {
    console.log("invalid regular expresion");
    return null;
  }
};
