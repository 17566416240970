import InfoIcon from "@mui/icons-material/Info";
import { List, ListItem, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import "../../styles/blurry-scroll.scss";
import "../../styles/components/errorBox.scss";
import ProviderCardContainer from "../common/ProviderCardContainer";
import ScrollableList from "../common/ScrollableList";
import { PrescriberInfoDiscriminatorDto } from "../types";

function PrescriberComponent({ pharmacyList }: any) {
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState<number | false>(false);
  const handleChange =
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const theme = useTheme();
  const translate = useTranslationByClient();

  return (
    <Grid
      xs={12}
      width="90%"
      className="flex-grow-column"
      justifyContent="space-between"
    >
      <Grid className="flex-grow-column" aria-label="Prescriber List">
        {pharmacyList && pharmacyList.length > 0 ? (
          <ScrollableList>
            <List className="list-style">
              {pharmacyList?.map(
                (prescriber: PrescriberInfoDiscriminatorDto, index: number) => {
                  // eslint-disable-next-line no-param-reassign
                  prescriber.discriminator = "prescriber";
                  return (
                    <ListItem
                      className="list-item"
                      key={prescriber.practiceName}
                    >
                      <ProviderCardContainer
                        expanded={expanded}
                        handleChange={handleChange}
                        index={index}
                        provider={prescriber}
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </ScrollableList>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            flexDirection="column"
          >
            <InfoIcon
              className="errorIcon"
              sx={{ color: theme.palette.info.darker }}
              aria-label="info icon"
            />
            <br />
            <Typography
              variant="body3"
              color="info.darker"
              textTransform="uppercase"
            >
              {translate("PRESCRIBER_PAGE.NO_PRESCRIBER_YET_TEXT")}
            </Typography>
          </Box>
        )}
      </Grid>
      <Box className="page-back-button">
        <Button
          variant="primary"
          className="disabled-hover-effects"
          aria-label="back"
          onClick={() => navigate("/dashboard")}
        >
          {translate("GENERAL.BUTTON.BACK")}
        </Button>
      </Box>
    </Grid>
  );
}

export default PrescriberComponent;
