import { Box } from "@mui/material";
import React from "react";
import { GrantedAuthority } from "../../services/api/models/GrantedAuthority";
import SavingReadyWidget from "./SavingReadyWidget";
import SavingTotalWidget from "./SavingTotalWidget";
import SavingStartWidget from "./drugSaving/SavingStartWidget";
import SavingsAssistant from "./drugSaving/SavingsAssistant";
import GiftCardWidget from "./giftcard/GiftCardWidget";
import PharmacySavingWidget from "./pharmacySaving/PharmacySavingWidget";
import PrescriptionsReadyWidget from "./prescription/PrescriptionsReadyWidget";

export interface AuthoritiesProps {
  authorityList: GrantedAuthority[];
  authority: string;
  // setOverlayName: (value: string) => void;
}

function AuthorityCheck({
  authorityList,
  authority, // setOverlayName,
}: AuthoritiesProps) {
  return (
    <>
      {authorityList.map((authName: GrantedAuthority) =>
        authName.authority?.toUpperCase() === authority.toUpperCase() ? (
          <Box key={authName.authority} width="100%" m="auto" mb={3}>
            <Box>
              {
                {
                  SAVINGS_STATUS: <SavingReadyWidget />,
                  SAVINGS_TOTAL: <SavingTotalWidget />,
                  PRESCRIPTIONS_READY: <PrescriptionsReadyWidget />,
                  MEMBER_SAVING: <PharmacySavingWidget />,
                  GIFTCARDS: <GiftCardWidget />,
                  SAVINGS_START: <SavingStartWidget />,
                  SAVING_ASSISTANT: <SavingsAssistant />,
                }[authority]
              }
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginInline: 2,
                  marginTop: 5,
                  width: "100%",
                }}
              >
                {/* {authority.toUpperCase() === "MEMBER_SAVING" && (
                  <Button
                    onClick={onClickMoreInfo}
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: theme.palette.info.main,
                      minWidth: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    <Typography variant="subtitle1">More Info</Typography>
                  </Button>
                )} */}
                {/* {authName.isHideAble && (
                  <WidgetHideButton
                    aria-label={`disable feature ${authority.replaceAll(
                      "_",
                      " ",
                    )}`}
                    handleClick={() => handleClick(authority)}
                    width={
                      authority.toUpperCase() === "MEMBER_SAVING"
                        ? "initial"
                        : "100%"
                    }
                  />
                )} */}
              </Box>
            </Box>
          </Box>
        ) : null,
      )}
    </>
  );
}
export default AuthorityCheck;
