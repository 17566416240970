import { Box, useTheme } from "@mui/material";
import React from "react";
import PK from "../../assets/pk-flag.jpg";
import US from "../../assets/us-flag.png";

interface CountryFlagProps {
  country: string;
  color?: string;
  borderWidth?: string;
}
export default function CountryFlag({
  country,
  color,
  borderWidth,
}: CountryFlagProps) {
  const theme = useTheme();
  const flagSrc = country === "US" ? US : PK;
  return (
    <Box
      className="row-center country-flag-container"
      padding="0px"
      sx={{
        backgroundColor: `${theme.palette.info.light}`,
        border: `${borderWidth ?? "2.5px"} solid`,
        borderColor: color ?? `${theme.palette.info.dark}`,
        borderRight: "0",
      }}
    >
      <picture>
        <source
          media="(min-width:650px)"
          width="60"
          height="40"
          srcSet={flagSrc}
        />
        <source
          media="(min-width:375px)"
          width="39"
          height="26"
          srcSet={flagSrc}
        />
        <img src={flagSrc} alt="us-flag" width="30" height="20" />
      </picture>
    </Box>
  );
}
