import axios, { AxiosError } from "axios";
import { useContext } from "react";
import ReactGA from "react-ga4";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { addHeadersToPublicRequest } from "../../config/axiosConfig";
import { AppContext } from "../../config/store";
import { MemberInfoDto } from "../../services/api/models/MemberInfoDto";
import { getBaseUrl, setCookies, TokenData } from "../../utils/authUtils";
import createToast from "../../utils/globalUtils";
import { removeCaptcha } from "../../utils/otpUtils";
import useGetErrorMessage from "../useGetErrorMessage";
import usePersistentContext from "../usePersistentContext";
import useTranslationByClient, {
  TranslateFunction,
} from "../useTranslationByClient";

const registerMember = async (
  memberInfoUuid: string,
  registerInput: MemberInfoDto,
  tokens: TokenData | null,
  translate: TranslateFunction,
) => {
  if (!tokens) {
    throw new Error(translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"));
  }

  const baseUrl = getBaseUrl();
  const res = await axios.post(
    `${baseUrl}/members/${memberInfoUuid}/register`,
    {
      memberInfoDto: registerInput,
      token: tokens.accessToken,
    },
    {
      headers: addHeadersToPublicRequest(),
    },
  );
  return res;
};

const useRegisterMember = (
  setSpinner: (spinner: boolean) => void,
  firebaseUserId: string,
  tokens: TokenData | null,
) => {
  const navigate = useNavigate();
  const { value: memberPhone } = usePersistentContext("phone");
  const { preAuthUser } = useContext(AppContext);
  const getErrorMessage = useGetErrorMessage();
  const translate = useTranslationByClient();
  const registerInput = {
    firebaseUserId: firebaseUserId ?? "",
    memberInfoId: preAuthUser ? preAuthUser.memberInfoId : "",
    memberPhone: memberPhone!,
    memberFirstName: preAuthUser?.memberFirstName,
  };

  const { isLoading, refetch, data, error } = useQuery(
    ["registerMember", registerInput],
    () =>
      registerMember(
        registerInput.memberInfoId,
        registerInput,
        tokens,
        translate,
      ),
    {
      enabled: false,
      onSuccess: () => {
        if (!tokens) {
          createToast(
            translate("GENERAL.ERROR.SOMETHING_WENT_WRONG"),
            "error",
            3000,
          );
          navigate("/");
          return;
        }
        setCookies(
          tokens.accessToken,
          tokens.refreshToken,
          tokens.expirationTime,
        );
        createToast(translate("GENERAL.SUCCESS.USER_REGISTERED"), "success");
        ReactGA.event("sign_up");
        localStorage.removeItem("encryptionSalt");
        localStorage.removeItem("phone");
        setSpinner(false);
        removeCaptcha();
        navigate("/dashboard");
      },
      onError: (err: AxiosError) => {
        setSpinner(false);
        createToast(getErrorMessage(err), "error", 3000);
      },
    },
  );
  return { isLoading, refetch, data, error };
};

export default useRegisterMember;
