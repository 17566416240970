const DashboardModules: Array<string> = [
  "SAVINGS_STATUS",
  "SAVINGS_TOTAL",
  "MEMBER_SAVING",
  "PRESCRIPTIONS_READY",
  "SAVINGS_START",
  "GIFTCARDS",
  "SAVING_ASSISTANT",
];

export default DashboardModules;
