import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Page from "../components/common/Page";
import PrivacyComponent from "../components/privacyPage/PrivacyComponent";

function PrivacyPage() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <Page
      isSecondary={false}
      header={<Header />}
      isLoading={false}
      displayExtMemIdAtBottom
    >
      <PrivacyComponent handleClick={handleClick} />
    </Page>
  );
}

export default PrivacyPage;
