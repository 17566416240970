import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import useTranslationByClient, {
  TranslateFunction,
} from "../../hooks/useTranslationByClient";
import "../../styles/blurry-scroll.scss";
import ScrollableList from "../common/ScrollableList";

export interface TermsAndConditionComponentProps {
  handleClick: () => void;
}

const getTerms = (translate: TranslateFunction) => {
  const terms: any = translate(
    "TERMS_AND_CONDITIONS_PAGE.TERMS_AND_CONDITIONS",
    {
      returnObjects: true,
    },
  );
  return typeof terms !== "string" ? (Object.values(terms) as string[]) : [];
};

function TermsAndConditionComponent({
  handleClick,
}: TermsAndConditionComponentProps) {
  const translate = useTranslationByClient();
  const paragraphs = getTerms(translate);

  return (
    <Grid
      container
      xs={12}
      width="100%"
      height="auto"
      mx={3.75}
      justifyContent="center"
      alignContent="space-around"
      flexGrow={1}
    >
      <Grid item xs={12} className="col-center">
        <Typography
          variant="h2"
          fontSize="2rem"
          lineHeight={0.97}
          letterSpacing={-1.86}
          mb={1}
          textAlign="center"
          sx={{ wordSpacing: "3px" }}
        >
          {translate("TERMS_AND_CONDITIONS_PAGE.TITLE")}
        </Typography>
        <ScrollableList>
          {paragraphs.map((paragraph) => {
            return (
              <Typography
                key={paragraph}
                mt={3}
                variant="body1"
                textAlign="justify"
                sx={{
                  hyphens: "auto",
                  wordSpacing: "-0.1em",
                }}
              >
                {paragraph}
              </Typography>
            );
          })}
        </ScrollableList>
      </Grid>
      <Box className="page-back-button">
        <Button
          variant="primary"
          aria-label="agree to terms and conditions"
          onClick={handleClick}
          className="disabled-hover-effects"
        >
          {translate("GENERAL.BUTTON.OK")}
        </Button>
      </Box>
    </Grid>
  );
}

export default TermsAndConditionComponent;
