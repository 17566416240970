import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { useQuery } from "react-query";
import { drug } from "../components/switchNotificationPage/SavingsSwitchWidget";
import axiosConfig from "../config/axiosConfig";
import { Saving } from "../pages/SwitchNotificationPage";
import { SwitchNotificationDto } from "../services/api/models/SwitchNotificationDto";
import useTranslationByClient from "./useTranslationByClient";

const getMemberData = async (id: string) => {
  const res = await axiosConfig.get(`switches/${id}`);
  return res;
};

interface GetSwitchDetailsProps {
  id: string | null;
  setSourceDrug: Dispatch<SetStateAction<drug | undefined>>;
  setTargetDrug: Dispatch<SetStateAction<drug | undefined>>;
  setSavings: Dispatch<SetStateAction<Saving | undefined>>;
}

export default function useGetSwitchDetails({
  id,
  setSourceDrug,
  setTargetDrug,
  setSavings,
}: GetSwitchDetailsProps) {
  const translate = useTranslationByClient();
  const { isLoading, refetch, data, error, isFetching, isSuccess } = useQuery(
    ["getSwitchDetails"],
    () => getMemberData(id!),
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<SwitchNotificationDto>) => {
        const res = response.data;
        const tdrug: drug = {
          drugName: res.targetDrugName!,
          drugStrength: res.targetDrugStrength!,
          drugPrice: res.targetDrugPrice!,
          drugPharmacyAddress: res.drugPharmacyAddress,
          drugPharmacyName:
            res.drugPharmacyName && res.drugPharmacyName !== ""
              ? res.drugPharmacyName
              : translate("PHARMACIES_PAGE.UNKNOWN_PHARMACY_TEXT"),
          drugDaysSupply: res.drugDaysSupply,
          drugPrescriberName:
            res.drugPrescriberName && res.drugPrescriberName !== ""
              ? res.drugPrescriberName
              : translate("PRESCRIBER_PAGE.UNKNOWN_PRESCRIBER_TEXT"),
        };
        setTargetDrug(tdrug);
        const sdrug: drug = {
          drugName: res.sourceDrugName!,
          drugStrength: res.sourceDrugStrength!,
          drugPrice: res.sourceDrugPrice!,
          drugPharmacyAddress: res.drugPharmacyAddress,
          drugPharmacyName:
            res.drugPharmacyName && res.drugPharmacyName !== ""
              ? res.drugPharmacyName
              : translate("PHARMACIES_PAGE.UNKNOWN_PHARMACY_TEXT"),
          drugDaysSupply: res.drugDaysSupply,
          drugPrescriberName:
            res.drugPrescriberName && res.drugPrescriberName !== ""
              ? res.drugPrescriberName
              : translate("PRESCRIBER_PAGE.UNKNOWN_PRESCRIBER_TEXT"),
        };
        setSourceDrug(sdrug);
        setSavings({
          memberSavings: res.memberSavings,
          planSavings: res.planSavings,
          amountPaidByMember: res.amountPaidByMember,
        });
        return response;
      },
      onError: (err: AxiosError) => {
        return err;
      },
    },
  );
  return { isLoading, refetch, data, error, isFetching, isSuccess };
}
