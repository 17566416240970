import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { AxiosError } from "axios";
import React, { ReactNode, useEffect } from "react";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import setBackgroundColor from "../../utils/setBackgroundColor";
import SpinnerOverlay from "../otpPage/SpinnerOverlay";
import ErrorBox from "./ErrorBox";

export interface PageProps {
  isSecondary: boolean;
  header: ReactNode;
  isLoading?: boolean;
  spinnerText?: string;
  error?: AxiosError | Error | null;
}

function PageRefactored({
  isSecondary,
  header,
  isLoading,
  error,
  spinnerText,
  children,
}: React.PropsWithChildren<PageProps>) {
  const theme = useTheme();

  useEffect(() => {
    setBackgroundColor(
      isSecondary,
      theme.palette.primary.main,
      theme.palette.secondary.main,
    );
  }, []);
  const translate = useTranslationByClient();
  return (
    <Grid container>
      {header}
      <div
        style={
          useMediaQuery(`(min-width:${theme.constants.pageContentMaxWidth})`)
            ? {
                margin: "0 auto",
                maxWidth: theme.constants.pageContentMaxWidth,
              }
            : {}
        }
      >
        {isLoading ? (
          <SpinnerOverlay
            text={spinnerText ?? translate("GENERAL.SPINNER.LOADING_TEXT")}
            isSecondary={isSecondary}
          />
        ) : error ? (
          <ErrorBox error={error} />
        ) : (
          children
        )}
      </div>
    </Grid>
  );
}

export default PageRefactored;
