import axios, { AxiosRequestConfig } from "axios";
import {
  clearSession,
  getBaseUrl,
  getCookie,
  isTokenExpired,
  setCookies,
} from "../utils/authUtils";
import createToast from "../utils/globalUtils";
import refreshToken from "../utils/refreshToken";
import history from "./history";

let isTokenRefreshing = false;
let blockedRequests: { (): void }[] = [];

const baseUrl = getBaseUrl();
// add base url to axios
const axiosConfig = axios.create({
  baseURL: baseUrl,
});

const addHeaders = (configObject: AxiosRequestConfig) => {
  (configObject.headers ??= {}).Authorization = `Bearer ${getCookie(
    "accessToken",
  )}`;
  configObject.headers[
    "X-Application-Identifier"
  ] = `${process.env.REACT_APP_APPLICATION_IDENTIFIER}`;
};

export const addHeadersToPublicRequest = () => {
  return {
    "X-Application-Identifier": `${process.env.REACT_APP_APPLICATION_IDENTIFIER}`,
    "Content-Type": "application/json",
  };
};

axiosConfig.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const configObject = config;
  if (isTokenExpired()) {
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      try {
        const response = await refreshToken();
        const body = {
          oldToken: getCookie("accessToken"),
          newToken: response.accessToken,
          userId: response.userId,
        };
        await axios.post(`${baseUrl}/user/update-token`, body, {
          headers: {
            Authorization: `Bearer ${body.newToken}`,
            ...addHeadersToPublicRequest(),
          },
        });
        setCookies(
          response.accessToken,
          response.refreshToken,
          response.expiresIn,
        );
      } catch (error) {
        // navigate to login page
        createToast(
          "Session could not be retained! Logging out!",
          "error",
          5000,
        );
        clearSession();
        history.push("/logout");
      } finally {
        isTokenRefreshing = false;
        blockedRequests.forEach((request) => {
          request();
        });
        blockedRequests = [];
        addHeaders(configObject);
        return configObject;
      }
    } else {
      return new Promise((resolve) => {
        // Put the resolve into the queue, save it in a function form, and execute it directly after the token is refreshed
        blockedRequests.push(() => {
          // //Add a timestamp to the url to avoid the impact of request caching
          configObject.url ??= "";
          if (configObject.url.indexOf("?") > -1) {
            configObject.url = `${config.url}&n=${new Date().getTime()}`;
          } else {
            configObject.url = `${config.url}?n=${new Date().getTime()}`;
          }
          addHeaders(configObject);
          resolve(configObject);
        });
      });
    }
  }
  addHeaders(configObject);
  return configObject;
});

export default axiosConfig;
