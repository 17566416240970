import React from "react";

export interface SadSmileyIconProps {
  fill: string;
  className: string;
}

export default function SadSmileyIcon({ fill, className }: SadSmileyIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      height="100"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      className={className}
    >
      <defs>
        <clipPath id="307cf2c57a">
          <path d="M 7.300781 7.300781 L 368.050781 7.300781 L 368.050781 368.050781 L 7.300781 368.050781 Z M 7.300781 7.300781 " />
        </clipPath>
      </defs>
      <rect
        x="-37.5"
        width="450"
        fill="none"
        y="-37.499999"
        height="449.999989"
        fillOpacity="1"
      />
      <rect
        x="-37.5"
        width="450"
        fill="none"
        y="-37.499999"
        height="449.999989"
        fillOpacity="1"
      />
      <rect
        x="-37.5"
        width="450"
        fill="none"
        y="-37.499999"
        height="449.999989"
        fillOpacity="1"
      />
      <g clipPath="url(#307cf2c57a)">
        <path
          fill={fill}
          d="M 187.675781 368.050781 C 88.214844 368.050781 7.300781 287.136719 7.300781 187.675781 C 7.300781 88.214844 88.214844 7.300781 187.675781 7.300781 C 287.136719 7.300781 368.050781 88.214844 368.050781 187.675781 C 368.050781 287.136719 287.136719 368.050781 187.675781 368.050781 Z M 187.675781 22.332031 C 96.511719 22.332031 22.332031 96.511719 22.332031 187.675781 C 22.332031 278.839844 96.511719 353.019531 187.675781 353.019531 C 278.839844 353.019531 353.019531 278.839844 353.019531 187.675781 C 353.019531 96.511719 278.839844 22.332031 187.675781 22.332031 Z M 187.675781 22.332031 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <path
        fill={fill}
        d="M 270.347656 262.832031 L 105.003906 262.832031 C 100.855469 262.832031 97.488281 259.464844 97.488281 255.316406 C 97.488281 251.167969 100.855469 247.800781 105.003906 247.800781 L 270.347656 247.800781 C 274.496094 247.800781 277.863281 251.167969 277.863281 255.316406 C 277.863281 259.464844 274.496094 262.832031 270.347656 262.832031 Z M 270.347656 262.832031 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={fill}
        d="M 150.097656 172.644531 C 148.171875 172.644531 146.25 171.90625 144.777344 170.449219 L 84.652344 110.324219 C 81.71875 107.394531 81.71875 102.628906 84.652344 99.699219 C 87.582031 96.765625 92.347656 96.765625 95.277344 99.699219 L 155.402344 159.824219 C 158.335938 162.753906 158.335938 167.519531 155.402344 170.449219 C 153.945312 171.90625 152.023438 172.644531 150.097656 172.644531 Z M 150.097656 172.644531 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={fill}
        d="M 89.972656 172.644531 C 88.046875 172.644531 86.125 171.90625 84.652344 170.449219 C 81.71875 167.519531 81.71875 162.753906 84.652344 159.824219 L 144.777344 99.699219 C 147.707031 96.765625 152.472656 96.765625 155.402344 99.699219 C 158.335938 102.628906 158.335938 107.394531 155.402344 110.324219 L 95.277344 170.449219 C 93.820312 171.90625 91.898438 172.644531 89.972656 172.644531 Z M 89.972656 172.644531 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={fill}
        d="M 285.378906 172.644531 C 283.453125 172.644531 281.53125 171.90625 280.058594 170.449219 L 219.933594 110.324219 C 217 107.394531 217 102.628906 219.933594 99.699219 C 222.863281 96.765625 227.628906 96.765625 230.558594 99.699219 L 290.683594 159.824219 C 293.617188 162.753906 293.617188 167.519531 290.683594 170.449219 C 289.226562 171.90625 287.304688 172.644531 285.378906 172.644531 Z M 285.378906 172.644531 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={fill}
        d="M 225.253906 172.644531 C 223.328125 172.644531 221.40625 171.90625 219.933594 170.449219 C 217 167.519531 217 162.753906 219.933594 159.824219 L 280.058594 99.699219 C 282.988281 96.765625 287.753906 96.765625 290.683594 99.699219 C 293.617188 102.628906 293.617188 107.394531 290.683594 110.324219 L 230.558594 170.449219 C 229.101562 171.90625 227.179688 172.644531 225.253906 172.644531 Z M 225.253906 172.644531 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </svg>
  );
}
