import React from "react";

export interface ExclaimationMarkIconProps {
  fill: string;
  className: string;
}

export default function ExclaimationMarkIcon({
  fill,
  className,
}: ExclaimationMarkIconProps) {
  return (
    <svg
      fill={fill}
      className={className}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 301.691 301.691"
    >
      <g>
        <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0 	" />

        <rect x="130.563" y="261.168" width="40.525" height="40.523" />
      </g>
    </svg>
  );
}
