import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import { useNavigate } from "react-router-dom";
import useTranslationByClient from "../../hooks/useTranslationByClient";
import { DrugDto, SwitchListItemDto } from "../../services/api";
import "../../styles/blurry-scroll.scss";
import "../../styles/components/drugs.scss";
import ScrollableList from "../common/ScrollableList";
import DrugComponent from "../drugPage/DrugComponent";
import SwitchListComponent from "../switchListPage/SwitchListComponent";

interface RecentRefillsComponentProps {
  drugsData: DrugDto[];
  switchesData: SwitchListItemDto[];
}

function PharmaciesComponent({
  drugsData,
  switchesData,
}: RecentRefillsComponentProps) {
  const navigate = useNavigate();

  const translate = useTranslationByClient();

  return (
    <Grid
      xs={12}
      width="90%"
      className="flex-grow-column"
      justifyContent="space-between"
    >
      <ScrollableList>
        <SwitchListComponent switchesList={switchesData} />
        <DrugComponent drugDtoList={drugsData} />
      </ScrollableList>

      <Box className="page-back-button">
        <Button
          variant="primary"
          className="disabled-hover-effects"
          aria-label="back"
          onClick={() => navigate("/dashboard")}
        >
          {translate("GENERAL.BUTTON.BACK")}
        </Button>
      </Box>
    </Grid>
  );
}

export default PharmaciesComponent;
